define("slate-payroll-client/snippets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "balance-for-request-na.hbs": "{{balance-for-request description=null balances=null}}\n",
    "balance-for-request.hbs": "{{balance-for-request description=\"PTO\" balances=employee.balances asOf=false}}\n\n{{balance-for-request description=\"PTO\" balances=employee.balances}}\n",
    "confirm-component-block.hbs": "Advanced usage\n{{#x-confirm confirmState confirmClosed=(action \"doSomethingWhenClosed\") confirmOpen=(action \"doSomethingWhenOpen\")}}\n  My Message\n  <input type=\"text\" />\n{{/x-confirm}}\n",
    "confirm-component.hbs": "Basic usage\n{{x-confirm confirmState}}\n",
    "confirm-mixin.js": "import Ember from 'ember';\nimport confirmMixin from '../mixins/confirm';\n\nexport default Ember.Controller.extend(confirmMixin, {\n  actions: {\n    showConfirm () {\n      this.confirm('my message', 'Confirm title').then(response => {\n        Ember.Logger.log(response); // true || false\n      });\n    }\n  }\n});\n",
    "paystub-grid-item.hbs": "{{paystub-grid-item paystub=myPaystub}}\n",
    "select-component-advanced.hbs": "Advanced Usage\n{{bound-select selectId='my-select-id' selectClass='form-control' selection=myInitialValue content=myArrayOfData optionValuePath='someValuePath' optionLabelPath='someDescriptionPath' compareSelectionWithValue=true prompt='Some Select Prompt' action=(action (mut someValue))}}\n",
    "select-component-initial-value.hbs": "{{bound-select selectId='my-select-id' selectClass='form-control' selection='success' content=colors prompt='--Prompt--' action=(action (mut someValue))}}\n",
    "select-component.hbs": "{{bound-select selectId='my-select-id' selectClass='form-control' content=colors prompt='--Prompt--' action=(action (mut someValue))}}\n",
    "time-off-condensed-request-item-ro.hbs": "{{time-off/condensed-request-item request=request}}\n",
    "time-off-condensed-request-item-single-action.hbs": "{{time-off/condensed-request-item request=request reject=(action \"someOtherAction\")}}\n",
    "time-off-condensed-request-item-state.hbs": "{{time-off/condensed-request-item request=request showState=true}}\n",
    "time-off-condensed-request-item.hbs": "<div class=\"tor-condensed__list\">\n  {{#each requests as |request|}}\n    {{time-off/condensed-request-item request=request approve=(action \"someAction\") reject=(action \"someOtherAction\")}}\n  {{/each}}\n</div>\n",
    "time-off-request-component-list.hbs": "<div class=\"tor__list\">\n  {{#each timeOffRequests as |request|}}\n    {{time-off/request-item request=request}}\n  {{/each}}\n</div>\n",
    "time-off-request-component.hbs": "{{time-off/request-item request=timeOffRequestModel}}\n",
    "time-off-view-calendar-ro.hbs": "{{time-off/view-calendar requests=requests month=\"April\" year=\"2016\" readOnly=true}}\n",
    "time-off-view-calendar.hbs": "{{time-off/view-calendar requests=requests month=\"April\" year=\"2016\" approve=(action \"someAction\") reject=(action \"someOtherAction\")}}\n{{! approve action sends `model` and `quick` as arguments}}\n",
    "tooltip-block.hbs": "<button class=\"btn btn-primary\">\n  Hover me\n  {{#tool-tip placement=\"left\"}}\n    I'm in a block!\n  {{/tool-tip}}\n</button>\n",
    "tooltip-blockless.hbs": "<button class=\"btn btn-primary\">\n  Hover me\n  {{tool-tip \"Hi there\"}}\n</button>\n<button class=\"btn btn-primary\">\n  Hover me\n  {{tool-tip \"Over to the left\" placement=\"left\"}}\n</button>\n"
  };
  _exports.default = _default;
});