define("slate-payroll-client/mixins/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Public APIs
    confirm: function confirm(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Confirm';
      var stateKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'confirmState';
      return this.__setupState('Confirm', message, title, stateKey);
    },
    alert: function alert(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Alert';
      var stateKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'confirmState';
      return this.__setupState('Alert', message, title, stateKey);
    },
    __setupState: function __setupState(type, message, title, stateKey) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.set(_this, stateKey, {
          type: type,
          message: message,
          title: title,
          resolver: resolve
        });
      }).then(this.__teardownState(stateKey).bind(this));
    },
    __teardownState: function __teardownState(stateKey) {
      var _this2 = this;

      return function (response) {
        Ember.set(_this2, stateKey, undefined);
        return response;
      };
    }
  });

  _exports.default = _default;
});