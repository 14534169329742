define("slate-payroll-client/controllers/documents/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    mixpanel: Ember.inject.service(),
    auth: Ember.inject.service(),
    showModal: true,
    modalId: 'company-document-view-modal',
    notRead: Ember.computed.not('model.isRead'),
    showSignatureEntry: Ember.computed.and('notRead', 'model.requireSignature'),
    modalTitle: Ember.computed('model.title', function () {
      return '<h2>Viewing Document<br /><small>' + this.get('model.title') + '</small></h2>';
    }),
    computedDocumentUrl: Ember.computed('model.{id,link}', 'auth.token', function () {
      return '/client-api/company-document/file/' + this.get('model.id') + '?token=' + this.get('auth.token');
    }),
    shouldMarkRead: Ember.observer('model', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (!_this.get('model.link') && !_this.get('model.isRead')) {
          _this.send('markRead');
        }
      });
    }),
    actions: {
      closeModal: function closeModal() {
        this.transitionToRoute('documents');
      },
      markDownloaded: function markDownloaded() {
        this.set('downloadedDocument', true);
        this.send('markRead');
      },
      markRead: function markRead(signature) {
        var _this2 = this;

        this.set('feedback', null);

        if (this.get('notRead')) {
          if (this.get('model.requireSignature') && !signature || this.get('model.link') && !this.get('downloadedDocument')) {
            if (this.get('model.link') || !this.get('downloadedDocument')) {
              this.set('feedback', 'You must download the document before you can mark this as read.');
            }

            return;
          }

          var data = this.get('model.requireSignature') ? {
            signature: signature
          } : undefined; // Just do it lazily

          Ember.$.post('/client-api/company-documents/' + this.get('model.id') + '/mark-read', data).done(function () {
            if (data) {
              _this2.send('closeModal');
            }
          });
          this.mixpanel.trackEvent('Read Company Document', {
            user: this.get('auth.user.id'),
            company: this.get('auth.user.company.id'),
            companyDocument: this.get('model.id')
          });
        }
      }
    }
  });

  _exports.default = _default;
});