define("slate-payroll-client/templates/components/list-item/broadcast-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XmZNl4bK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[1,[23,[\"broadcast\",\"title\"]],false],[0,\"\\n  \"],[7,\"small\"],[11,\"class\",\"text-muted pull-right\"],[9],[0,\"Posted \"],[1,[27,\"time-ago\",[[23,[\"broadcast\",\"created\"]]],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[23,[\"broadcast\",\"message\"]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/list-item/broadcast-item.hbs"
    }
  });

  _exports.default = _default;
});