define("slate-payroll-client/utils/address-formatter", ["exports", "slate-payroll-client/utils/title-case"], function (_exports, _titleCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addressFormatter;

  function addressFormatter() {
    var data = this.getProperties('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode', 'hasAddress');

    if (data.hasAddress === false) {
      return '';
    }

    data.addressLine2 = data.addressLine2 ? data.addressLine2 + ' ' : '';
    data.addressZipcode = data.addressZipcode ? data.addressZipcode : '';
    return (0, _titleCase.default)(data.addressLine1 + ' ' + data.addressLine2 + data.addressCity + ', ' + data.addressState + ' ' + data.addressZipcode);
  }
});