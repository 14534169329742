define("slate-payroll-client/models/ach-transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    order: attr('number'),
    account: attr('string'),
    routing: attr('string'),
    amount: attr('number'),
    type: attr('string'),
    batch: attr('number'),
    depositedOn: attr('date')
  });

  _exports.default = _default;
});