define("slate-payroll-client/templates/components/labeled-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IJCYfbWg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"radio-button\",null,[[\"radioClass\",\"radioId\",\"changed\",\"disabled\",\"groupValue\",\"name\",\"required\",\"value\"],[[23,[\"radioClass\"]],[23,[\"radioId\"]],\"innerRadioChanged\",[23,[\"disabled\"]],[23,[\"groupValue\"]],[23,[\"name\"]],[23,[\"required\"]],[23,[\"value\"]]]]],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/labeled-radio-button.hbs"
    }
  });

  _exports.default = _default;
});