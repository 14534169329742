define("slate-payroll-client/mixins/popovers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.__setupPopovers();
    },
    __setupPopovers: function __setupPopovers() {
      Ember.run.later(this, function () {
        Ember.$('.popover-trigger').popover();
      });
    }
  });

  _exports.default = _default;
});