define("slate-payroll-client/components/time-off/time-of-day-select", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    changeDay: function changeDay(day, time, hours) {
      if (day) {
        Ember.setProperties(day, {
          hours: hours,
          date: time,
          end: (0, _moment.default)(time).add(hours, 'hours').toDate()
        });
      }

      return this.get('request.days');
    }
  });

  _exports.default = _default;
});