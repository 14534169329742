define("slate-payroll-client/components/reminder-list-item", ["exports", "slate-payroll-client/mixins/controller-abstractions/add-edit", "slate-payroll-client/mixins/controller-abstractions/delete"], function (_exports, _addEdit, _delete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_addEdit.default, _delete.default, {
    classNames: ['list-group-item', 'clearfix'],
    classNameBindings: ['reminder.virtualMissed:list-group-item-danger', 'reminder.isWarning:list-group-item-warning'],
    actions: {
      markCompleted: function markCompleted() {
        var reminder = this.get('reminder');
        reminder.set('completed', true);
        this.send('save', reminder);
      }
    }
  });

  _exports.default = _default;
});