define("slate-payroll-client/controllers/supervisor/punches/add", ["exports", "moment", "slate-payroll-client/mixins/ux-ajax-status-hooks", "slate-payroll-client/config/app-data"], function (_exports, _moment, _uxAjaxStatusHooks, _appData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    auth: Ember.inject.service(),
    queryParams: ['unitEntry'],
    punchesController: Ember.inject.controller('supervisor/punches'),
    showModal: true,
    unitEntry: false,
    modalId: 'add-punch-modal',
    modalTitle: '<h2>Add Punch</h2>',
    lunchIsOther: Ember.computed.equal('punchLunch', 'other'),
    dateOutPreset: Ember.computed.reads('autoDateOut'),
    balanceForCode: Ember.computed('model.{companyPaycode,employee.balances.[]}', function () {
      var paycode = this.get('model.companyPaycode');
      return paycode && paycode.get('timeOffCapability') && (this.get('model.employee.balances') || []).find(function (balance) {
        return balance.companyPaycode === paycode.get('id');
      });
    }),
    lockEntry: Ember.computed('model.companyPaycode.unit', function () {
      return this.get('model.companyPaycode.unit') === 'D' ? true : false;
    }),
    isMultiDay: Ember.computed('model.{timeIn,timeOut}', function () {
      var _this$model = this.model,
          timeIn = _this$model.timeIn,
          timeOut = _this$model.timeOut;
      return timeIn && timeOut && !(0, _moment.default)(timeOut).isSame(timeIn, 'day');
    }),
    updateLunch: Ember.observer('punchLunch', 'otherPunchLunch', function () {
      var data = this.getProperties('punchLunch', 'otherPunchLunch');
      var lunch = data.punchLunch !== 'other' ? data.punchLunch / 60 : data.otherPunchLunch / 60 || 0;
      this.set('model.lunch', lunch.toFixed(2) / 1);
    }),
    disableDateOut: Ember.computed('model.timeIn', function () {
      var _this = this;

      return [function (date) {
        var timeIn = _this.get('model.timeIn');

        return (0, _moment.default)(date).endOf('day').isBefore(timeIn);
      }];
    }),
    resetForm: function resetForm() {
      this.setProperties({
        autoDateOut: undefined,
        punchLunch: undefined,
        other: undefined
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.resetForm();
      this.transitionToRoute('supervisor.punches');
    },
    findPayPeriod: function findPayPeriod(date) {
      return this.store.query('pay-period', {
        start: {
          $lte: date
        },
        end: {
          $gte: date
        }
      }).then(function (res) {
        return res.get('firstObject');
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionToParentRoute();
      },
      savePunch: function savePunch() {
        var _this2 = this;

        this.ajaxStart();
        var punch = this.get('model');
        this.set('lockSelection', true);

        if (!punch.get('employee.id')) {
          this.ajaxError('No employee selected', true);
          return;
        }

        if (!punch.get('companyPaycode.id')) {
          this.ajaxError('No paycode selected', true);
          return;
        }

        if (this.get('unitEntry')) {
          var paycode = punch.get('companyPaycode'),
              mapping = _appData.paycodeUnitMap[paycode.get('unitActual')],
              units = this.get('units');

          if (!mapping) {
            this.ajaxError('Unable to find paycode mapping', true);
            return;
          }

          if (_typeof(mapping) === 'object') {
            mapping = mapping[paycode.get('type')];
          }

          punch.set(mapping, units);
        }

        punch.set('directHourEntry', this.get('unitEntry'));

        var savePunch = function savePunch() {
          return punch.save().then(function () {
            _this2.ajaxSuccess();

            _this2.set('units', null);

            _this2.transitionToParentRoute();
          });
        };

        if (this.get('unitEntry')) {
          var time = (0, _moment.default)(punch.get('created')).hour(12).minute(0).second(0).toDate();
          punch.setProperties({
            timeIn: time,
            timeOut: time,
            created: new Date()
          });
          this.findPayPeriod(time).then(function (payPeriod) {
            if (payPeriod) {
              punch.set('payPeriod', payPeriod);
              return savePunch();
            } else {
              return _this2.ajaxError('Unable to find appropriate pay period', true);
            }
          }).catch(this.ajaxError.bind(this));
        } else {
          savePunch().catch(this.ajaxError.bind(this));
        }
      },
      lockUnitEntry: function lockUnitEntry() {
        if (this.get('model.companyPaycode.unit') === 'D') {
          this.set('unitEntry', true);
          this.get('model').setProperties({
            hours: '0.00',
            lunch: null
          });
        }
      }
    }
  });

  _exports.default = _default;
});