define("slate-payroll-client/templates/components/paystub-document-ach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XSfuNAfN",
    "block": "{\"symbols\":[\"achTransaction\"],\"statements\":[[7,\"h4\"],[11,\"class\",\"section-small-heading text-center\"],[9],[0,\"Accounts\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"transaction-list\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row label-row no-gutter\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"Account\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-4 text-right\"],[9],[0,\"Amount\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"achTransactionsOrdered\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"row no-gutter\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"inline-heading\"],[9],[0,\"\\n          *******\"],[1,[22,1,[\"account\"]],false],[7,\"br\"],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"text-sm text-right text-muted\"],[9],[0,\"\\n            \"],[1,[22,1,[\"routing\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-4 text-right\"],[9],[0,\"\\n        \"],[1,[27,\"x-currency\",[[22,1,[\"amount\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/paystub-document-ach.hbs"
    }
  });

  _exports.default = _default;
});