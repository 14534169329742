define("slate-payroll-client/components/lazy-model-select", ["exports", "slate-payroll-client/mixins/ux-ajax-status-hooks"], function (_exports, _uxAjaxStatusHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LazyModelSelectComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    store: Ember.inject.service(),
    classNames: ['lazy-model-multi-select-component'],
    classNameBindings: ['multi:lazy-model-multi-select'],
    _modelName: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.get('_modelName') !== this.get('modelName')) {
        this.set('_modelName', this.get('modelName'));
        this.resolveModel().then(function (val) {
          _this.set('resolvedModel', val);
        });
      }
    },
    resolveModel: function resolveModel() {
      var _this2 = this;

      var select = 'id',
          selectors = ['optionLabelPath', 'optionValuePath', 'selectQuery'];
      this.ajaxStart();
      selectors.forEach(function (selector) {
        if (_this2.get(selector)) {
          select += ' ' + _this2.get(selector);
        }
      });
      return this.store.query(this.get('modelName'), {
        select: select
      }).then(function (results) {
        _this2.ajaxSuccess();

        return results;
      }).catch(this.ajaxError.bind(this));
    },
    updateValue: function updateValue(val) {
      this.get('onChange')(val);
    }
  });
  LazyModelSelectComponent.reopenClass({
    positionalParams: ['modelName']
  });
  /*
    {{lazy-model-multi-select
      'my-model'
      selectId='my-select-id'
      optionLabelPath='model.label.path'
      onChange=(action (mut value))}}
  */

  var _default = LazyModelSelectComponent;
  _exports.default = _default;
});