define("slate-payroll-client/controllers/punches/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelUpcoming: Ember.computed.filter('model', function (payPeriod) {
      return (0, _moment.default)().isBefore(payPeriod.get('start'));
    }),
    modelCurrent: Ember.computed.filter('model', function (payPeriod) {
      var now = (0, _moment.default)();
      return now.isBefore(payPeriod.get('end')) && now.isAfter(payPeriod.get('start'));
    }),
    modelPast: Ember.computed.filter('model', function (payPeriod) {
      return (0, _moment.default)().isAfter(payPeriod.get('end'));
    }),
    multiModels: Ember.computed('modelUpcoming', 'modelCurrent', 'modelPast', function () {
      var _this = this;

      var modelNames = ['Current', 'Upcoming', 'Past'];
      return modelNames.map(function (name) {
        var modelPath = 'model' + name;
        return {
          modelPath: modelPath,
          name: name,
          model: _this.get(modelPath)
        };
      });
    })
  });

  _exports.default = _default;
});