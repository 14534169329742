define("slate-payroll-client/services/logging", ["exports", "slate-payroll-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var levels = ['debug', 'info', 'warning', 'error', 'critical'];

  var _default = Ember.Service.extend({
    rollbar: Ember.inject.service(),
    rollbarLogLevel: levels[1],
    // info
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      levels.forEach(function (level) {
        _this[level] = function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _this.__curryLog.apply(_this, [level].concat(args));
        };
      });
    },
    identifyUser: function identifyUser(user) {
      var _ref = user.getProperties('id', 'email', 'employeeNumber') || {},
          id = _ref.id,
          email = _ref.email,
          employeeNumber = _ref.employeeNumber;

      this.set('rollbar.currentUser', {
        id: id,
        email: email,
        username: "e#".concat(employeeNumber, "-c#").concat(user.belongsTo('company').id())
      });
      window.rb = this.get('rollbar');
    },
    determineLogLevel: function determineLogLevel(err) {
      if (err && err.errors) {
        Object.assign(err, err.errors[0]);
      }

      var dtLevel = 'error',
          status = typeof err.status === 'number' && "".concat(err.status).charAt(0);

      if (status) {
        dtLevel = status === '5' ? 'critical' : 'info';
      }

      return dtLevel;
    },
    send: function send() {
      var err = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var userLevel = arguments.length > 1 ? arguments[1] : undefined;

      if (!err || _environment.default.environment === 'test') {
        return;
      }

      var level = userLevel || err.level || this.determineLogLevel(err),
          threshold = levels.indexOf(this.get('rollbarLogLevel'));

      if (!_environment.default.emberRollbarClient.enabled || levels.indexOf(level) < threshold) {
        return this.__localLog(err, level);
      }

      this.rollbar[level](err);
    },
    __localLog: function __localLog(err, level) {
      console[level === 'error' ? 'error' : 'log'](err);
    },
    __curryLog: function __curryLog(level) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return this.send(args.join(' '), level);
    }
  });

  _exports.default = _default;
});