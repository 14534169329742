define("slate-payroll-client/config/app-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paycodeUnitMap = _exports.bankAccountTypes = _exports.filingStatuses = _exports.holidays = _exports.payPeriodFrequencies = _exports.maritalStatuses = _exports.genders = _exports.suffixes = _exports.states = void 0;
  var states = [{
    v: 'AL',
    l: 'Alabama'
  }, {
    v: 'AK',
    l: 'Alaska'
  }, {
    v: 'AZ',
    l: 'Arizona'
  }, {
    v: 'AR',
    l: 'Arkansas'
  }, {
    v: 'CA',
    l: 'California'
  }, {
    v: 'CO',
    l: 'Colorado'
  }, {
    v: 'CT',
    l: 'Connecticut'
  }, {
    v: 'DE',
    l: 'Delaware'
  }, {
    v: 'DC',
    l: 'District of Columbia'
  }, {
    v: 'FL',
    l: 'Florida'
  }, {
    v: 'GA',
    l: 'Georgia'
  }, {
    v: 'HI',
    l: 'Hawaii'
  }, {
    v: 'ID',
    l: 'Idaho'
  }, {
    v: 'IL',
    l: 'Illinois'
  }, {
    v: 'IN',
    l: 'Indiana'
  }, {
    v: 'IA',
    l: 'Iowa'
  }, {
    v: 'KS',
    l: 'Kansas'
  }, {
    v: 'KY',
    l: 'Kentucky'
  }, {
    v: 'LA',
    l: 'Louisiana'
  }, {
    v: 'ME',
    l: 'Maine'
  }, {
    v: 'MD',
    l: 'Maryland'
  }, {
    v: 'MA',
    l: 'Massachusetts'
  }, {
    v: 'MI',
    l: 'Michigan'
  }, {
    v: 'MN',
    l: 'Minnesota'
  }, {
    v: 'MS',
    l: 'Mississippi'
  }, {
    v: 'MO',
    l: 'Missouri'
  }, {
    v: 'MT',
    l: 'Montana'
  }, {
    v: 'NE',
    l: 'Nebraska'
  }, {
    v: 'NV',
    l: 'Nevada'
  }, {
    v: 'NH',
    l: 'New Hampshire'
  }, {
    v: 'NJ',
    l: 'New Jersey'
  }, {
    v: 'NM',
    l: 'New Mexico'
  }, {
    v: 'NY',
    l: 'New York'
  }, {
    v: 'NC',
    l: 'North Carolina'
  }, {
    v: 'ND',
    l: 'North Dakota'
  }, {
    v: 'OH',
    l: 'Ohio'
  }, {
    v: 'OK',
    l: 'Oklahoma'
  }, {
    v: 'OR',
    l: 'Oregon'
  }, {
    v: 'PA',
    l: 'Pennsylvania'
  }, {
    v: 'RI',
    l: 'Rhode Island'
  }, {
    v: 'SC',
    l: 'South Carolina'
  }, {
    v: 'SD',
    l: 'South Dakota'
  }, {
    v: 'TN',
    l: 'Tennessee'
  }, {
    v: 'TX',
    l: 'Texas'
  }, {
    v: 'UT',
    l: 'Utah'
  }, {
    v: 'VT',
    l: 'Vermont'
  }, {
    v: 'VA',
    l: 'Virginia'
  }, {
    v: 'WA',
    l: 'Washington'
  }, {
    v: 'WV',
    l: 'West Virginia'
  }, {
    v: 'WI',
    l: 'Wisconsin'
  }, {
    v: 'WY',
    l: 'Wyoming'
  }];
  _exports.states = states;
  var suffixes = ['Sr.', 'Jr.', 'II', 'III', 'IV', 'V'];
  _exports.suffixes = suffixes;
  var genders = ['Male', 'Female'];
  _exports.genders = genders;
  var maritalStatuses = ['Single', 'Married'];
  _exports.maritalStatuses = maritalStatuses;
  var payPeriodFrequencies = ['Weekly', 'Bi-Weekly', 'Semi-Monthly', 'Monthly'];
  _exports.payPeriodFrequencies = payPeriodFrequencies;
  var filingStatuses = ['Single', 'Married filing jointly', 'Married filing separately', 'Head of household', 'Qualifying widow(er) with dependent child'];
  _exports.filingStatuses = filingStatuses;
  var bankAccountTypes = ['Checking', 'Savings'];
  _exports.bankAccountTypes = bankAccountTypes;
  var holidays = [// 2015
  new Date(2015, 0, 1), new Date(2015, 0, 19), new Date(2015, 1, 16), new Date(2015, 4, 25), new Date(2015, 6, 3), new Date(2015, 6, 4), new Date(2015, 8, 7), new Date(2015, 9, 12), new Date(2015, 10, 11), new Date(2015, 10, 26), new Date(2015, 11, 25), // 2016
  new Date(2016, 0, 1), new Date(2016, 0, 18), new Date(2016, 1, 15), new Date(2016, 4, 30), new Date(2016, 6, 4), new Date(2016, 8, 5), new Date(2016, 9, 10), new Date(2016, 10, 11), new Date(2016, 10, 24), new Date(2016, 11, 26)];
  _exports.holidays = holidays;
  var paycodeUnitMap = {
    Hours: 'hours',
    Days: 'days',
    Dollars: {
      E: 'addEarnings',
      D: 'deduction'
    },
    Percent: {
      E: 'addEarnings',
      D: 'deduction'
    }
  };
  _exports.paycodeUnitMap = paycodeUnitMap;
});