define("slate-payroll-client/models/file-blob", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    attributes: (0, _attr.default)(),
    s3ObjectPath: (0, _attr.default)('string'),
    s3Bucket: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company', {
      async: true,
      inverse: null
    }),
    employee: (0, _relationships.belongsTo)('employee', {
      async: true,
      inverse: null
    }),
    created: (0, _attr.default)('date')
  });

  _exports.default = _default;
});