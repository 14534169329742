define("slate-payroll-client/components/timely-greeting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    timelyGreetingText: Ember.computed(function () {
      var now = new Date().getHours();

      if (now >= 0 && now < 12) {
        return 'Good morning';
      } else if (now >= 12 && now < 17) {
        return 'Good afternoon';
      } else {
        return 'Good evening';
      }
    })
  });

  _exports.default = _default;
});