define("slate-payroll-client/components/time-off/view-calendar/interaction-day-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    computeDayTotal: function computeDayTotal(day) {
      return day ? day.hours ? day.hours : day.half ? 4 : 8 : 0;
    },
    percentOfRequest: Ember.computed('days.[]', 'day', function () {
      var _this = this;

      return this.computeDayTotal(this.get('day')) / this.get('days').reduce(function (total, day) {
        return total + _this.computeDayTotal(day);
      }, 0);
    })
  });

  _exports.default = _default;
});