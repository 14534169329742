define("slate-payroll-client/components/sg/sg-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sg__color', 'col-md-2'],
    classNameBindings: ['computedClassName'],
    computedClassName: Ember.computed('color', function () {
      return 'sg__color-' + this.get('color');
    })
  });

  _exports.default = _default;
});