define("slate-payroll-client/config/models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modelSettings = void 0;
  var modelSettings = {
    employee: {
      label: 'fullName',
      selectQuery: 'name'
    },
    department: {
      label: 'name'
    },
    location: {
      label: 'name'
    },
    company: {
      label: 'name'
    },
    'company-paycode': {
      selectQuery: 'paycode',
      label: 'paycode.description'
    },
    'pay-period': {
      label: 'paidOn'
    }
  };
  _exports.modelSettings = modelSettings;
});