define("slate-payroll-client/components/bs-modal", ["exports", "slate-payroll-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal', 'fade'],
    attributeBindings: ['id'],
    didInsertElement: function didInsertElement() {
      this._toggleShow();
    },
    _toggleShow: Ember.observer('showModal', function () {
      var parentClass = this.get('elementId') + '-parent',
          inRoot = this.get('_inRoot'),
          showModal = this.get('showModal'),
          $this = this.$();

      if (showModal && !inRoot) {
        $this.after('<div class="' + parentClass + '"></div>');
        $this.appendTo('.ember-application');
        this.set('_inRoot', true);

        this._bsFn(true).one('hidden.bs.modal', this._hidViaModal.bind(this));

        if ((_environment.default || {}).environment === 'test') {
          this._otfTweaks();
        }
      } else if (!showModal && inRoot) {
        if (this.get('hiddenByModal')) {
          $this.off('hidden.bs.modal', this._hidViaModal.bind(this));

          this._manipulateDOM();

          this.set('hiddenByModal', null);
        } else {
          this._bsFn(false).one('hidden.bs.modal', this._manipulateDOM.bind(this));
        }
      }
    }),

    /* testing-specific, on-the-fly bootstrap modal tweaks */
    _otfTweaks: function _otfTweaks() {
      var $backdrop = Ember.$('.modal-backdrop');

      if (!$backdrop) {
        return;
      } // backdrop has attached to the test page


      if (!$backdrop.parent().hasClass('.ember-application')) {
        $backdrop.appendTo('.ember-application');
      } // relocate modal-open class


      if (Ember.$('body').hasClass('modal-open')) {
        Ember.$('body').removeClass('modal-open');
        Ember.$('.ember-application').addClass('modal-open');
      }
    },
    _hidViaModal: function _hidViaModal() {
      this.setProperties({
        showModal: false,
        hiddenByModal: true
      });
    },
    _manipulateDOM: function _manipulateDOM() {
      Ember.$('.' + this.get('elementId') + '-parent').replaceWith(Ember.$('#' + this.get('elementId')));
      this.setProperties({
        _inRoot: false,
        hiddenByModal: undefined
      });

      if (this.get('onClose')) {
        this.get('onClose')();
      }
    },
    _bsFn: function _bsFn(showModal) {
      var $el = this.$();
      return showModal ? $el.modal(this.get('static') ? {
        keyboard: false,
        backdrop: 'static'
      } : 'show') : $el.modal('hide');
    },
    willDestroy: function willDestroy() {
      if (this.get('_inRoot')) {
        var elId = this.get('elementId'),
            parentClass = elId + '-parent';
        Ember.$('#' + elId).remove();
        Ember.$('.' + parentClass).remove();
      }
    }
  });

  _exports.default = _default;
});