define("slate-payroll-client/templates/components/reminder-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCcsZss8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"reminder\",\"virtualMissed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"label label-danger\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-times-circle\"],[9],[10],[0,\" Missed\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"reminder\",\"code\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"label label-default\"],[9],[1,[23,[\"reminder\",\"code\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[1,[23,[\"reminder\",\"description\"]],false],[0,\"\\n\\n\"],[7,\"small\"],[11,\"class\",\"pull-right text-sm text-muted text-right\"],[9],[0,\"\\n  Next Due \"],[1,[27,\"time-ago\",[[23,[\"reminder\",\"next\"]]],null],false],[0,\" on \"],[1,[27,\"format-moment\",[[23,[\"reminder\",\"next\"]],\"M/D/YY\"],null],false],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"reminder\",\"completed\"]]],null,{\"statements\":[[0,\"    Completed\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[28,[\"text-success tooltip-trigger\",[27,\"unless\",[[23,[\"reminder\",\"virtualMissed\"]],\" text-bright\"],null]]]],[11,\"title\",\"Mark as completed\"],[3,\"action\",[[22,0,[]],\"markCompleted\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-check\"],[9],[10],[0,\" Complete\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/reminder-list-item.hbs"
    }
  });

  _exports.default = _default;
});