define("slate-payroll-client/helpers/concat-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatString = concatString;
  _exports.default = void 0;

  function concatString(params
  /*, hash*/
  ) {
    return params.join(' ');
  }

  var _default = Ember.Helper.helper(concatString);

  _exports.default = _default;
});