define("slate-payroll-client/components/paystub-document-deductions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['squeeze']
  });

  _exports.default = _default;
});