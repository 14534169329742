define("slate-payroll-client/templates/components/bound-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9NRPWiMt",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\"],[12,\"id\",[21,\"selectId\"]],[12,\"class\",[21,\"selectClass\"]],[3,\"action\",[[22,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"prompt\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"selected\",[27,\"is-not\",[[23,[\"selection\"]]],null]],[9],[0,\"\\n      \"],[1,[21,\"prompt\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,[\"content\"]]],[[\"key\"],[\"@identity\"]],{\"statements\":[[0,\"    \"],[1,[27,\"x-bound-select-option\",null,[[\"item\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"compareWithValue\",\"comparePath\"],[[22,1,[]],[23,[\"optionValuePath\"]],[23,[\"optionLabelPath\"]],[23,[\"selection\"]],[23,[\"compareSelectionWithValue\"]],[23,[\"comparePath\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/bound-select.hbs"
    }
  });

  _exports.default = _default;
});