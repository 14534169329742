define("slate-payroll-client/models/transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    code: attr('string'),
    description: attr('string'),
    units: attr('number'),
    rate: attr('number'),
    total: attr('number'),
    taxLocal: attr('boolean'),
    taxState: attr('boolean'),
    taxFederal: attr('boolean'),
    taxStateWithholding: attr('string'),
    taxStateUnemployment: attr('string'),
    companyPaycode: _emberData.default.belongsTo('company-paycode', {
      async: true,
      inverse: false
    })
  });

  _exports.default = _default;
});