define("slate-payroll-client/components/x-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ConfirmComponent = Ember.Component.extend({
    classNames: ['x-confirm__component'],
    showModal: false,
    modalSm: true,
    modalId: Ember.computed('elementId', function () {
      return this.get('elementId') + '__modal';
    }),
    _stateChanged: Ember.observer('_confirmState', function () {
      var _this = this;

      var state = Ember.get(this, '_confirmState'),
          modalId = Ember.get(this, 'modalId');

      var stateNotification = function stateNotification(type) {
        return function () {
          var a = Ember.get(_this, type);

          if (a && typeof a === 'function') {
            a(state);
          }

          if (type === 'confirmClosed') {
            Ember.get(_this, '_confirmState.resolver')(Ember.get(_this, '_intermediateState'));
            Ember.set(_this, '_intermediateState', undefined);
          }
        };
      };

      Ember.setProperties(this, {
        isConfirm: undefined,
        isAlert: undefined
      });

      if (state) {
        Ember.set(this, 'showModal', true);
        Ember.set(this, 'is' + state.type, true);
        this.$('#' + modalId).one('shown.bs.modal', stateNotification('confirmOpen'));
        this.$('#' + modalId).one('hidden.bs.modal', stateNotification('confirmClosed'));
      }
    }),
    actions: {
      close: function close(response) {
        Ember.set(this, 'showModal', false);
        Ember.set(this, '_intermediateState', response);
      }
    }
  });
  ConfirmComponent.reopenClass({
    positionalParams: ['_confirmState']
  });
  var _default = ConfirmComponent;
  _exports.default = _default;
});