define("slate-payroll-client/templates/esa/clear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ALAdPDJ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"transparent-card clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"text-center text-success\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-check\"],[9],[10],[0,\" Successfully reset password. Please login to setup your new password.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-center\"],[9],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"goToLogin\"]],[9],[0,\"Proceed to login\"],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/esa/clear.hbs"
    }
  });

  _exports.default = _default;
});