define("slate-payroll-client/components/x-snowshoe-screen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    logging: Ember.inject.service(),
    classNames: ['snowshoe-stamp-screen'],
    postUrl: '',
    didInsertElement: function didInsertElement() {
      this.initStampScreen();
    },
    initStampScreen: function initStampScreen() {
      var initData = {
        postUrl: this.get('postUrl'),
        stampScreenElmId: this.get('elementId'),
        postViaAjax: true,
        success: this.stampAction('success'),
        error: this.stampAction('error'),
        messages: {}
      };
      this.logging.send('Initializing stamp screen...', 'debug');
      Ember.$.snowshoe.stampScreen.init(initData, Ember.$.snowshoe.client);
      this.logging.send('Initialized stamp screen.', 'debug');
    },
    stampAction: function stampAction(event) {
      var self = this;
      return function () {
        var callback = self.get(event);

        if (!callback) {
          return this.logging.send('No action for "' + event + '".', 'error');
        }

        callback.apply(void 0, arguments);
      };
    }
  });

  _exports.default = _default;
});