define("slate-payroll-client/serializers/company-paycode", ["exports", "ember-data", "slate-payroll-client/serializers/application", "slate-payroll-client/utils/serialize-object"], function (_exports, _emberData, _application, _serializeObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      paycode: {
        embedded: 'always'
      }
    },
    normalize: function normalize(c, hash) {
      (0, _serializeObject.default)(hash, false, 'codeSettings', 'permissions');
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});