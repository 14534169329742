define("slate-payroll-client/initializers/mixpanel", ["exports", "slate-payroll-client/config/environment", "ember-cli-mixpanel-service/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'mixpanel',
    initialize: function initialize() {
      var config = _environment.default['mixpanel'] || {};

      _configuration.default.load(config);

      var application = arguments[1] || arguments[0];
      application.inject('route', 'mixpanel', 'service:mixpanel');
      application.inject('router:main', 'mixpanel', 'service:mixpanel');
      application.inject('controller', 'mixpanel', 'service:mixpanel');
      application.inject('component', 'mixpanel', 'service:mixpanel');
    }
  };
  _exports.default = _default;
});