define("slate-payroll-client/routes/supervisor/time-off/index", ["exports", "moment", "slate-payroll-client/mixins/refreshable"], function (_exports, _moment, _refreshable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshable.default, {
    queryParams: {
      month: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var queryDate = params.month && params.year ? (0, _moment.default)("".concat(params.month, " ").concat(params.year), 'MMMM YYYY') : (0, _moment.default)(),
          start = (0, _moment.default)(queryDate).startOf('month').toDate(),
          end = (0, _moment.default)(queryDate).endOf('month').toDate();
      var queries = [{
        _supervised: true,
        'days.date': {
          $gte: start
        }
      }, {
        $and: [{
          approved: true
        }, {
          'days.date': {
            $gte: start
          }
        }, {
          'days.date': {
            $lte: end
          }
        }]
      }];

      if (this.paramsFor('supervisor').allSupervised) {
        queries[0].includeRecursiveSupervised = true;
      }

      return Ember.RSVP.all(queries.map(function (query) {
        return _this.store.query('time-off-request', query);
      })).then(function (results) {
        return results.reduce(function (all, result) {
          return all.concat(result.toArray());
        }, []);
      });
    }
  });

  _exports.default = _default;
});