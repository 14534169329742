define("slate-payroll-client/templates/components/x-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EeRXoVJg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"copyright\"],[9],[0,\"© \"],[1,[21,\"year\"],false],[0,\" Slate Pay, Slate Payroll, Slate by Associated Employers. All Rights Reserved.\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"copyright-print\"],[9],[0,\"© \"],[1,[21,\"year\"],false],[0,\" Slate Payroll. Print Version. All Rights Reserved.\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[7,\"a\"],[11,\"href\",\"https://status.associatedemployers.org\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[11,\"title\",\"Status page\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-wifi\"],[9],[10],[0,\" Slate Network Status\"],[10],[10],[0,\"\\n\"],[1,[27,\"x-version-info\",null,[[\"buildInfo\"],[[23,[\"buildInfo\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/x-footer.hbs"
    }
  });

  _exports.default = _default;
});