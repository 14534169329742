define("slate-payroll-client/templates/components/sg/sg-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sXzlCmCL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"sg__section-title\"],[9],[0,\"\\n  \"],[1,[21,\"name\"],false],[0,\"\\n\"],[4,\"link-to\",[\"beyonce\",[27,\"query-params\",null,[[\"focus\"],[[27,\"if\",[[23,[\"focus\"]],\"null\",[23,[\"name\"]]],null]]]]],[[\"class\"],[\"pull-right\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-expand\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"sg__section-content \",[27,\"if\",[[23,[\"squeeze\"]],\"sg__squeeze-content\"],null]]]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/sg/sg-section.hbs"
    }
  });

  _exports.default = _default;
});