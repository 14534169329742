define("slate-payroll-client/routes/supervisor/time-off/historical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    limit: 25,
    queryParams: {
      page: {
        refreshModel: true
      },
      e: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var page = params.page - 1;
      var query = {
        _supervised: true,
        page: page || 0,
        limit: this.get('limit'),
        sort: {
          'name.last': 1
        }
      };
      var employeesQuery = {};

      if (this.paramsFor('supervisor').allSupervised) {
        query.includeRecursiveSupervised = true;
        employeesQuery.includeRecursiveSupervised = true;
      }

      if (params.e) {
        query.employee = params.e;
      }

      return Ember.RSVP.hash({
        model: this.store.query('time-off-request', query),
        employees: this.store.query('employee', employeesQuery)
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model,
        employees: model.employees,
        limit: this.get('limit')
      });
    }
  });

  _exports.default = _default;
});