define("slate-payroll-client/templates/components/x-sv-employee-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cHh5G9Pe",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"x-sv-picture\",null,[[\"picture\",\"mergeStyles\"],[[23,[\"employee\",\"apiPictureUrl\"]],[23,[\"pictureStyles\"]]]]],false],[0,\"\\n\\n\"],[7,\"h5\"],[11,\"class\",\"sve-heading\"],[9],[0,\"\\n  \"],[1,[23,[\"employee\",\"fullName\"]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"label label-success\"],[9],[1,[23,[\"employee\",\"employeeNumber\"]],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sve__employee-info\"],[9],[0,\"\\n    \"],[7,\"small\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"lastPunch\"]]],null,{\"statements\":[[0,\"        Last Punch \"],[1,[27,\"time-ago\",[[23,[\"lastPunch\",\"timeIn\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        No Recent Punch Found.\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[1,[27,\"x-sve-punch-indicator\",null,[[\"lastPunch\"],[[23,[\"lastPunch\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"small\"],[9],[0,\"\\n    \"],[1,[23,[\"employee\",\"jobTitle\"]],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"didResetPassword\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-check text-success\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"title\",\"Reset password for employee\"],[3,\"action\",[[22,0,[]],\"resetPassword\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-history\"],[9],[10],[0,\" Reset password for employee\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/x-sv-employee-list-item.hbs"
    }
  });

  _exports.default = _default;
});