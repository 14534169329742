define("slate-payroll-client/services/ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    auth: Ember.inject.service(),
    headers: Ember.computed('auth.session.token', {
      get: function get() {
        var headers = {};
        var token = this.get('auth.session.token');

        if (token) {
          headers['X-API-Token'] = token;
        }

        return headers;
      }
    }),
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (typeof payload === 'string') {
        return [{
          detail: payload
        }];
      }
    }
  });

  _exports.default = _default;
});