define("slate-payroll-client/components/input/digit-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    classNames: ['input__digits'],
    inputLength: 5,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('autoFocus')) {
        this.$('input.input-digits__digit')[0].focus();
      }
    },
    inputs: Ember.computed('inputLength', function () {
      return _toConsumableArray(Array(this.get('inputLength')).fill());
    }),
    valueArray: Ember.computed('__value', function () {
      return (this.get('__value') || '').split('');
    }),
    focusIndex: function focusIndex(i) {
      try {
        if (i.focus) {
          i.focus();
        } else {
          this.$('input.input-digits__digit')[i].focus();
        }
      } catch (e) {
        return;
      }
    },
    mutateInput: function mutateInput(index, value, ev) {
      var _this = this;

      var onChange = this.get('onChange');
      var newValue; // paste mode

      if (index === 'paste') {
        // this may fail, clipboard data isn't available in all browsers
        try {
          newValue = (value || ev).originalEvent.clipboardData.getData('Text') || '';
          newValue = newValue.split('').filter(function (s) {
            return !isNaN(parseInt(s));
          }).join('');
        } catch (e) {
          return;
        } finally {
          var $inputs = this.$('input.input-digits__digit'),
              newValueArr = newValue.split('');
          $inputs.each(function (inputIndex, $input) {
            $input.value = newValueArr[inputIndex] || '';
          });
        }

        this.set('__value', newValue);
        onChange(this.get('__value'));
        Ember.run.later(function () {
          return _this.focusIndex(_this.get('inputLength') - 1);
        }, 100);
        return;
      } // block non-int entry


      if (value !== '' && isNaN(parseInt(value))) {
        ev.target.value = '';
        return;
      }

      Ember.run.schedule('actions', function () {
        var deleting = ev.keyCode === 8; // figure out and focus input (back/forw) backspace === keyCode 8

        var focusI = deleting || ev.keyCode === 37 ? ev.target.previousElementSibling || index - 1 : ev.target.nextElementSibling || index + 1;

        _this.focusIndex(focusI); // get current value, split it, set index value, rejoin


        var currValInt = _this.get('__value') || '';
        var valArr = currValInt.split('');

        if (deleting && valArr.length === _this.get('inputLength') && valArr[index] !== '') {
          valArr[index] = '';

          _this.focusIndex(index);
        }

        if (deleting && valArr.length !== _this.get('inputLength')) {
          valArr[index - 1] = '';
        }

        valArr[index] = !deleting && '1234567890'.includes(ev.key) ? ev.key : value;
        newValue = valArr.join(''); // set this for next run

        _this.set('__value', newValue); // bubble change


        onChange(_this.get('__value'));
      });
    }
  });

  _exports.default = _default;
});