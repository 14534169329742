define("slate-payroll-client/templates/components/input/digit-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DB3TUS/4",
    "block": "{\"symbols\":[\"x\",\"index\"],\"statements\":[[4,\"each\",[[23,[\"inputs\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"class\",\"name\",\"maxlength\",\"value\",\"key-up\",\"paste\"],[\"input-digits__digit form-control\",\"digit_entry\",\"1\",[27,\"object-at\",[[22,2,[]],[22,0,[\"valueArray\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"mutateInput\"]],[22,2,[]]],null],[27,\"action\",[[22,0,[]],[23,[\"mutateInput\"]],\"paste\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/input/digit-entry.hbs"
    }
  });

  _exports.default = _default;
});