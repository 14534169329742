define("slate-payroll-client/routes/esa/clear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.$.post('/client-api/employee/esat-clear/' + params.employee_id + '/' + params.clear_token);
    }
  });

  _exports.default = _default;
});