define("slate-payroll-client/components/signature-pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    tagName: 'div',
    classNames: ['signature-pad'],
    classNameBindings: ['fullscreen:signature-pad-fullscreen'],
    displayHandoffDialog: Ember.computed.and('notTouchDevice', 'handoffEnabled', 'notHandoff', 'noHandoffData'),
    notTouchDevice: Ember.computed.not('touchDevice'),
    notHandoff: Ember.computed.not('handoff'),
    noHandoffData: Ember.computed.not('receivedHandoff'),
    width: '800',
    height: '200',
    handoff: false,
    handoffEnabled: true,
    allowFullscreenToggle: true,
    isEmpty: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.goFullScreen();
      this.set('signaturePad', new SignaturePad(this.$('canvas.signature-pad-canvas')[0], {
        velocityFilterWeight: 0.75,
        onBegin: this._strokeStart.bind(this),
        onEnd: this._strokeEnd.bind(this)
      }));
    },
    setSvgData: function setSvgData(svgData) {
      this.get('signaturePad').fromDataURL(svgData);
    },
    clear: function clear() {
      if (this.get('signaturePad')) {
        this.get('signaturePad').clear();
      }

      this.set('isEmpty', true);
    },
    touchDevice: Ember.computed(function () {
      return !!window.Modernizr.touch;
    }),
    _strokeStart: function _strokeStart() {
      this.set('isSigning', true);
    },
    _strokeEnd: function _strokeEnd() {
      this.setProperties({
        isEmpty: this.get('signaturePad').isEmpty(),
        isSigning: false
      });
    },
    goFullScreen: Ember.observer('fullscreen', function () {
      var inRoot = this.get('inRoot'),
          allow = this.get('allowFullscreenToggle'),
          fs = this.get('fullscreen');

      if (!allow && !fs) {
        return;
      }

      var parentClass = this.get('elementId') + '-' + 'parent',
          bodyClass = 'signature-pad-lock';

      if (fs && !inRoot) {
        this.$().after('<div class="' + parentClass + '"></div>');
        this.$().appendTo('.ember-view.app-view');
        Ember.$('html, body').addClass(bodyClass);
        this.set('inRoot', true);
      } else if (inRoot && !fs) {
        Ember.$('.' + parentClass).replaceWith(Ember.$('#' + this.get('elementId')));
        Ember.$('html, body').removeClass(bodyClass);
        this.set('inRoot', false);
      }
    }),
    willDestroy: function willDestroy() {
      if (this.get('inRoot')) {
        var elId = this.get('elementId'),
            parentClass = elId + '-parent';
        Ember.$('#' + elId).remove();
        Ember.$('.' + parentClass).remove();
        Ember.$('html, body').removeClass('signature-pad-lock');
      }
    },
    // Socket Events
    __receiveSignature: function __receiveSignature(data) {
      this.setSvgData(data.svgData);
      this.setProperties({
        pendingHandoff: false,
        receivedHandoff: true
      });

      this._strokeEnd();
    },
    actions: {
      registerHandoff: function registerHandoff() {
        var self = this;
        this.set('registeringHandoff', true);
        this.socket.get('connection').emit('signature-handoff', {
          user: this.get('auth.user.id')
        });
        this.socket.get('connection').on('signature-push', this.__receiveSignature.bind(this));
        this.socket.get('connection').on('signature-verification', function (data) {
          self.setProperties({
            registeringHandoff: false,
            pendingHandoff: true,
            handoffId: data
          });
        });
      },
      transmitSignature: function transmitSignature() {
        this.socket.get('connection').emit('signature-transmission', {
          svgData: this.get('signaturePad').toDataURL(),
          verificationKey: this.get('handoffVerificationKey')
        });
        this.set('transmittedHandoff', true);
      },
      submitSignature: function submitSignature() {
        var action = this.get('action');

        if (action && typeof action === 'function') {
          action(this.get('signaturePad').toDataURL());
        }
      },
      clear: function clear() {
        this.clear();
      },
      toggleFullscreen: function toggleFullscreen() {
        this.toggleProperty('fullscreen');
      }
    }
  });

  _exports.default = _default;
});