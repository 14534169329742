define("slate-payroll-client/components/x-sv-employee-list-item", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href', 'style'],
    href: '#',
    classNames: ['list-group-item', 'sve-list-group-item'],
    classNameBindings: ['isSelected:selected'],
    click: function click(ev) {
      ev.preventDefault();

      if (this.get('onClick')) {
        this.get('onClick')(this.get('employee.id'));
      }
    },
    style: Ember.computed('employee.randomColorRGB', function () {
      return new Ember.String.htmlSafe('border-right: 5px solid ' + this.get('employee.randomColorRGB'));
    }),
    pictureStyles: Ember.computed('employee.randomColorRGB', function () {
      return 'background-color: ' + this.get('employee.randomColorRGB') + '; background-blend-mode: hard-light;';
    }),
    isSelected: Ember.computed('employee', 'selected', function () {
      return this.get('employee') === this.get('selected');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._getLastPunch();
    },
    _getLastPunch: Ember.observer('employee', function () {
      var _this = this;

      this.store.query('punch', {
        employee: this.get('employee.id'),
        timeIn: {
          $lt: (0, _moment.default)().add(1, 'hour').toDate()
        },
        limit: 1,
        sort: {
          timeIn: -1
        }
      }).then(function (results) {
        _this.set('lastPunch', results.get('firstObject'));
      });
    }),
    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        if (!confirm('Are you sure you want to reset this employee\'s password?')) {
          return;
        }

        return Ember.$.post("/client-api/employee/esat-reset/".concat(this.get('employee.id'))).then(Ember.run.bind(this, function () {
          return _this2.set('didResetPassword', true);
        }));
      }
    }
  });

  _exports.default = _default;
});