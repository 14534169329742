define("slate-payroll-client/utils/title-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleCase;

  function titleCase(value) {
    return value && typeof value === 'string' ? value.replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    }) : value;
  }
});