define("slate-payroll-client/templates/components/lazy-model-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgmSAhWU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"working\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-spin fa-spinner\"],[9],[10],[0,\" Loading Selections...\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"resolvedModel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"select-value\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"selection\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"read-path\",[[23,[\"selection\"]],[23,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Click To Select\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-caret-down\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"bound-select\",null,[[\"selectId\",\"selectClass\",\"optionLabelPath\",\"optionValuePath\",\"selection\",\"content\",\"action\",\"prompt\"],[[23,[\"selectId\"]],[23,[\"selectClass\"]],[23,[\"optionLabelPath\"]],[23,[\"optionValuePath\"]],[23,[\"selection\"]],[23,[\"resolvedModel\"]],[27,\"action\",[[22,0,[]],[23,[\"updateValue\"]]],null],[27,\"if\",[[23,[\"prompt\"]],[23,[\"prompt\"]],\"Click To Select\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"select-value text-muted\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-times-circle\"],[9],[10],[0,\" No selections available.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/lazy-model-select.hbs"
    }
  });

  _exports.default = _default;
});