define("slate-payroll-client/templates/components/list/collapsing-nav-pills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hn0yTMeO",
    "block": "{\"symbols\":[\"link\"],\"statements\":[[4,\"each\",[[23,[\"links\"]]],null,{\"statements\":[[4,\"active-link\",null,null,{\"statements\":[[4,\"link-to\",[[22,1,[\"link\"]],[22,1,[\"parameter\"]]],[[\"title\"],[[22,1,[\"title\"]]]],{\"statements\":[[0,\"      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-\",[22,1,[\"icon\"]]]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"collapsed\"]]],null,{\"statements\":[[4,\"ember-tooltip\",null,[[\"side\"],[\"right\"]],{\"statements\":[[0,\"          \"],[1,[22,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[7,\"div\"],[11,\"role\",\"button\"],[11,\"class\",\"nav-pills-collapsing__toggle\"],[3,\"action\",[[22,0,[]],[23,[\"toggleProperty\"]],\"collapsed\"]],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-angle-\",[27,\"if\",[[23,[\"collapsed\"]],\"right\",\"left\"],null]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/list/collapsing-nav-pills.hbs"
    }
  });

  _exports.default = _default;
});