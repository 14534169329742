define("slate-payroll-client/routes/punch/timesheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        payPeriod: this.controllerFor('punch').get('activePayPeriod'),
        paycodes: this.store.query('company-paycode', {
          'permissions.employees': true,
          sort: {
            description: 1
          }
        }).then(function () {
          var paycodes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          return (paycodes.toArray ? paycodes.toArray() : paycodes).sort(function (x, y) {
            return x.get('isDefault') && y.get('isDefault') ? 0 : x.get('isDefault') ? -1 : 1;
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.payPeriod,
        availablePaycodes: model.paycodes
      });

      controller._resetPending();
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('punchQueue.length') > 0 && !confirm('Are you sure you want to abandon pending punches?')) {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});