define("slate-payroll-client/routes/supervisor/punches/add-many", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var employeeQuery = {
        sort: {
          'name.last': 1
        },
        $or: [{
          terminatedOn: {
            $not: {
              $type: 9
            }
          }
        }, {
          terminatedOn: {
            $gt: (0, _moment.default)().subtract(2, 'weeks').toDate()
          }
        }, {
          terminatedOn: null
        }]
      };

      if (this.paramsFor('supervisor').allSupervised) {
        employeeQuery.includeRecursiveSupervised = true;
      }

      return Ember.RSVP.hash({
        paycodes: this.store.query('company-paycode', {
          'permissions.supervisors': true,
          sort: {
            description: 1,
            type: 1
          }
        }),
        employees: this.store.query('employee', employeeQuery),
        punch: this.store.createRecord('punch', {
          status: 'Entered by Supervisor',
          employee: null,
          timesheet: true
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.punch,
        employees: model.employees,
        paycodes: model.paycodes
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controller;

        if (!controller.get('showModal')) {
          return;
        }

        if (controller.get('model.isNew')) {
          controller.get('model').destroyRecord();
        }

        transition.abort();
        controller.set('showModal', false);
        Ember.$('#' + controller.get('modalId')).one('hidden.bs.modal', Ember.run.bind(this, function () {
          transition.retry();
          controller.set('lockSelection', false);
          controller.get('punchesController').send('refreshModel');
        }));
        /*
        $('#' + controller.get('modalId')).one('hidden.bs.modal', () => {
          transition.retry()
          .then(() => {
            controller.set('lockSelection', false);
            controller.get('punchesController').send('refreshModel');
          });
        });
        */
      },
      didTransition: function didTransition() {
        // We must turn the modal on every time we enter the route
        this.controller.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});