define("slate-payroll-client/mixins/authenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    auth: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.get('auth.authenticated')) {
        this.__transition(transition);

        return;
      }

      this._super.apply(this, arguments);
    },
    authenticationChanged: Ember.observer('auth.authenticated', function () {
      if (!this.get('auth.authenticated')) {
        this.__transition();
      }
    }),
    __transition: function __transition(transition) {
      var _this = this;

      if (transition) {
        transition.abort();
        this.controllerFor('index').set('savedTransition', transition);
      }

      return localforage.getItem('slateClientStickyCompanyId').then(function (stickyId) {
        return stickyId ? _this.transitionTo('index', stickyId) : _this.transitionTo('no-url-key');
      });
    }
  });

  _exports.default = _default;
});