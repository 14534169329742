define("slate-payroll-client/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    normalize: function normalize(modelClass, hash) {
      if (!hash.id && hash._id) {
        hash.id = hash._id;
      }

      delete hash._id;
      delete hash.__v;
      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json._id = json.id;
      return json;
    }
  });

  _exports.default = _default;
});