define("slate-payroll-client/templates/xsu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xk9PAHMn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"full-height animated fadeInUp\"],[9],[0,\"\\n  \"],[7,\"hgroup\"],[11,\"class\",\"text-center vertically-centered\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-spinner fa-spin\"],[9],[10],[0,\"\\n      Starting spoofing session...\\n    \"],[10],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"Actions you make in this session will be on behalf of the user being spoofed.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/xsu.hbs"
    }
  });

  _exports.default = _default;
});