define("slate-payroll-client/components/action-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['title', 'rel', 'tabindex', 'target', 'href'],
    click: function click() {
      var action = this.get('action');

      if (action && typeof action === 'function') {
        action();
      }
    }
  });

  _exports.default = _default;
});