define("slate-payroll-client/routes/supervisor", ["exports", "slate-payroll-client/mixins/authenticated-route", "slate-payroll-client/mixins/esa-authenticated-route"], function (_exports, _authenticatedRoute, _esaAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, _esaAuthenticatedRoute.default, {
    queryParams: {
      allSupervised: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});