define("slate-payroll-client/templates/components/input/multi-select/x-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KxCu5Kc3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-\",[27,\"if\",[[23,[\"selected\"]],\"check-circle\",\"circle-o\"],null]]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"read-path\",[[23,[\"item\"]],[23,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"item\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/input/multi-select/x-option.hbs"
    }
  });

  _exports.default = _default;
});