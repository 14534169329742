define("slate-payroll-client/helpers/format-moment", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    auth: Ember.inject.service(),
    compute: function compute(_ref, _ref2) {
      var _ref3 = _slicedToArray(_ref, 2),
          timestamp = _ref3[0],
          pattern = _ref3[1];

      var forceTz = _ref2.forceTz;

      var _pattern = typeof pattern === 'string' ? pattern : 'MM/DD/YYYY',
          momentInst = (0, _moment.default)(timestamp);

      if (forceTz) {
        momentInst = momentInst.tz(this.auth.tz || 'America/Denver');
      }

      return momentInst.format(_pattern);
    }
  });

  _exports.default = _default;
});