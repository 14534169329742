define("slate-payroll-client/components/list-item/punch-view-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-group-item', 'clearfix']
  });

  _exports.default = _default;
});