define("slate-payroll-client/templates/no-url-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPN+Rr6K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"jumbotron jumbotron-index text-center\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[7,\"small\"],[9],[0,\"It looks like you are a little lost...\"],[10],[10],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"To log in to Slate, you must navigate to your company's URL. (ex. ae.slatepayroll.com)\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/no-url-key.hbs"
    }
  });

  _exports.default = _default;
});