define("slate-payroll-client/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Error',
    model: function model() {
      return localforage.getItem('slateClientStickyCompanyId');
    },
    actions: {
      didTransition: function didTransition() {
        var _this = this;

        var controller = this.get('controller'),
            lf = window.localforage;

        if (!controller.get('fromError')) {
          return lf.getItem('slateRoutePreviousToError').then(function (previousRoute) {
            return _this.transitionTo(previousRoute || 'index');
          });
        }

        var previousRoute = controller.get('previousRoute');

        if (previousRoute) {
          lf.setItem('slateRoutePreviousToError', previousRoute);
        }
      },
      willTransition: function willTransition() {
        window.localforage.setItem('slateRoutePreviousToError', null);
      }
    }
  });

  _exports.default = _default;
});