define("slate-payroll-client/routes/supervisor/approvals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      return Ember.RSVP.resolve(this.get('auth.user')).then(function (user) {
        return Ember.get(user, 'company');
      }).then(function (company) {
        if (!Ember.get(company, 'enablePAS')) {
          return _this.transitionTo('supervisor.index');
        }
      });
    }
  });

  _exports.default = _default;
});