define("slate-payroll-client/models/employee", ["exports", "ember-data", "slate-payroll-client/utils/address-formatter"], function (_exports, _emberData, _addressFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-undef */
  var attr = _emberData.default.attr;

  function firstCharacter(str) {
    return str && str.charAt(0) ? str.charAt(0).toUpperCase() : '';
  }

  var _default = _emberData.default.Model.extend({
    employeeNumber: attr('string'),
    ssn: attr('string'),
    phone: attr('string'),
    phoneMobile: attr('string'),
    phoneExt: attr('string'),
    email: attr('string'),
    maritalStatus: attr('string'),
    picture: attr('string'),
    exempt: attr('boolean'),
    partTime: attr('boolean', {
      defaultValue: false
    }),
    electronicPaystub: attr('boolean'),
    allowAdvanced: attr('boolean'),
    codeBasedPunch: attr('boolean'),
    codeBasedPunchCodes: _emberData.default.hasMany('company-paycodes', {
      async: true
    }),
    esaEnabled: attr('boolean'),
    tfaDismiss: attr('boolean'),
    tfaMethods: attr('array'),
    tfaEnabled: attr('boolean'),
    jobTitle: attr('string'),
    gender: attr('string'),
    emergencyContactName: attr('string'),
    emergencyContactPhone: attr('string'),
    workersCompensation: attr('string'),
    payRateHourly: attr('number'),
    payRateSalary: attr('number'),
    payRateVacation: attr('number'),
    limitsVacation: attr('number'),
    accrualSick: attr('number', {
      defaultValue: 0
    }),
    accrualSickHours: attr('number', {
      defaultValue: 0
    }),
    accrualSickPaid: attr('number', {
      defaultValue: 0
    }),
    accrualSickMax: attr('number', {
      defaultValue: 0
    }),
    accrualVacation: attr('number', {
      defaultValue: 0
    }),
    accrualVacationHours: attr('number', {
      defaultValue: 0
    }),
    accrualVacationPaid: attr('number', {
      defaultValue: 0
    }),
    accrualVacationMax: attr('number', {
      defaultValue: 0
    }),
    federalTaxAllowances: attr('number', {
      defaultValue: 0
    }),
    federalTaxAdditionalWithholding: attr('number', {
      defaultValue: 0
    }),
    federalTaxFilingStatus: attr('string'),
    stateTaxName: attr('string', {
      defaultValue: 'MT'
    }),
    stateTaxAllowances: attr('number', {
      defaultValue: 0
    }),
    stateTaxAdditionalWithholding: attr('number', {
      defaultValue: 0
    }),
    stateTaxFilingStatus: attr('string'),
    stateTaxUnemploymentInsurance: attr('string', {
      defaultValue: 'MT'
    }),
    hsaAccount: attr('string'),
    hsaRouting: attr('string'),
    hsaType: attr('string'),
    firstName: attr('string'),
    middleInitial: attr('string'),
    lastName: attr('string'),
    suffix: attr('string'),
    addressLine1: attr('string'),
    addressLine2: attr('string'),
    addressCity: attr('string'),
    addressState: attr('string'),
    addressZipcode: attr('string'),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null
    }),
    location: _emberData.default.belongsTo('location', {
      async: true,
      inverse: null
    }),
    department: _emberData.default.belongsTo('department', {
      async: true,
      inverse: null
    }),
    bankAccounts: _emberData.default.hasMany('bank-account'),
    supervisor: _emberData.default.belongsTo('employee', {
      async: true,
      inverse: null
    }),
    balances: attr('array'),
    punchViews: attr('array'),
    legacyEmployeeId: attr('number'),
    legacyUsername: attr('string'),
    legacyDeptId: attr('number'),
    legacySupervisorId: attr('number'),
    legacyManagerId: attr('number'),
    legacyLocationId: attr('number'),
    legacyBarcodeAvailability: attr('boolean'),
    legacyOngoingComment: attr('string'),
    legacyMemo: attr('string'),
    hireDate: attr('date'),
    dateOfBirth: attr('date'),
    'time_stamp': attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    supervisedEmployees: Ember.computed('id', function () {
      return this.store.query('employee', {});
    }),
    isSupervisor: Ember.computed('supervisedEmployees.isFulfilled', function () {
      var supervises = this.get('supervisedEmployees');
      return supervises.get('isFulfilled') ? supervises.get('content.length') > 0 : undefined;
    }),
    briefName: Ember.computed('firstName', 'lastName', function () {
      var fn = this.get('firstName'),
          ln = this.get('lastName');
      return fn && ln ? fn + ' ' + ln.charAt(0) + '.' : fn;
    }),
    fullName: Ember.computed('firstName', 'lastName', 'middleInitial', 'suffix', function () {
      var n = this.getProperties('firstName', 'lastName', 'middleInitial', 'suffix'),
          ret = '';

      if (n.firstName) {
        ret += n.firstName;
      }

      if (n.middleInitial) {
        ret += ' ' + n.middleInitial + '. ';
      }

      if (n.lastName) {
        ret += ' ' + n.lastName;
      }

      if (n.suffix) {
        ret += ' ' + n.suffix;
      }

      return ret.length > 0 ? ret : undefined;
    }),
    initials: Ember.computed('firstName', 'lastName', 'middleInitial', function () {
      var data = this.getProperties('firstName', 'lastName', 'middleInitial'),
          ret = firstCharacter(data.firstName);

      if (data.middleInitial) {
        ret += firstCharacter(data.middleInitial);
      }

      ret += firstCharacter(data.lastName);
      return ret;
    }),
    hasAddress: Ember.computed('addressLine1', 'addressCity', 'addressState', function () {
      return this.get('addressLine1') && this.get('addressCity') && this.get('addressState');
    }),
    addressFormatted: _addressFormatter.default.property('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode'),
    randomColor: Ember.computed('firstName', 'lastName', function () {
      var defaultColor = {
        r: 255,
        g: 255,
        b: 255
      };
      var data = this.getProperties('firstName', 'lastName'),
          al = 'abcdefghijklmnopqrstuvwxyz'.split('');

      if (!data.firstName || !data.lastName) {
        return defaultColor;
      }

      var indices = {
        f: al.indexOf(firstCharacter(data.firstName).toLowerCase()) * 10,
        l: al.indexOf(firstCharacter(data.lastName).toLowerCase()) * 10
      };
      indices.o = Math.abs(indices.f - indices.l);
      return {
        r: indices.f > 255 ? 255 : indices.f,
        g: indices.l > 255 ? 255 : indices.l,
        b: indices.o > 255 ? 255 : indices.o
      };
    }),
    randomColorRGB: Ember.computed('randomColor', function () {
      var colors = this.get('randomColor');
      return 'rgb(' + colors.r + ', ' + colors.g + ', ' + colors.b + ')';
    }),
    phoneFormatted: Ember.computed('phone', function () {
      var phone = this.get('phone');
      return phone ? phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : phone;
    }),
    md5EmailHash: Ember.computed('email', function () {
      var email = this.get('email');
      return email ? md5(email.toLowerCase().trim()) : undefined;
    }),
    gravatarUrl: Ember.computed('md5EmailHash', function () {
      var md5EmailHash = this.get('md5EmailHash');
      return md5EmailHash ? 'https://secure.gravatar.com/avatar/' + md5EmailHash + '?d=identicon&s=200' : undefined;
    }),
    apiPictureUrl: Ember.computed('picture', function () {
      var picture = this.get('picture');

      if (picture) {
        return picture;
      }
    })
  });

  _exports.default = _default;
});