define("slate-payroll-client/controllers/punch/time-off/edit", ["exports", "slate-payroll-client/mixins/controller-abstractions/add-edit"], function (_exports, _addEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_addEdit.default, {
    auth: Ember.inject.service(),
    showModal: true,
    modalId: 'edit-time-off-request',
    modalTitle: '<h2>Edit Time Off Request</h2>',
    transitionWithModel: false,
    transitionAfterSave: 'punch.time-off.index',
    grossBalance: Ember.computed('model.companyPaycode', 'auth.user.balances.@each.description', function () {
      // Grabs the id for the companyPaycode rel
      var paycode = this.get('model').belongsTo('companyPaycode').id();
      return paycode ? this.get('auth.user.balances').find(function (balance) {
        return balance.companyPaycode === paycode;
      }) : undefined;
    }),
    totalSelectedHours: Ember.computed('model.days.@each.{hours,half}', function () {
      var selectedDays = this.get('model.days');
      return selectedDays ? selectedDays.reduce(function (accumulator, day) {
        return accumulator + (day.hours || (day.half ? 4 : 8));
      }, 0) : 0;
    }),
    approvedFutureHours: Ember.computed('requests.[]', 'model.companyPaycode', function () {
      var paycode = this.model.belongsTo('companyPaycode').id(),
          balanceForPaycode = (this.get('auth.user.balances') || []).find(function (balance) {
        return balance.companyPaycode === paycode;
      }),
          pp = this.payPeriod,
          timePeriodStart = balanceForPaycode ? moment(balanceForPaycode.asOf).isBefore(pp.start) ? balanceForPaycode.asOf : pp.start : pp.start;
      return (this.requests ? this.requests.toArray() : []).reduce(function (total, request) {
        if (!request.reviewedOn || !request.approved || paycode !== request.belongsTo('companyPaycode').id() || !moment(request.days[0].date).isAfter(timePeriodStart)) {
          return total;
        }

        return total + (request.totalHours || 0);
      }, 0);
    }),
    netBalance: Ember.computed('grossBalance.balance', 'totalSelectedHours', function () {
      return (this.get('grossBalance.balance') || 0) - (this.get('totalSelectedHours') || 0) - (this.get('approvedFutureHours') || 0);
    }),
    actions: {
      changeBalancePaycode: function changeBalancePaycode(companyPaycode) {
        // When we fetch the grossBalance data above, the belongsTo
        // cannot be just an id, as ED doesn't understand non-model refs.
        // We push some skeleton data into the ref, just with the id.
        if (companyPaycode) {
          this.get('model').belongsTo('companyPaycode').push({
            data: {
              type: 'companyPaycode',
              id: companyPaycode.get('id')
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});