define("slate-payroll-client/routes/2fa/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // ajax: service(),
    auth: Ember.inject.service(),
    model: function model() {
      return this.auth.user;
    },
    setupController: function setupController(controller) {
      controller.setProperties({
        qr: null,
        secret: null,
        selectedTfMethod: null,
        displayMethodSetup: false
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});