define("slate-payroll-client/templates/components/time-off/time-of-day-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mGEpI7F2",
    "block": "{\"symbols\":[\"day\"],\"statements\":[[4,\"each\",[[27,\"sort-by\",[\"date\",[23,[\"request\",\"days\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"time-off/time-of-day-select-day\",null,[[\"request\",\"day\",\"company\",\"onChange\"],[[23,[\"request\"]],[22,1,[]],[23,[\"company\"]],[27,\"action\",[[22,0,[]],[22,0,[\"changeDay\"]],[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/time-off/time-of-day-select.hbs"
    }
  });

  _exports.default = _default;
});