define("slate-payroll-client/serializers/time-off-request", ["exports", "slate-payroll-client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName !== 'time-off-request') {
        return this._super.apply(this, arguments);
      }

      if (hash.days) {
        hash.days = hash.days.map(function (day) {
          day.date = new Date(day.date);
          return day;
        });
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot) {
      var approvedWasSet = typeof snapshot.attr('approved') === 'boolean',
          json = this._super.apply(this, arguments);

      if (!approvedWasSet) {
        json.approved = undefined;
      }

      return json;
    }
  });

  _exports.default = _default;
});