define("slate-payroll-client/templates/components/x-sve-punch-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BaxMh6W1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-circle\",[27,\"unless\",[[23,[\"isClockedIn\"]],\"-o\"],null]]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/x-sve-punch-indicator.hbs"
    }
  });

  _exports.default = _default;
});