define("slate-payroll-client/templates/components/pay-period-grid-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fur1EJAs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"punches.view\",[23,[\"payPeriod\",\"id\"]]],[[\"class\"],[\"thumbnail view-overlay\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-thumbnail view-popover\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"text-center\"],[9],[1,[27,\"format-moment\",[[23,[\"payPeriod\",\"paidOn\"]],\"M/D/YY\"],null],false],[10],[0,\"\\n    \"],[7,\"h6\"],[11,\"class\",\"text-muted text-center\"],[9],[0,\"\\n      \"],[1,[27,\"format-moment\",[[23,[\"payPeriod\",\"start\"]],\"M/D\"],null],false],[0,\" -\\n      \"],[1,[27,\"format-moment\",[[23,[\"payPeriod\",\"end\"]],\"M/D\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/pay-period-grid-item.hbs"
    }
  });

  _exports.default = _default;
});