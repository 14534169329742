define("slate-payroll-client/controllers/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    clientError: Ember.computed('fromError', function () {
      var error = this.get('fromError');
      return error && error.status === 400;
    })
  });

  _exports.default = _default;
});