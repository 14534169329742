define("slate-payroll-client/controllers/supervisor/time-off/approve", ["exports", "slate-payroll-client/mixins/render-tooltips", "slate-payroll-client/mixins/controller-abstractions/add-edit", "slate-payroll-client/mixins/confirm"], function (_exports, _renderTooltips, _addEdit, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_renderTooltips.default, _addEdit.default, _confirm.default, {
    auth: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.transitionWithModel = false;
      this.transitionAfterSave = 'supervisor.time-off.index';
      this.requireFields = ['companyPaycode.content'];
      this.requireFieldDescriptors = {
        'companyPaycode.content': 'paycode'
      };
    },
    actions: {
      approveRequest: function approveRequest() {
        var _this = this;

        var model = this.get('model');
        this.confirm('Are you sure you want to approve this request?', 'Are you sure?').then(function (ok) {
          if (ok) {
            model.set('approved', true);

            _this.send('save', model);
          } else {
            _this.transitionToRoute('supervisor.time-off.approve', model.get('id'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});