define("slate-payroll-client/components/x-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    tagName: 'nav',
    role: 'navigation',
    classNames: ['navbar', 'navbar-default', 'app-navbar'],
    attributeBindings: ['role'],
    actions: {
      logout: function logout() {
        this.get('logout')();
      }
    }
  });

  _exports.default = _default;
});