define("slate-payroll-client/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HgUbcFLL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"app-view\"],[9],[0,\"\\n  \"],[1,[27,\"x-navigation\",null,[[\"logout\",\"alternateLogo\",\"unreadDocs\",\"unreadReminders\"],[[27,\"action\",[[22,0,[]],\"logout\"],null],[23,[\"companyLogo\"]],[23,[\"auth\",\"unreadDocs\"]],[23,[\"auth\",\"unreadReminders\"]]]]],false],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[1,[27,\"x-footer\",null,[[\"buildInfo\"],[[23,[\"buildInfo\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/application.hbs"
    }
  });

  _exports.default = _default;
});