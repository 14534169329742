define("slate-payroll-client/controllers/reminders", ["exports", "slate-payroll-client/mixins/resource-pagination", "slate-payroll-client/mixins/controller-abstractions/delete", "slate-payroll-client/mixins/render-tooltips", "slate-payroll-client/utils/title-case"], function (_exports, _resourcePagination, _delete, _renderTooltips, _titleCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_resourcePagination.default, _delete.default, _renderTooltips.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this._sortOptions = [{
        label: 'Due Date',
        value: 'next'
      }, 'description', 'code', 'missed'];
    },
    queryParams: ['page', 'sortAsc', 'sortBy'],
    sortBy: 'next',
    sortAsc: false,
    page: 1,
    limit: 20,
    sortOptions: Ember.computed('_sortOptions', function () {
      var sortOptions = this.get('_sortOptions');
      return sortOptions.map(function (option) {
        return typeof option === 'string' ? {
          label: (0, _titleCase.default)([option]),
          value: option
        } : option;
      });
    }),
    reRenderTooltips: Ember.observer('model.[]', function () {
      this._renderTooltips();
    }),
    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });

  _exports.default = _default;
});