define("slate-payroll-client/components/time-off/view-calendar/request-item", ["exports", "slate-payroll-client/utils/computed-equals-key", "moment"], function (_exports, _computedEqualsKey, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['cal__request', 'clearfix'],
    classNameBindings: ['request.approved::cal__request-pending', 'isFocused:cal__request-focused'],
    isFocused: (0, _computedEqualsKey.default)('focusedRequest', 'request'),
    requestDay: Ember.computed('request.days.@each.date', 'dayDate', function () {
      var date = this.get('dayDate');
      return this.get('request.days').find(function (day) {
        return (0, _moment.default)(date).isSame(day.date, 'day');
      });
    }),
    timeDisplay: Ember.computed('showTimes', 'requestDay.{end,date}', function () {
      if (!this.get('showTimes') || !this.get('requestDay.end')) {
        return;
      }

      var st = (0, _moment.default)(this.get('requestDay.date')),
          ed = (0, _moment.default)(this.get('requestDay.end')); // slice converts pm to p and am to a.
      // change format for the *most concise* display - if minutes, show minutes, if not, do not

      return [st.format(st.minute() ? 'h:mma' : 'ha').slice(0, -1), ed.format(ed.minute() ? 'h:mma' : 'ha').slice(0, -1)].join('-');
    }),
    hours: Ember.computed('requestDay', function () {
      var day = this.get('requestDay');
      return day ? day.hours ? day.hours : day.half ? 4 : 8 : undefined;
    }),
    _triggerMouseAction: function _triggerMouseAction(method) {
      var methodFn = this.get(method);

      if (methodFn && typeof methodFn === 'function') {
        methodFn(this.get('request'));
      }
    },
    mouseMove: function mouseMove() {
      this._triggerMouseAction('focus');
    },
    mouseLeave: function mouseLeave() {
      this._triggerMouseAction('unfocus');
    },
    click: function click() {
      this.get('interact')('request', this.get('request'));
    }
  });

  _exports.default = _default;
});