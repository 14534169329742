define("slate-payroll-client/components/time-off/request-item", ["exports", "slate-payroll-client/mixins/render-tooltips", "moment"], function (_exports, _renderTooltips, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusIcons = {
    approved: 'check',
    rejected: 'times',
    pending: 'ellipsis-h'
  };

  var _default = Ember.Component.extend(_renderTooltips.default, {
    classNames: ['tor__component'],
    tagName: 'li',
    requestIsPending: Ember.computed.equal('requestStatus', 'pending'),
    // You can delete a TOR if it is equal to or greater than the start of the
    // current payPeriod
    isHistoric: Ember.computed('request.days.firstObject.date', function () {
      var firstDay = this.get('request.days.firstObject.date'),
          activePayPeriodStart = this.get('activePayPeriod.start');
      return (0, _moment.default)(firstDay).isBefore((0, _moment.default)(activePayPeriodStart)) || false;
    }),
    // Returns a string of month(s) as `contextualMonths`
    // based on the days selected
    contextualMonths: Ember.computed('request.days.@each.date', function () {
      return this.get('request.days') ? this.get('request.days').reduce(function (accumulator, day) {
        accumulator.addObject((0, _moment.default)(day.date).format('MMMM'));
        return accumulator;
      }, Ember.A()).join(', ') : 'N/A';
    }),
    daysTooltipContent: Ember.computed('request.days.@each.date', function () {
      return 'Days in this request:\n' + (this.get('request.days') ? this.get('request.days').reduce(function (accumulator, day) {
        return accumulator.concat((0, _moment.default)(day.date).format('MM/DD/YYYY'));
      }, []).join('\n') : 'None');
    }),
    commentTooltipContent: Ember.computed('request.supervisorNotes', function () {
      var notes = this.get('request.supervisorNotes');
      return notes ? 'Your supervisor\'s notes: ' + notes : undefined;
    }),
    statusIcon: Ember.computed('requestStatus', function () {
      var requestStatus = this.get('requestStatus');
      return statusIcons[requestStatus];
    }),
    requestStatus: Ember.computed('request.{approved,reviewedOn}', function () {
      var approved = this.get('request.approved'),
          reviewedOn = this.get('request.reviewedOn');

      if (approved) {
        return 'approved';
      } else if (!approved && reviewedOn) {
        return 'rejected';
      } else {
        return 'pending';
      }
    }),
    actions: {
      destroyRecord: function destroyRecord() {
        var action = this.get('onDelete'),
            model = this.get('request');
        model.destroyRecord();

        if (action && typeof action === 'function') {
          action(model);
        }
      }
    }
  });

  _exports.default = _default;
});