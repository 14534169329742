define("slate-payroll-client/controllers/supervisor/time-off/historical", ["exports", "slate-payroll-client/mixins/resource-pagination"], function (_exports, _resourcePagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'e'],
    page: 1,
    e: undefined // Employee

  });

  _exports.default = _default;
});