define("slate-payroll-client/templates/components/paystub-document-employee-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8KamK2qI",
    "block": "{\"symbols\":[\"balance\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n    \"],[7,\"h4\"],[11,\"class\",\"section-small-heading\"],[9],[0,\"You\"],[10],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"inline-heading info-heading\"],[9],[0,\"\\n      \"],[1,[23,[\"employee\",\"fullName\"]],false],[7,\"br\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"text-sm clearfix text-muted\"],[9],[0,\"\\n        \"],[1,[23,[\"employee\",\"employeeNumber\"]],false],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n          \"],[1,[23,[\"employee\",\"department\",\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"balances\",\"firstObject\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\"],[11,\"class\",\"section-small-heading\"],[9],[0,\"Balances\"],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"data-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"balances\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[0,\"\\n            \"],[1,[27,\"round-to\",[[22,1,[\"balance\"]],3],null],false],[0,\"\\n            \"],[7,\"label\"],[9],[1,[22,1,[\"description\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/paystub-document-employee-information.hbs"
    }
  });

  _exports.default = _default;
});