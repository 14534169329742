define("slate-payroll-client/serializers/session", ["exports", "ember-localforage-adapter/serializers/localforage"], function (_exports, _localforage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localforage.default.extend({});

  _exports.default = _default;
});