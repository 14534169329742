define("slate-payroll-client/templates/components/paystub-document-deductions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r8x0/cTt",
    "block": "{\"symbols\":[\"deduction\"],\"statements\":[[7,\"h4\"],[11,\"class\",\"section-small-heading text-center\"],[9],[0,\"Deductions\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"transaction-list\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row label-row no-gutter\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-4 col-xs-offset-8 text-right\"],[9],[0,\"Total\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"deductions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"row no-gutter\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"\\n        \"],[1,[27,\"if\",[[22,1,[\"companyPaycode\",\"paycode\",\"description\"]],[22,1,[\"companyPaycode\",\"paycode\",\"description\"]],[22,1,[\"description\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-4 text-right\"],[9],[0,\"\\n        \"],[1,[27,\"x-currency\",[[22,1,[\"total\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"h4\"],[11,\"class\",\"text-center text-muted\"],[9],[0,\"No Deductions For This Paystub.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/paystub-document-deductions.hbs"
    }
  });

  _exports.default = _default;
});