define("slate-payroll-client/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    queryParams: ['u', 'intent'],
    companyLogo: null,
    actions: {
      logout: function logout() {
        this.get('target').send('logout');
      }
    }
  });

  _exports.default = _default;
});