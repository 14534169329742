define("slate-payroll-client/components/list-item/broadcast-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BroadcastItemComponent = Ember.Component.extend({
    tagName: 'li',
    didInsertElement: function didInsertElement() {
      var readByIds = this.get('broadcast').hasMany('readBy').ids();

      if (this.currentUserId && !readByIds.includes(this.currentUserId)) {
        this.markAsRead();
      }
    },
    markAsRead: function markAsRead() {
      Ember.$.post("/client-api/broadcast/".concat(this.get('broadcast.id'), "/mark-read"));
    }
  });
  BroadcastItemComponent.reopenClass({
    positionalParams: ['broadcast']
  });
  var _default = BroadcastItemComponent;
  _exports.default = _default;
});