define("slate-payroll-client/templates/components/tax-form-grid-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yaBfYHk8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"href\",[28,[\"/client-api/file-blobs/\",[23,[\"file\",\"id\"]],\"/view?token=\",[23,[\"auth\",\"token\"]]]]],[11,\"rel\",\"noopener\"],[11,\"target\",\"_blank\"],[11,\"class\",\"thumbnail view-overlay\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-thumbnail view-popover\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-file-text\"],[9],[10],[0,\" \"],[1,[23,[\"file\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-center text-sm\"],[9],[0,\"\\n      \"],[1,[27,\"time-ago\",[[23,[\"file\",\"created\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-center text-sm\"],[9],[0,\"\\n      Click to download\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/tax-form-grid-item.hbs"
    }
  });

  _exports.default = _default;
});