define("slate-payroll-client/serializers/employee", ["exports", "ember-data", "slate-payroll-client/serializers/application", "slate-payroll-client/utils/serialize-object", "slate-payroll-client/utils/expand-serialized-object"], function (_exports, _emberData, _application, _serializeObject, _expandSerializedObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      bankAccounts: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName !== 'employee') {
        return this._super.apply(this, arguments);
      }

      if (hash.name) {
        hash.firstName = hash.name.first;
        hash.middleInitial = hash.name.middleInitial;
        hash.lastName = hash.name.last;
        hash.suffix = hash.name.suffix;
        delete hash.name;
      }

      (0, _serializeObject.default)(hash, true, 'emergencyContact', 'address', 'payRate', 'limits', 'accrual', 'federalTax', 'stateTax', 'hsa');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.name = {
        first: json.firstName,
        middleInitial: json.middleInitial,
        last: json.lastName,
        suffix: json.suffix
      };
      delete json.firstName;
      delete json.middleInitial;
      delete json.lastName;
      delete json.suffix;
      (0, _expandSerializedObject.default)(json, 'emergencyContact', 'address', 'payRate', 'limits', 'accrual', 'federalTax', 'stateTax', 'hsa');
      return json;
    }
  });

  _exports.default = _default;
});