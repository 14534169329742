define("slate-payroll-client/components/signature-pad-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'canvas',
    classNames: ['signature-pad-canvas'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      window.onresize = this._resizeCanvas.bind(this);

      this._resizeCanvas();
    },
    fullscreenDidChange: Ember.observer('fullscren', function () {
      this._resizeCanvas();
    }),
    _resizeCanvas: function _resizeCanvas() {
      if (!this.$()) {
        return;
      }

      var canvas = this.$()[0],
          width = this.get('width'),
          height = this.get('height'),
          wWidth = Ember.$(window).width(),
          wHeight = Ember.$(window).height(),
          fs = this.get('fullscreen');
      var wPercent = width.indexOf('%') > -1,
          hPercent = height.indexOf('%') > -1;
      canvas.height = fs ? wHeight : hPercent ? height.slice(0, -1) / 100 * wHeight : height;
      canvas.width = fs ? wWidth : wPercent ? width.slice(0, -1) / 100 * wWidth : width;
      var action = this.get('action');

      if (action && typeof action === 'function') {
        action();
      }
    }
  });

  _exports.default = _default;
});