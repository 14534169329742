define("slate-payroll-client/models/paystub", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    read: attr('boolean'),
    earnings: _emberData.default.hasMany('earning'),
    deductions: _emberData.default.hasMany('deduction'),
    achTransactions: _emberData.default.hasMany('ach-transaction'),
    balances: attr('array'),
    descriptor: attr('string'),
    payrollDate: attr('date'),
    yearToDateInformationGrossWages: attr('number'),
    yearToDateInformationFederal: attr('number'),
    yearToDateInformationState: attr('number'),
    yearToDateInformationSocialSecurity: attr('number'),
    yearToDateInformationMedicare: attr('number'),
    yearToDateInformationOtherDeductions: attr('number'),
    totalsHours: attr('number'),
    totalsGross: attr('number'),
    totalsDeductions: attr('number'),
    totalsNet: attr('number'),
    withholdingFederal: attr('number'),
    withholdingState: attr('number'),
    withholdingSocialSecurity: attr('number'),
    withholdingMedicare: attr('number'),
    withholdingLocal: attr('number'),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: false
    }),
    employee: _emberData.default.belongsTo('employee', {
      async: true,
      inverse: false
    }),
    payPeriod: _emberData.default.belongsTo('pay-period', {
      async: true,
      inverse: false
    }),
    created: attr('date')
  });

  _exports.default = _default;
});