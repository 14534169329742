define("slate-payroll-client/mixins/ux-ajax-status-hooks", ["exports", "slate-payroll-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    logging: Ember.inject.service(),
    successMessageTimeout: 3,
    __parseErrors: function __parseErrors(errTarget) {
      return Array.isArray(errTarget) ? errTarget.mapBy('detail').join(', ') : errTarget.detail || errTarget;
    },
    ajaxError: function ajaxError(e, user) {
      var errMsg;

      if (e && e.name && e.name.indexOf('TransitionAborted') > -1) {
        return;
      }

      if (e && e.errors || e.payload) {
        errMsg = this.__parseErrors(e.errors || e.payload || e);
      } else {
        errMsg = e && e.responseText ? e.responseText : e;
      }

      if (e.status === 0) {
        this.logging.send(e);
        errMsg = 'Connection interrupted. Please try again. If this message persists, try disabling extensions.';
      }

      if (!user && e) {
        this.logging.send(e);
      }

      this.setProperties({
        working: false,
        errorMessage: errMsg
      });
    },
    ajaxSuccess: function ajaxSuccess(success) {
      var _this = this;

      this.setProperties({
        working: false,
        errorMessage: null,
        successMessage: success
      });
      (_environment.default.environment === 'test' ? setTimeout : Ember.run.later)(function () {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
          _this.set('successMessage', null);
        }
      }, this.get('successMessageTimeout') * 1000);
    },
    ajaxStart: function ajaxStart() {
      this.setProperties({
        working: true,
        errorMessage: null,
        successMessage: null
      });
    }
  });

  _exports.default = _default;
});