define("slate-payroll-client/controllers/supervisor/time-off/index", ["exports", "slate-payroll-client/mixins/controller-abstractions/add-edit", "slate-payroll-client/mixins/confirm"], function (_exports, _addEdit, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_addEdit.default, _confirm.default, {
    auth: Ember.inject.service(),
    queryParams: ['month', 'year'],
    pendingRequests: Ember.computed.filter('model', function (item) {
      return !item.get('reviewedOn');
    }),
    approvedRequests: Ember.computed.filter('model', function (request) {
      return request.get('approved');
    }),
    month: null,
    year: null,
    subscribeUrl: Ember.computed('supervisor', function () {
      var supervisor = this.get('auth.user.id');
      return 'webcal://slatepayroll.com/api/calendar/' + supervisor + '/calendar.ical';
    }),
    afterSave: function afterSave() {
      this.send('refresh');
    },
    actions: {
      approveRequest: function approveRequest(request, immediate) {
        var _this = this;

        if (immediate && request.get('balanceDescription')) {
          this.confirm('Are you sure you want to approve this request?', 'Are you sure?', 'basicConfirmState').then(function (ok) {
            if (ok) {
              request.set('approved', true);

              _this.send('save', request);
            }
          });
        } else {
          this.transitionToRoute('supervisor.time-off.approve', request.get('id'));
        }
      },
      rejectRequest: function rejectRequest(request) {
        var _this2 = this;

        this.confirm(null, 'Reject Request').then(function (confirmed) {
          var supervisorNotes = _this2.get('rejectionReason');

          _this2.set('rejectionReason', null);

          if (!confirmed) {
            return;
          }

          request.setProperties({
            supervisorNotes: supervisorNotes,
            approved: false,
            reviewedOn: new Date()
          });

          _this2.send('save', request);
        });
      }
    }
  });

  _exports.default = _default;
});