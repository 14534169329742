define("slate-payroll-client/components/time-off/select-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getWeekNums(momentObj) {
    var clonedMoment = (0, _moment.default)(momentObj),
        first = clonedMoment.startOf('month').week(),
        last = clonedMoment.endOf('month').week(); // In case last week is in next year

    if (first > last) {
      last = clonedMoment.endOf('month').subtract(1, 'week').week() + 1;
    }

    return last - first + 1;
  }

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.classNames = ['time-off-cal'];
      this.month = undefined;
      this.year = undefined;
      this._cachedMonths = {};
      this.setMonthYear();
    },
    setMonthYear: function setMonthYear() {
      if (Ember.get(this, 'month') || Ember.get(this, 'year')) {
        return;
      }

      var now = (0, _moment.default)();
      Ember.setProperties(this, {
        month: now.format('MMMM'),
        year: now.format('YYYY')
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('_selectedDays', this.get('selectedDays') || Ember.A());
    },
    tz: Ember.computed('auth.tz', function () {
      return this.auth.tz || 'America/Denver';
    }),
    tzDiffers: Ember.computed('tz', function () {
      return this.tz !== _moment.default.tz.guess();
    }),
    tzAbbr: Ember.computed('tz', function () {
      return _moment.default.tz.zone(this.tz).abbr(new Date());
    }),
    dayLabels: Ember.computed(function () {
      var labels = [];

      for (var i = 0; i < 7; i++) {
        labels.push((0, _moment.default)().day(i).format('dddd'));
      }

      return labels; // [ 'Sunday', 'Monday' ... 'Saturday' ]
    }),
    _momentComputed: Ember.computed('month', 'year', function () {
      var month = Ember.get(this, 'month'),
          year = Ember.get(this, 'year');
      return month && year ? (0, _moment.default)().year(year).month(month) : undefined;
    }),
    _weeks: Ember.computed('_momentComputed', 'month', 'year', 'tz', function () {
      var _this = this;

      var mc = Ember.get(this, '_momentComputed'),
          cache = Ember.get(this, '_cachedMonths'),
          year = Ember.get(this, 'year'),
          month = Ember.get(this, 'month'),
          selection = Ember.A(Ember.get(this, '_selectedDays'));

      if (!mc) {
        return undefined;
      }

      if (!cache[year] || !cache[year][month]) {
        var startFrom = (0, _moment.default)(mc).tz(this.tz).startOf('month'),
            weeks = Ember.A(),
            weekNum = getWeekNums(startFrom);

        for (var i = 0; i < weekNum; i++) {
          var m = (0, _moment.default)(startFrom).add(i, 'week');
          weeks.pushObject({
            range: {
              start: (0, _moment.default)(m).startOf('week').toDate(),
              end: (0, _moment.default)(m).endOf('week').toDate()
            }
          });
        }

        var setDaysForWeek = function setDaysForWeek(week) {
          week.days = [];

          for (var d = 0; d < 7; d++) {
            // slate-payroll#962 - .hour(12) sets this date time to noon, needed for possible inter-company shifts across US timezones
            var day = {
              date: (0, _moment.default)(week.range.start).add(d, 'days').tz(_this.tz).hour(12).toDate()
            };
            day.outOfMonth = !(0, _moment.default)(day.date).tz(_this.tz).isSame(startFrom, 'month');
            week.days.push(day);
          }

          return week;
        };

        if (!cache[year]) {
          Ember.set(this, '_cachedMonths.' + year, {});
        }

        Ember.set(this, '_cachedMonths.' + year + '.' + month, weeks.map(setDaysForWeek));
      }

      var findDayInSelection = function findDayInSelection(day) {
        return selection ? selection.find(function (d) {
          return (0, _moment.default)(Ember.get(d, 'date')).isSame(Ember.get(day, 'date'), 'day');
        }) : false;
      };

      Ember.set(this, '_cachedMonths.' + year + '.' + month, this.get('_cachedMonths')[year][month].map(function (week) {
        Ember.set(week, 'days', week.days.map(function (day) {
          var selected = findDayInSelection(day);
          Ember.setProperties(day, {
            selected: !!selected,
            half: selected ? !!Ember.get(selected, 'half') : false,
            hours: selected ? Ember.get(selected, 'hours') : null
          });
          return day;
        }));
        return week;
      }));
      return Ember.get(this, '_cachedMonths')[year][month];
    }),
    nextMonth: Ember.computed('_momentComputed', function () {
      var m = Ember.get(this, '_momentComputed');
      return m ? (0, _moment.default)(m).add(1, 'month').format('MMMM') : undefined;
    }),
    lastMonth: Ember.computed('_momentComputed', function () {
      var m = Ember.get(this, '_momentComputed');
      return m ? (0, _moment.default)(m).subtract(1, 'month').format('MMMM') : undefined;
    }),
    actions: {
      changeMonth: function changeMonth(month) {
        var m = Ember.get(this, 'month');

        if (month === 'January' && m === 'December') {
          this.incrementProperty('year');
        } else if (month === 'December' && m === 'January') {
          this.decrementProperty('year');
        }

        this.set('month', month);
      },
      addDay: function addDay(day) {
        this.get('_selectedDays').addObject(day);
        this.get('action')(Ember.get(this, '_selectedDays'));
      },
      removeDay: function removeDay(day) {
        var selectedDays = Ember.get(this, '_selectedDays');
        selectedDays.removeObject(selectedDays.find(function (d) {
          return (0, _moment.default)(Ember.get(d, 'date')).isSame(Ember.get(day, 'date'), 'day');
        }));
        Ember.get(this, 'action')(Ember.get(this, '_selectedDays'));
      },
      changedDay: function changedDay(day) {
        var selectedDays = Ember.get(this, '_selectedDays');
        selectedDays.removeObject(selectedDays.find(function (d) {
          return (0, _moment.default)(Ember.get(d, 'date')).isSame(Ember.get(day, 'date'), 'day');
        }));
        selectedDays.addObject(day);
        Ember.get(this, 'action')(Ember.get(this, '_selectedDays'));
      }
    }
  });

  _exports.default = _default;
});