define("slate-payroll-client/routes/reminders", ["exports", "slate-payroll-client/mixins/route-abstractions/paginated-view"], function (_exports, _paginatedView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_paginatedView.default, {
    titleToken: 'Reminders',
    modelName: 'reminder',
    limit: 20
  });

  _exports.default = _default;
});