define("slate-payroll-client/templates/2fa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RF8lbvUS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"esa-backdrop\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-lock\"],[9],[10],[0,\" \\n\"],[4,\"if\",[[22,0,[\"tfa\"]]],null,{\"statements\":[[0,\"        Two Factor Authentication\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Confirm Identity\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/2fa.hbs"
    }
  });

  _exports.default = _default;
});