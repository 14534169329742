define("slate-payroll-client/components/x-footer", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['app-footer'],
    year: Ember.computed(function () {
      return (0, _moment.default)().year();
    })
  });

  _exports.default = _default;
});