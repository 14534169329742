define("slate-payroll-client/templates/components/balance-for-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EOz7Yy1B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"balance\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"round-to\",[[23,[\"balance\",\"balance\"]],3],null],false],[0,\"hrs\\n  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[22,0,[\"approvedFutureHours\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[0,\"- \"],[1,[27,\"round-to\",[[22,0,[\"approvedFutureHours\"]],3],null],false],[0,\" = \"],[1,[27,\"round-to\",[[22,0,[\"availableBalance\"]],3],null],false],[10],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n      Future approved time off requests that will use \"],[7,\"strong\"],[9],[1,[27,\"round-to\",[[22,0,[\"approvedFutureHours\"]],3],null],false],[10],[0,\" of this balance\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"asOf\"]]],null,{\"statements\":[[0,\"    as of \"],[1,[27,\"format-moment\",[[23,[\"balance\",\"asOf\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[22,0,[\"loadingRequests\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-spinner fa-spin\"],[9],[10],[0,\" Loading future requests...\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  N/A\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/balance-for-request.hbs"
    }
  });

  _exports.default = _default;
});