define("slate-payroll-client/templates/supervisor/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zFPAdmlQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"Two Factor Authentication\"],[10],[0,\"\\n\"],[4,\"if\",[[22,0,[\"model\",\"tfaMethods\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"text-success\"],[9],[0,\"2FA is enabled on your account.\"],[10],[0,\"\\n\"],[4,\"unless\",[[22,0,[\"model\",\"company\",\"requires2fa\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-sm btn-danger\"],[12,\"disabled\",[21,\"working\"]],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"disableTfa\"]],[9],[0,\"Disable/Reset 2FA\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"text-muted\"],[9],[0,\"\\n        2FA is not enabled. \\n        \"],[7,\"strong\"],[9],[4,\"link-to\",[\"2fa.setup\"],[[\"class\"],[\"text-success\"]],{\"statements\":[[0,\" \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-lock\"],[9],[10],[0,\" Setup 2FA\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/settings.hbs"
    }
  });

  _exports.default = _default;
});