define("slate-payroll-client/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ToolTipComponent = Ember.Component.extend({
    classNames: ['tooltip__renderer'],
    placement: 'top',
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('parent', this.$().parent());
      this.$().parent().tooltip({
        placement: this.get('placement'),
        container: this.get('container') || '.ember-application',
        title: this.get('text'),
        html: !!this.get('html')
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this.get('parent')) {
        this.get('parent').tooltip('destroy');
      }
    }
  });
  ToolTipComponent.reopenClass({
    positionalParams: ['text']
  });
  var _default = ToolTipComponent;
  _exports.default = _default;
});