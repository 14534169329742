define("slate-payroll-client/controllers/esa/clear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    actions: {
      goToLogin: function goToLogin() {
        var _this = this;

        this.get('auth').destroySession().then(function () {
          _this.transitionToRoute('esa.setup');
        });
      }
    }
  });

  _exports.default = _default;
});