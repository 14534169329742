define("slate-payroll-client/controllers/xsu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['t', 'user', 'e', 'redirect'],
    t: null,
    user: null,
    e: null,
    redirect: null
  });

  _exports.default = _default;
});