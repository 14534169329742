define("slate-payroll-client/serializers/paystub", ["exports", "ember-data", "slate-payroll-client/serializers/application", "slate-payroll-client/utils/serialize-object", "slate-payroll-client/utils/expand-serialized-object"], function (_exports, _emberData, _application, _serializeObject, _expandSerializedObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      earnings: {
        embedded: 'always'
      },
      deductions: {
        embedded: 'always'
      },
      achTransactions: {
        embedded: 'always'
      }
    },
    normalize: function normalize(type, hash) {
      (0, _serializeObject.default)(hash, true, 'yearToDateInformation', 'totals', 'withholding');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      (0, _expandSerializedObject.default)(json, 'yearToDateInformation', 'totals', 'withholding');
      return json;
    }
  });

  _exports.default = _default;
});