define("slate-payroll-client/controllers/supervisor/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    'supervisor/employees/employee': Ember.inject.controller(),
    activeEmployee: Ember.computed.reads('supervisor/employees/employee.model'),
    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      selectEmployee: function selectEmployee(employeeId) {
        this.transitionToRoute('supervisor.employees.employee', employeeId);
      }
    }
  });

  _exports.default = _default;
});