define("slate-payroll-client/templates/components/time-off/select-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xY0FxDZJ",
    "block": "{\"symbols\":[\"week\",\"day\",\"label\"],\"statements\":[[7,\"div\"],[11,\"class\",\"cal-controls\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"pull-left\"],[3,\"action\",[[22,0,[]],\"changeMonth\",[23,[\"lastMonth\"]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-angle-double-left\"],[9],[10],[0,\"\\n    \"],[1,[21,\"lastMonth\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"cal-month-label\"],[9],[1,[21,\"month\"],false],[0,\" \"],[1,[21,\"year\"],false],[4,\"if\",[[22,0,[\"tzDiffers\"]]],null,{\"statements\":[[0,\"(\"],[1,[22,0,[\"tzAbbr\"]],false],[0,\")\"]],\"parameters\":[]},null],[10],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"pull-right\"],[3,\"action\",[[22,0,[]],\"changeMonth\",[23,[\"nextMonth\"]]]],[9],[0,\"\\n    \"],[1,[21,\"nextMonth\"],false],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-angle-double-right\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"cal__list-day-labels\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"dayLabels\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[22,3,[]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"_weeks\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"cal-week\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"days\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"time-off/select-calendar/select-day\",null,[[\"day\",\"company\",\"addedDay\",\"removedDay\",\"changedDay\"],[[22,2,[]],[23,[\"company\"]],[27,\"action\",[[22,0,[]],\"addDay\"],null],[27,\"action\",[[22,0,[]],\"removeDay\"],null],[27,\"action\",[[22,0,[]],\"changedDay\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/time-off/select-calendar.hbs"
    }
  });

  _exports.default = _default;
});