define("slate-payroll-client/templates/components/form-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sx17uHvu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-center text-danger\"],[9],[1,[21,\"errorMessage\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"button\"],[12,\"disabled\",[21,\"_disabled\"]],[12,\"class\",[21,\"buttonClass\"]],[11,\"type\",\"submit\"],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw \",[27,\"if\",[[23,[\"loading\"]],\"fa-spinner fa-spin\",[23,[\"icon\"]]],null]]]],[9],[10],[0,\"\\n  \"],[1,[21,\"buttonText\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/form-footer.hbs"
    }
  });

  _exports.default = _default;
});