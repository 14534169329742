define("slate-payroll-client/templates/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pso1aZGn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"text-center text-danger\"],[9],[0,\"401 Unauthorized\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"text-danger text-center\"],[9],[0,\"\\n  You were unauthorized to access that resource.\"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"small\"],[9],[0,\"\\n    \"],[7,\"code\"],[9],[1,[21,\"unauthorizedReason\"],false],[10],[0,\" That's all we know.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-center text-sm\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"index\",[23,[\"model\"]]],null,{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/unauthorized.hbs"
    }
  });

  _exports.default = _default;
});