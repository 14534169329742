define("slate-payroll-client/templates/components/paystub-document-breakdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2cO/IGl3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\"],[11,\"class\",\"section-small-heading text-center\"],[9],[0,\"For This Pay Period\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"ul\"],[11,\"class\",\"transaction-list\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"paystub\",\"totalsHours\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row label-row no-gutter\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-8 col-xs-offset-1\"],[9],[0,\"Hours\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-xs-3 text-right\"],[9],[1,[23,[\"paystub\",\"totalsHours\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"row no-gutter\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-1\"],[9],[0,\"+\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"Gross Pay\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-3 text-right\"],[9],[1,[27,\"x-currency\",[[23,[\"paystub\",\"totalsGross\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"li\"],[11,\"class\",\"row row-bottom-line no-gutter\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-1\"],[9],[0,\"-\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-8\"],[9],[0,\"Deductions\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-3 text-right\"],[9],[1,[27,\"x-currency\",[[23,[\"paystub\",\"totalsDeductions\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"li\"],[11,\"class\",\"row text-strong no-gutter\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-8 col-xs-offset-1\"],[9],[0,\"Net Pay\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-xs-3 text-right\"],[9],[1,[27,\"x-currency\",[[23,[\"paystub\",\"totalsNet\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/paystub-document-breakdown.hbs"
    }
  });

  _exports.default = _default;
});