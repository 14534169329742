define("slate-payroll-client/serializers/bank-account", ["exports", "slate-payroll-client/serializers/application", "slate-payroll-client/utils/serialize-object", "slate-payroll-client/utils/expand-serialized-object"], function (_exports, _application, _serializeObject, _expandSerializedObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,
    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, true, 'ach');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json._id = json.id;
      delete json.id;
      (0, _expandSerializedObject.default)(json, 'ach');
      return json;
    }
  });

  _exports.default = _default;
});