define("slate-payroll-client/components/time-off/view-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getWeekNums(momentObj) {
    var clonedMoment = (0, _moment.default)(momentObj),
        first = clonedMoment.startOf('month').week(),
        last = clonedMoment.endOf('month').week(); // In case last week is in next year

    if (first > last) {
      last = clonedMoment.endOf('month').subtract(1, 'week').week() + 1;
    }

    return last - first + 1;
  }

  var _default = Ember.Component.extend({
    classNames: ['cal__component'],
    classNameBindings: ['isInteracting:cal__component-interactive'],
    month: undefined,
    year: undefined,
    requests: Ember.A(),
    isInteracting: Ember.computed.bool('interactionType'),
    interactionPartialPath: Ember.computed('interactionType', function () {
      var type = this.get('interactionType');
      return type ? 'time-off/view-calendar/' + type + '-interaction' : undefined;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setMonthYear();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.setMonthYear();
    },
    setMonthYear: function setMonthYear() {
      if (this.get('month') || this.get('year') && !isNaN(this.get('year'))) {
        return;
      }

      var now = (0, _moment.default)();
      this.setProperties({
        month: now.format('MMMM'),
        year: now.format('YYYY')
      });
    },
    dayLabels: Ember.computed(function () {
      var labels = [];

      for (var i = 0; i < 7; i++) {
        labels.push((0, _moment.default)().day(i).format('dddd'));
      }

      return labels; // [ 'Sunday', 'Monday' ... 'Saturday' ]
    }),
    _momentComputed: Ember.computed('month', 'year', function () {
      var month = this.get('month'),
          year = parseFloat(this.get('year'));
      return month && year ? (0, _moment.default)().year(year).month(month) : undefined;
    }),
    _weeks: Ember.computed('_momentComputed', 'requests.@each.date', function () {
      var mc = this.get('_momentComputed');

      if (!mc) {
        return undefined;
      }

      var startFrom = (0, _moment.default)(mc).startOf('month'),
          weeks = Ember.A(),
          requests = this.get('requests'),
          weekNum = getWeekNums(startFrom);

      for (var i = 0; i < weekNum; i++) {
        var m = (0, _moment.default)(startFrom).add(i, 'week');
        weeks.pushObject({
          range: {
            start: (0, _moment.default)(m).startOf('week').toDate(),
            end: (0, _moment.default)(m).endOf('week').toDate()
          }
        });
      }

      var requestsForDay = function requestsForDay(day) {
        return requests.filter(function (request) {
          var rejected = !request.get('approved') && request.get('reviewedOn');
          return rejected ? false : request.get('days').find(function (d) {
            return (0, _moment.default)(d.date).isSame(day.date, 'day');
          });
        });
      };

      var setDaysForWeek = function setDaysForWeek(week) {
        week.days = [];

        for (var d = 0; d < 7; d++) {
          var day = {
            date: (0, _moment.default)(week.range.start).add(d, 'days').toDate()
          };
          day.outOfMonth = !(0, _moment.default)(day.date).isSame(startFrom, 'month');
          day.requests = requestsForDay(day);
          week.days.push(day);
        }

        return week;
      };

      return weeks.map(setDaysForWeek);
    }),
    nextMonth: Ember.computed('_momentComputed', function () {
      var m = this.get('_momentComputed');
      return m ? (0, _moment.default)(m).add(1, 'month').format('MMMM') : undefined;
    }),
    lastMonth: Ember.computed('_momentComputed', function () {
      var m = this.get('_momentComputed');
      return m ? (0, _moment.default)(m).subtract(1, 'month').format('MMMM') : undefined;
    }),
    interact: function interact(interactionType, interactionData) {
      this.setProperties({
        interactionType: interactionType,
        interactionData: interactionData
      });
    },
    actions: {
      changeMonth: function changeMonth(month) {
        var m = this.get('month'),
            year = parseFloat(this.get('year'));

        if (month === 'January' && m === 'December') {
          year += 1;
        } else if (month === 'December' && m === 'January') {
          year -= 1;
        }

        this.setProperties({
          month: month,
          year: year
        });
      },
      focusRequest: function focusRequest(request) {
        var _this = this;

        // Wait for any unfocus events to propagate
        Ember.run.next(function () {
          _this.set('focusedRequest', request);
        });
      },
      unfocusRequest: function unfocusRequest() {
        this.set('focusedRequest', null);
      },
      clearInteraction: function clearInteraction() {
        this.setProperties({
          interactionType: null,
          interactionData: null
        });
      }
    }
  });

  _exports.default = _default;
});