define("slate-payroll-client/models/company-paycode", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    type: attr('string'),
    format: attr('string'),
    unit: attr('string'),
    unitActual: attr('string'),
    sort: attr('number'),
    static: attr('boolean'),
    description: attr('string'),
    crossReference: attr('string'),
    countsTowardsSalary: attr('boolean'),
    countsTowardsOvertime: attr('boolean'),
    isDefault: attr('boolean'),
    employees: attr('boolean'),
    supervisors: attr('boolean'),
    managers: attr('boolean'),
    basic: attr('boolean'),
    timeOffCapability: attr('boolean'),
    codeBasedPunchCapability: attr('boolean'),
    // Relational
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    paycode: _emberData.default.belongsTo('paycode', {
      inverse: false,
      async: false
    }),
    // Legacy
    legacyCompanyPaycodeId: attr('number'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});