define("slate-payroll-client/router", ["exports", "slate-payroll-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  var _default = Router.map(function () {
    this.route('index', {
      path: '/:companyid'
    });
    this.route('squares-login', {
      path: '/:companyid/squares-login'
    });
    this.route('punch', function () {
      this.route('simple');
      this.route('timesheet');
      this.route('time-off', function () {
        this.route('new-request');
        this.route('edit', {
          path: '/:id/edit'
        });
      });
    });
    this.route('punches', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/:pay_period_id/view'
      }, function () {
        this.route('edit', {
          path: '/:punchid/edit'
        });
      });
    });
    this.route('esa', {
      path: '/extended-security-authentication'
    }, function () {
      this.route('index', {
        path: '/authenticate'
      });
      this.route('setup');
      this.route('reset');
      this.route('clear', {
        path: '/clear/:employee_id/:clear_token'
      });
    });
    this.route('2fa', {
      path: '/two-factor-authentication'
    }, function () {
      this.route('verify');
      this.route('setup');
    });
    this.route('supervisor', function () {
      this.route('time-off', function () {
        this.route('approve', {
          path: '/:requestid/approve'
        });
        this.route('historical');
        this.route('balances');
      });
      this.route('timesheets', function () {});
      this.route('punches', function () {
        this.route('edit', {
          path: '/:id/edit'
        });
        this.route('add');
        this.route('add-many');
      });
      this.route('approvals', function () {
        this.route('review', {
          path: '/:payperiod_id'
        }, function () {
          this.route('employee', {
            path: '/punches/:employee_id'
          });
        });
      });
      this.route('settings');
    });
    this.route('paystubs', function () {
      this.route('index', {
        path: '/'
      });
      this.route('view', {
        path: '/:paystub_id/view'
      });
    });
    this.route('reminders');
    this.route('unauthorized');
    this.route('error');
    this.route('not-found');
    this.route('xsu');
    this.route('no-url-key', {
      path: '/'
    });
    this.route('catchall', {
      path: '/*wildcard'
    });
    this.route('documents', function () {
      this.route('view', {
        path: '/:id/view'
      });
    });
    this.route('beyonce');
    this.route('sso', {
      path: '/sso/:token'
    });
    this.route('tax-forms');
  });

  _exports.default = _default;
});