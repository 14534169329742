define("slate-payroll-client/models/bank-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    number: attr('number'),
    routing: attr('number'),
    type: attr('string'),
    achAmount: attr('number'),
    achType: attr('string'),
    // $ or %
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});