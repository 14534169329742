define("slate-payroll-client/instance-initializers/rsvp-error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var filterErrors = ['TransitionAborted', 'TransitionAborted: TransitionAborted'];

  function initialize(appInstance) {
    var logging = appInstance.lookup('service:logging');
    Ember.RSVP.on('error', function (err) {
      if (filterErrors.includes((err || {}).name)) {
        return;
      }

      logging.send(err);
    });
  }

  var _default = {
    name: 'rsvp-error-handler',
    initialize: initialize
  };
  _exports.default = _default;
});