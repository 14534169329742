define("slate-payroll-client/templates/components/time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uf35pTrR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[27,\"input\",null,[[\"type\",\"class\",\"id\",\"value\"],[\"text\",\"time-picker-input form-control\",[23,[\"inputId\"]],[23,[\"textValue\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/time-picker.hbs"
    }
  });

  _exports.default = _default;
});