define("slate-payroll-client/components/x-bound-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'option',
    attributeBindings: ['value', 'selected'],
    selected: Ember.computed('selection', 'item', function () {
      var selection = this.get('selection'),
          item = this.get('item');

      if (this.get('compareWithValue') || this.get('comparePath')) {
        var comparePath = this.get('comparePath'),
            sel = comparePath ? Ember.get(selection, comparePath) : selection,
            val = comparePath ? Ember.get(this.get('value'), comparePath) : this.get('value');
        return val === sel;
      }

      return selection === item;
    }),
    value: Ember.computed('optionValuePath', 'item', function () {
      var item = this.get('item'),
          valPath = this.get('optionValuePath');
      return valPath ? Ember.get(item, valPath) : item;
    })
  });

  _exports.default = _default;
});