define("slate-payroll-client/controllers/supervisor/punches/edit", ["exports", "moment", "slate-payroll-client/utils/is-number", "slate-payroll-client/mixins/ux-ajax-status-hooks", "slate-payroll-client/config/app-data"], function (_exports, _moment, _isNumber, _uxAjaxStatusHooks, _appData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    auth: Ember.inject.service(),
    parentController: Ember.inject.controller('supervisor/punches'),
    showModal: true,
    timeInterval: Ember.computed.reads('auth.user.company.timeMinuteInterval'),
    modalId: 'edit-punch-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-pencil-square"></i> Editing Punch</h2>',
    lunchIsOther: Ember.computed.equal('punchLunch', 'other'),
    autosetPunchAttributes: Ember.observer('model', 'model.timeIn', 'model.timeOut', function () {
      var model = this.get('model');

      if (!model || this.get('didAutoset')) {
        return;
      }

      var data = model.getProperties('timeIn', 'timeOut', 'lunch'),
          timeOutMoment = (0, _moment.default)(data.timeOut);
      var isOtherLunch = data.lunch !== 0 && data.lunch !== 1 && data.lunch !== 0.5 && data.lunch !== undefined && data.lunch !== null,
          isUnit = (0, _isNumber.default)(model.get('addEarnings')) || (0, _isNumber.default)(model.get('deduction')) || (0, _isNumber.default)(model.get('days')) || model.get('companyPaycode.unitActual') !== 'Hours' || !timeOutMoment.isValid() && (0, _isNumber.default)(model.get('hours')) || model.get('directHourEntry');
      this.setProperties({
        didAutoset: true,
        punchLunch: isOtherLunch ? 'other' : !data.lunch ? null : data.lunch * 60,
        otherPunchLunch: isOtherLunch ? data.lunch * 60 : undefined,
        unitEntry: isUnit,
        units: null
      });

      if (isUnit) {
        var paycode = model.get('companyPaycode'),
            mapping = _appData.paycodeUnitMap[paycode.get('unitActual')];

        if (!mapping) {
          return;
        }

        if (_typeof(mapping) === 'object') {
          mapping = mapping[paycode.get('type')];
        }

        this.set('units', model.get(mapping));
      }
    }),
    isMultiDay: Ember.computed('model.{timeIn,timeOut}', function () {
      var _this$model = this.model,
          timeIn = _this$model.timeIn,
          timeOut = _this$model.timeOut;
      return timeIn && timeOut && !(0, _moment.default)(timeOut).isSame(timeIn, 'day');
    }),
    updateLunch: Ember.observer('punchLunch', 'otherPunchLunch', function () {
      var data = this.getProperties('punchLunch', 'otherPunchLunch');
      var lunch = data.punchLunch !== 'other' ? data.punchLunch / 60 : data.otherPunchLunch / 60 || 0;
      this.set('model.lunch', lunch.toFixed(2) / 1);
    }),
    lockEntry: Ember.computed('model.companyPaycode.unit', function () {
      return this.get('model.companyPaycode.unit') === 'D' ? true : false;
    }),
    enableDateIn: Ember.computed('model.payPeriod.{start,end}', function () {
      var payPeriod = this.get('model.payPeriod');
      return payPeriod ? [{
        from: payPeriod.get('start'),
        to: payPeriod.get('end')
      }] : [];
    }),
    disableDateOut: Ember.computed(function () {
      var _this = this;

      return [function (date) {
        var timeIn = _this.get('model.timeIn'),
            payPeriodEnd = (0, _moment.default)(_this.get('model.payPeriod.end')).add(1, 'day').startOf('day');

        return (0, _moment.default)(date).endOf('day').isBefore(timeIn) && (0, _moment.default)(date).isBefore(payPeriodEnd);
      }];
    }),
    actions: {
      discardChanges: function discardChanges() {
        this.transitionToRoute('supervisor.punches');
        this.get('model').rollbackAttributes();
      },
      savePunch: function savePunch() {
        var _this2 = this;

        this.ajaxStart();
        var punch = this.get('model');

        if (!punch.get('employee.id')) {
          this.ajaxError('No employee selected', true);
          return;
        }

        if (!punch.get('companyPaycode.id')) {
          this.ajaxError('No paycode selected', true);
          return;
        }

        punch.set('hours', null);

        if (this.get('unitEntry')) {
          var paycode = punch.get('companyPaycode'),
              mapping = _appData.paycodeUnitMap[paycode.get('unitActual')],
              units = this.get('units');

          if (!mapping) {
            this.ajaxError('Unable to find paycode mapping', true);
            return;
          }

          if (_typeof(mapping) === 'object') {
            mapping = mapping[paycode.get('type')];
          }

          punch.setProperties({
            hours: null,
            days: null,
            addEarnings: null,
            deduction: null
          });
          punch.set(mapping, units);
          var time = (0, _moment.default)(punch.get('timeIn')).hour(12).minute(0).second(0).toDate();
          punch.setProperties({
            timeIn: time,
            timeOut: time
          });
        }

        punch.setProperties({
          directHourEntry: this.get('unitEntry'),
          status: "Edited by Supervisor [".concat(this.get('auth.user.firstName'), "]"),
          timesheet: true
        });
        punch.save().then(function () {
          _this2.ajaxSuccess();

          _this2.transitionToRoute('supervisor.punches');
        }).catch(this.ajaxError.bind(this));
      },
      lockUnitEntry: function lockUnitEntry() {
        if (this.get('model.companyPaycode.unit') === 'D') {
          this.set('unitEntry', true);
          this.get('model').setProperties({
            hours: '0.00',
            lunch: null
          });
        }
      }
    }
  });

  _exports.default = _default;
});