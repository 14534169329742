define("slate-payroll-client/templates/supervisor/approvals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pd7b0Afe",
    "block": "{\"symbols\":[\"payPeriodGroup\"],\"statements\":[[4,\"if\",[[23,[\"employeesWithDirectReports\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"form-group hidden-for-print\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"employee-selection\"],[9],[0,\"\\n      Display approvals for\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"bound-select\",null,[[\"selectClass\",\"selectId\",\"optionLabelPath\",\"optionValuePath\",\"compareSelectionWithValue\",\"content\",\"selection\",\"action\",\"prompt\"],[\"form-control\",\"employee-selection\",\"fullName\",\"id\",true,[23,[\"employeesWithDirectReports\"]],[23,[\"approvalEmployee\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"approvalEmployee\"]]],null]],null],\"Myself\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ul\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"pas/pay-period-group\",[[22,1,[]]],[[\"notOwn\",\"forEmployee\",\"onQuickApproval\"],[[23,[\"reviewingNotOwn\"]],[23,[\"reviewingEmployee\"]],[27,\"route-action\",[\"refresh\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"text-center\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\" No Pay Periods Available.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/approvals/index.hbs"
    }
  });

  _exports.default = _default;
});