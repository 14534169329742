define("slate-payroll-client/components/form-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group'],
    icon: 'fa-check',
    buttonText: 'Save',
    buttonClass: 'btn btn-success btn-block btn-lg',
    _disabled: Ember.computed.or('loading', 'disabled')
  });
  /*
    Examples:
    Inline
      {{form-footer error=errorMessage success=successMessage loading=working}}
    Block
      {{form-footer error=errorMessage success=successMessage loading=working}}
        <button type="button">My Cancel Button</button>
      {{/form-footer}}
  */


  _exports.default = _default;
});