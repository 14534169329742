define("slate-payroll-client/templates/catchall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hTIL1nbW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"text-center text-danger\"],[9],[0,\"Uh oh. We can't find that page.\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"p\"],[11,\"class\",\"text-danger text-center\"],[9],[0,\"\\n  The URL you navigated to is missing. This can either be because you typed the URL wrong or we've moved that page.  Sorry about that.\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"text-center text-sm\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/catchall.hbs"
    }
  });

  _exports.default = _default;
});