define("slate-payroll-client/controllers/supervisor/approvals/review/index", ["exports", "slate-payroll-client/mixins/ux-ajax-status-hooks"], function (_exports, _uxAjaxStatusHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    continuingReview: Ember.computed('model.parentModel.approval', function () {
      var approval = this.get('model.parentModel.approval');

      if (!approval || !approval.get('approved.length') || !approval.get('approved').filter(function (app) {
        return app.at;
      }).length) {
        return false;
      }

      return true;
    }),
    actions: {
      initializeReview: function initializeReview() {
        var _this = this;

        if (this.get('working')) {
          return;
        }

        var directReports = this.get('model.directReports'),
            approval = this.get('model.parentModel.approval'),
            transTo = 'supervisor.approvals.review.employee';

        if (this.get('continuingReview')) {
          var allApproved = approval.get('approved').filter(function (app) {
            return !!Ember.get(app, 'at');
          }),
              lastApproval = allApproved[allApproved.length - 1];
          return this.transitionToRoute(transTo, lastApproval && lastApproval.employee || approval.get('approved')[0].employee);
        }

        this.ajaxStart();
        approval.set('approved', directReports.map(function (employee) {
          return {
            employee: employee.get('id'),
            at: null
          };
        }));
        approval.save().then(function (app) {
          _this.transitionToRoute(transTo, app.get('approved.firstObject.employee'));

          _this.ajaxSuccess();
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  _exports.default = _default;
});