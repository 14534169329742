define("slate-payroll-client/routes/application", ["exports", "slate-payroll-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var errorRouteMap = {
    401: 'unauthorized',
    404: 'not-found',
    500: 'error',
    400: 'error'
  };

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    logging: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get('auth').checkExistingSessions();
    },
    model: function model(params) {
      if (params.u) {
        return localforage.setItem('slateClientStickyCompanyId', params.u);
      }
    },
    actions: {
      error: function error(_error) {
        var route = 'error',
            err = _error.errors ? _error.errors[0] : _error;

        if (err && err.status) {
          var routeInMap = errorRouteMap[err.status];

          if (routeInMap) {
            route = routeInMap;
          }
        }

        this.logging.send(_error);
        this.logging.debug('Routing to', route, 'to handle UX error...');
        this.controllerFor(route).set('fromError', err);
        this.transitionTo(route);
      },
      logout: function logout() {
        var _this = this;

        return this.get('auth').destroySession().then(function () {
          return localforage.getItem('slateClientStickyCompanyId');
        }).then(function (stickyId) {
          return stickyId ? _this.transitionTo('index', stickyId) : _this.transitionTo('index');
        }).then(function () {
          if (_environment.default.environment !== 'test') {
            window.location.reload();
          }
        });
      }
    }
  });

  _exports.default = _default;
});