define("slate-payroll-client/routes/documents/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('company-document', params.id);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controller;

        if (!controller.get('showModal')) {
          return;
        }

        transition.abort();
        this.controller.set('showModal', false);
        Ember.$('#' + controller.get('modalId')).one('hidden.bs.modal', Ember.run.bind(this, function () {
          transition.retry();
          this.send('refreshModel');
        }));
      },
      didTransition: function didTransition() {
        // We must turn the modal on every time we enter the route
        this.controller.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});