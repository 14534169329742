define("slate-payroll-client/templates/components/document-grid-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r5N2no80",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"documents.view\",[23,[\"companyDocument\",\"id\"]]],[[\"class\"],[\"thumbnail view-overlay\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-thumbnail view-popover\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-\",[27,\"if\",[[23,[\"companyDocument\",\"link\"]],\"paperclip\",\"file-text\"],null]]]],[9],[10],[0,\" \"],[1,[23,[\"companyDocument\",\"title\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-center text-sm\"],[9],[0,\"\\n      \"],[1,[27,\"time-ago\",[[23,[\"companyDocument\",\"created\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"companyDocument\",\"forEmployee\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"label label-default\"],[9],[0,\"\\n          Personal\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/document-grid-item.hbs"
    }
  });

  _exports.default = _default;
});