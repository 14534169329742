define("slate-payroll-client/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7re2n3ro",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"employee-login container\"],[11,\"autocomplete\",\"off\"],[3,\"action\",[[22,0,[]],\"login\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    Enter Your Employee #\"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"small\"],[11,\"class\",\"company-name-being-displayed\"],[9],[0,\"\\n      \"],[1,[23,[\"model\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"employee-number\"],[9],[0,\"Employee Number\"],[10],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"class\",\"autocomplete\",\"value\",\"placeholder\",\"autofocus\"],[\"text\",\"employee-number\",\"form-control input-lg employee-number\",\"off\",[23,[\"employeeNumber\"]],\"Employee Number\",\"autofocus\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[11,\"class\",\"text-danger text-center error-text\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\"\\n      Error logging in: \"],[1,[21,\"errorMessage\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-lg btn-block login\"],[12,\"disabled\",[27,\"or\",[[23,[\"working\"]],[27,\"if\",[[23,[\"employeeNumber\"]],false,true],null]],null]],[11,\"type\",\"submit\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"working\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-spinner fa-spin\"],[9],[10],[0,\" Logging in...\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Login\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/index.hbs"
    }
  });

  _exports.default = _default;
});