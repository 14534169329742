define("slate-payroll-client/components/table-row-punch-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['punch-row-details'],
    init: function init() {
      this._super.apply(this, arguments);

      this.popoverContentChanged();
    },
    isDollars: Ember.computed.equal('punch.companyPaycode.unitActual', 'Dollars'),
    popoverContentChanged: Ember.observer('punch.{triggerPunch,overtimePunch}', function () {
      var punch = this.get('punch');

      if (punch.triggerPunch || punch.overtimePunch) {
        Ember.run.scheduleOnce('afterRender', function () {
          Ember.$('.overtime-popover').popover();
        });
      }
    }),
    editable: Ember.computed('punch.companyPaycode.employees', function () {
      return !!this.get('punch.companyPaycode.employees');
    }),
    associatedDate: Ember.computed('punch.{timeIn,timeOut,created}', function () {
      var punch = this.get('punch'),
          created = punch.get('created');
      return !punch.get('timeIn') && !punch.get('timeOut') && created ? (0, _moment.default)(created).set('hour', 12).startOf('hour').toDate() : false;
    }),
    computedType: Ember.computed('punch.companyPaycode.unitActual', function () {
      var types = Ember.A(['hours', 'days', 'addEarnings', 'deduction']),
          punch = this.get('punch');
      var type = punch.get('incomplete') ? undefined : types.find(function (t) {
        var valForType = punch.get(t);
        return valForType && !isNaN(valForType);
      });
      return type ? {
        long: Ember.String.capitalize(Ember.String.decamelize(type).replace(/_/g, ' ')),
        property: type
      } : type;
    }),
    computedAmount: Ember.computed('computedType', 'punch.{hours,deduction,addEarnings,days}', function () {
      var type = this.get('computedType');
      return type ? this.get('punch').get(type.property) : undefined;
    })
  });

  _exports.default = _default;
});