define("slate-payroll-client/components/paystub-document-ach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.transactionSorting = ['order'];
      this.achTransactionsOrdered = Ember.computed.sort('achTransactions', 'transactionSorting');
    }
  });

  _exports.default = _default;
});