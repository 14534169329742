define("slate-payroll-client/templates/squares-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XraeHuFt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-cubes\"],[9],[10],[0,\" Stamp your square in the green area to log in\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"model\",\"requireSquares\"]]],null,{\"statements\":[[0,\"      \"],[7,\"small\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"index\",[23,[\"model\",\"urlKey\"]]],null,{\"statements\":[[0,\"Login with employee #\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-center text-danger\"],[9],[0,\"\\n    There was an error logging in with that square.\"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"text-sm\"],[9],[1,[21,\"loginError\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"x-snowshoe-screen\",null,[[\"success\",\"error\",\"postUrl\"],[[27,\"action\",[[22,0,[]],\"squareSuccess\"],null],[27,\"action\",[[22,0,[]],\"squareError\"],null],\"/client-api/employee/squares-login\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/squares-login.hbs"
    }
  });

  _exports.default = _default;
});