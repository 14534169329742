define("slate-payroll-client/templates/components/list/time-off-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CKrw+tzQ",
    "block": "{\"symbols\":[\"@balance\"],\"statements\":[[7,\"li\"],[9],[0,\"\\n  \"],[1,[27,\"round-to\",[[22,1,[\"balance\"]],3],null],false],[0,\"\\n\"],[4,\"if\",[[22,0,[\"approvedFutureHours\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-muted\"],[9],[0,\"- \"],[1,[27,\"round-to\",[[22,0,[\"approvedFutureHours\"]],3],null],false],[0,\" = \"],[1,[27,\"round-to\",[[22,0,[\"availableBalance\"]],3],null],false],[10],[0,\"\\n    \"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n      You have future time off requests that will use \"],[7,\"strong\"],[9],[1,[27,\"round-to\",[[22,0,[\"approvedFutureHours\"]],3],null],false],[10],[0,\" of your balance\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"label\"],[9],[1,[22,1,[\"description\"]],false],[10],[0,\"\\n  \"],[7,\"h4\"],[11,\"class\",\"text-muted text-sm text-center time-off-balances__time-description\"],[9],[0,\"\\n    As of \"],[4,\"if\",[[22,1,[\"asOf\"]]],null,{\"statements\":[[1,[27,\"format-moment\",[[22,1,[\"asOf\"]],\"M/D/YY\"],null],false]],\"parameters\":[]},{\"statements\":[[0,\"N/A\"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/list/time-off-balance.hbs"
    }
  });

  _exports.default = _default;
});