define("slate-payroll-client/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T4nBnnRu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"text-center text-danger not-found\"],[9],[0,\"404 Not Found\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"text-danger text-center\"],[9],[0,\"\\n  We could not find that page.\"],[7,\"br\"],[9],[10],[0,\"\\n  It's possible that you entered the wrong employer url or that your employer's url has changed.\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"fromError\",\"responseText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"small\"],[9],[0,\"\\n      \"],[7,\"code\"],[9],[1,[23,[\"fromError\",\"responseText\"]],false],[10],[0,\" That's all we know.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-center text-sm\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"index\",[23,[\"model\"]]],null,{\"statements\":[[0,\"Back to index\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});