define("slate-payroll-client/config/beyonce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.references = _exports.modelData = void 0;
  var modelData = {
    'time-off-request': [{
      approved: true,
      supervisorNotes: 'Good work this week',
      employeeNotes: 'Thanks Dad',
      balanceDescription: 'PTO',
      days: [{
        half: true,
        hours: 4,
        date: new Date(2016, 3, 10)
      }, {
        hours: 8,
        date: new Date(2016, 3, 11)
      }, {
        hours: 8,
        date: new Date(2016, 4, 1)
      }],
      totalDays: 3,
      totalHours: 20,
      reviewedOn: new Date(2016, 3, 5),
      created: new Date(2016, 3, 1)
    }, {
      approved: true,
      supervisorNotes: 'I hope you have more fun than a slinky on an escalator!',
      employeeNotes: 'Fo Sho',
      balanceDescription: 'PTO',
      days: [{
        hours: 10,
        date: new Date(2016, 3, 10)
      }, {
        hours: 8,
        date: new Date(2016, 3, 11)
      }, {
        hours: 8,
        date: new Date(2016, 3, 12)
      }, {
        hours: 8,
        date: new Date(2016, 3, 13)
      }],
      totalDays: 4,
      totalHours: 34,
      reviewedOn: new Date(2016, 3, 9),
      created: new Date(2016, 3, 8)
    }, {
      approved: true,
      supervisorNotes: 'Watch out for those rock lobsters!',
      employeeNotes: 'Fo Sho',
      balanceDescription: 'PTO',
      days: [{
        hours: 10,
        date: new Date(2016, 3, 10)
      }, {
        hours: 8,
        date: new Date(2016, 3, 11)
      }, {
        hours: 8,
        date: new Date(2016, 3, 12)
      }, {
        hours: 8,
        date: new Date(2016, 3, 13)
      }],
      totalDays: 4,
      totalHours: 34,
      reviewedOn: new Date(2016, 3, 9),
      created: new Date(2016, 3, 8)
    }, {
      approved: true,
      supervisorNotes: 'I\'ve been wanting you take a hike!',
      days: [{
        hours: 10,
        date: new Date(2016, 3, 11)
      }, {
        hours: 8,
        date: new Date(2016, 3, 12)
      }, {
        hours: 8,
        date: new Date(2016, 3, 13)
      }, {
        hours: 8,
        date: new Date(2016, 3, 14)
      }],
      totalDays: 4,
      totalHours: 34,
      reviewedOn: new Date(2016, 3, 9),
      created: new Date(2016, 3, 8)
    }, {
      approved: false,
      supervisorNotes: 'I wasn\'t even supposed to be here today. Ok? I\'m tired of your crap, jerry.',
      employeeNotes: 'Whatevs',
      days: [{
        hours: 10,
        date: new Date(2016, 4, 21)
      }],
      totalDays: 1,
      totalHours: 10,
      reviewedOn: new Date(2016, 2, 19),
      created: new Date(2016, 2, 18)
    }, {
      days: [{
        hours: 10,
        date: new Date(2016, 4, 21)
      }, {
        hours: 8,
        date: new Date(2016, 3, 10)
      }],
      totalDays: 1,
      totalHours: 10,
      created: new Date(2016, 2, 18)
    }, {
      approved: undefined,
      days: [{
        hours: 10,
        date: new Date(2016, 3, 20)
      }, {
        hours: 10,
        date: new Date(2016, 3, 21)
      }, {
        hours: 10,
        date: new Date(2016, 3, 22)
      }, {
        hours: 10,
        date: new Date(2016, 3, 25)
      }, {
        hours: 10,
        date: new Date(2016, 3, 26)
      }, {
        hours: 10,
        date: new Date(2016, 3, 27)
      }, {
        hours: 10,
        date: new Date(2016, 3, 28)
      }, {
        hours: 10,
        date: new Date(2016, 3, 29)
      }],
      totalDays: 8,
      totalHours: 80,
      created: new Date(2016, 3, 1)
    }],
    'company-paycode': [{
      type: 'E',
      format: 'H',
      unit: 'H',
      unitActual: 'Hours',
      static: false,
      description: 'PTO',
      employees: true,
      basic: true
    }],
    'employee': [{
      employeeNumber: '123',
      email: 'test@aehr.org',
      exempt: true,
      jobTitle: 'Web Developer',
      firstName: 'Bender',
      middleInitial: 'B',
      lastName: 'Bot',
      balances: [{
        description: 'PTO',
        balance: 50,
        asOf: new Date(2016, 2, 20)
      }]
    }]
  };
  _exports.modelData = modelData;
  var references = [{
    for: 'time-off-request',
    forKey: 'companyPaycode',
    toModel: 'company-paycode',
    atIndex: 0
  }, {
    for: 'time-off-request',
    forKey: 'employee',
    toModel: 'employee',
    atIndex: 0
  }];
  _exports.references = references;
});