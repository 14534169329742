define("slate-payroll-client/controllers/punch/simple", ["exports", "moment", "slate-payroll-client/mixins/ux-ajax-status-hooks"], function (_exports, _moment, _uxAjaxStatusHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    punch: Ember.inject.controller(),
    squaresLogin: Ember.inject.controller(),
    geo: Ember.inject.service(),
    mixpanel: Ember.inject.service(),
    auth: Ember.inject.service(),
    codeBasedPunchCode: {},
    successMessageTimeout: 10,
    activePunch: Ember.computed.alias('punch.activePunch'),
    activePayPeriod: Ember.computed.reads('punch.activePayPeriod'),
    isClockedIn: Ember.computed.reads('punch.isClockedIn'),
    squaresAutoPunch: Ember.computed.alias('squaresLogin.pendingAutoPunch'),
    autoLogout: Ember.computed.alias('auth.user.company.basicAutoLogout'),
    isOverMaxHours: Ember.computed('activePunch', 'auth.user.company.maxHourEntry', function () {
      var activePunch = this.get('activePunch'),
          maxHours = this.get('auth.user.company.maxHourEntry');

      if (!activePunch) {
        return false;
      }

      return (0, _moment.default)().diff(activePunch.get('timeIn'), 'hours', true) > maxHours;
    }),
    autoPunchActions: Ember.on('init', Ember.observer('model', 'punch.loadingActivePunch', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (_this.get('punch.loadingActivePunch')) {
          return;
        }

        if (_this.get('squaresAutoPunch') === true) {
          _this.send(_this.get('isClockedIn') ? 'clockOut' : 'clockIn');

          _this.setProperties({
            squaresAutoPunch: false,
            isAutoPunch: true
          });
        }
      });
    })),
    actions: {
      showModal: function showModal() {
        var _this2 = this;

        this.setProperties({
          showModal: true,
          codeBasedPunchCode: {}
        });
        return new Promise(function (resolve, reject) {
          return _this2.setProperties({
            resolve: resolve,
            reject: reject
          });
        });
      },
      respondModal: function respondModal(response) {
        if (!response) {
          this.set('codeBasedPunchCode', {});
        }

        this.get(response ? 'resolve' : 'reject')();
        this.set('showModal', false);
      },
      clockIn: function clockIn() {
        var _this3 = this;

        var employee = this.get('model'),
            company = employee.get('company'),
            codeBasedPunchCode = this.get('codeBasedPunchCode');

        if (employee.get('isClockedIn')) {
          return;
        }

        var signature = this.get('signature');

        if (company.get('requireSignatures') && !signature) {
          this.set('showSignature', true);
          return;
        }

        this.ajaxStart();
        var codeQuery;

        if (employee.codeBasedPunch && company.get('codeBasedPunch')) {
          if (!codeBasedPunchCode.id) {
            return this.ajaxError('No paycode selected');
          }

          codeQuery = {
            _id: codeBasedPunchCode.id
          };
        } else {
          codeQuery = {
            'codeSettings.isDefault': true
          };
        }

        var createPunch = function createPunch(geoData) {
          return _this3.store.query('company-paycode', codeQuery).then(function (paycodes) {
            if (!paycodes || !paycodes.get('firstObject')) {
              return _this3.ajaxError('No default paycode found.');
            }

            var punchData = {
              timeIn: new Date(),
              status: 'Open',
              employee: employee,
              companyPaycode: paycodes.get('firstObject'),
              signature: signature,
              uaIn: (navigator || {}).userAgent,
              checkServerTime: true,
              clientVersion: "slate-payroll-client@".concat(_this3.get('buildInfo.version'))
            };

            if (geoData && geoData.lat) {
              punchData.timeInGeoLat = geoData.lat;
              punchData.timeInGeoLng = geoData.lng;
            }

            var punch = _this3.store.createRecord('punch', punchData);

            if (company.get('enablePunchComments')) {
              punch.set('punchComment', _this3.get('punchComment'));

              _this3.set('punchComment', null);
            }

            return punch.save();
          }).then(function (savedPunch) {
            if (_this3.get('signature')) {
              _this3.setProperties({
                signature: null,
                showSignature: false
              });
            }

            _this3.set('punch.setActivePunchSilent', true);

            _this3.set('activePunch', savedPunch);

            _this3.set('codeBasedPunchCode', {});

            _this3.ajaxSuccess("Successfully recorded punch in for ".concat((0, _moment.default)(savedPunch.get('timeIn')).format('h:mma')));

            _this3.mixpanel.trackEvent('Clock :: In', {
              signature: !!savedPunch.get('signature'),
              geoData: company.get('useGeolocation'),
              user: employee.get('id'),
              company: company.get('id')
            });

            if (_this3.get('isAutoPunch') || _this3.get('autoLogout')) {
              _this3.send('logout');
            }
          });
        };

        if (!company.get('useGeolocation')) {
          createPunch().catch(this.ajaxError.bind(this));
          return;
        }

        this.get('geo').getLocation(company.get('requireNavLocation')).then(createPunch).catch(function (error) {
          if (company.get('requireNavLocation') && error.code === 1) {
            return _this3.ajaxError('Please enable location services before clocking in');
          }

          return createPunch().catch(_this3.ajaxError.bind(_this3));
        });
      },
      clockOut: function clockOut() {
        var _this4 = this;

        var activePunch = this.get('activePunch'),
            company = this.get('model.company');

        if (!this.get('isClockedIn') || !activePunch) {
          return;
        }

        this.ajaxStart();

        var endPunch = function endPunch(geoData) {
          activePunch.setProperties({
            timeOut: new Date(),
            status: 'Complete',
            uaOut: (navigator || {}).userAgent,
            checkServerTime: true
          });

          if (geoData && geoData.lat) {
            activePunch.setProperties({
              timeOutGeoLat: geoData.lat,
              timeOutGeoLng: geoData.lng
            });
          }

          if (company.get('enablePunchComments') && !activePunch.get('punchComment')) {
            activePunch.set('punchComment', _this4.get('punchComment'));
          }

          return activePunch.save().then(function (punch) {
            _this4.set('activePunch', null);

            var timeInF = (0, _moment.default)(punch.get('timeIn')).format('h:mma'),
                successMessage = "Your punch starting at ".concat(timeInF, " was successfully clocked out.").concat(punch.get('triggeredOvertime') ? ' This punch triggered overtime.' : '');

            _this4.ajaxSuccess(successMessage);

            _this4.mixpanel.trackEvent('Clock :: Out', {
              signature: !!punch.get('signature'),
              geoData: company.get('useGeolocation'),
              user: _this4.get('model.id'),
              company: company.get('id'),
              overtime: punch.get('triggeredOvertime') || punch.get('isOvertime')
            });

            if (_this4.get('isAutoPunch') || _this4.get('autoLogout')) {
              _this4.send('logout');
            }
          });
        };

        if (!company.get('useGeolocation')) {
          endPunch().catch(this.ajaxError.bind(this));
          return;
        }

        this.get('geo').getLocation(company.get('requireNavLocation')).then(endPunch).catch(function (error) {
          if (company.get('requireNavLocation') && error.code === 1) {
            return _this4.ajaxError('Please enable location services before clocking out');
          }

          return endPunch().catch(_this4.ajaxError.bind(_this4));
        });
      },
      clockBreak: function clockBreak() {
        var _this5 = this;

        var activePunch = this.get('activePunch');

        if (!this.get('isClockedIn') || !activePunch) {
          return;
        }

        this.ajaxStart();

        if (activePunch.get('onBreak')) {
          var breaks = activePunch.get('breaks'),
              diff = (0, _moment.default)().diff((0, _moment.default)(activePunch.get('onBreak')), 'minutes') / 60;
          activePunch.setProperties({
            onBreak: null,
            breaks: breaks + diff.toFixed(2)
          });
        } else {
          activePunch.set('onBreak', new Date());
        }

        activePunch.save().then(function (punch) {
          var message = 'Successfully ' + ((punch.get('onBreak') ? 'started' : 'completed') + ' break at ' + (0, _moment.default)().format('h:mma'));

          _this5.ajaxSuccess(message);

          _this5.mixpanel.trackEvent('Clock :: Break', {
            user: _this5.get('model.id'),
            company: _this5.get('model.company.id')
          });

          if (_this5.get('autoLogout')) {
            _this5.send('logout');
          }
        }).catch(this.ajaxError.bind(this));
      },
      submitSignature: function submitSignature(dataUri) {
        this.set('signature', dataUri);
        this.send('clockIn');
      },
      cancel: function cancel() {
        this.toggleProperty('showModal');
      }
    }
  });

  _exports.default = _default;
});