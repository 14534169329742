define("slate-payroll-client/routes/supervisor/timesheets/index", ["exports", "moment", "slate-payroll-client/mixins/refreshable"], function (_exports, _moment, _refreshable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshable.default, {
    queryParams: {
      payPeriodId: {
        refreshModel: true
      },
      employeeId: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var payPeriodId = params.payPeriodId;
      var sortParams = ['sortCommentToEmployee', 'sortPunchComment', 'sortPunchCommentAsc', 'sortTimeIn', 'sortTimeOut', 'sortLunch', 'sortBreak', 'sortHours'],
          sort = {};
      sortParams.forEach(function (paramName) {
        var enableParam = params[paramName],
            paramAsc = params[paramName + 'Asc'],
            newParamName = paramName.replace('sort', '');

        if (enableParam) {
          sort[newParamName.substring(0, 1).toLowerCase() + newParamName.substring(1)] = paramAsc === true ? -1 : 1;
        }
      });

      if (Object.keys(sort).length < 1) {
        sort.timeIn = -1;
      }

      var timeSheetQuery = {
        employee: params.employeeId,
        payPeriod: payPeriodId,
        sort: sort
      };
      var employeeQuery = {
        $or: [{
          terminatedOn: {
            $not: {
              $type: 9
            }
          }
        }, {
          terminatedOn: {
            $exists: false
          }
        }, {
          terminatedOn: {
            $gte: (0, _moment.default)().subtract(1, 'month').toDate()
          }
        }],
        sort: {
          'name.last': 1
        }
      };

      if (this.paramsFor('supervisor').allSupervised) {
        employeeQuery.includeRecursiveSupervised = true;
      }

      var payPeriodQuery = {
        start: {
          $gte: (0, _moment.default)().subtract(2, 'months').toDate()
        },
        end: {
          $lte: (0, _moment.default)().add(90, 'days').toDate()
        },
        sort: {
          paidOn: -1
        }
      };
      return Ember.RSVP.hash({
        employees: this.store.query('employee', employeeQuery),
        selectedEmployee: params.employeeId ? this.store.find('employee', params.employeeId) : null,
        payPeriods: this.store.query('pay-period', payPeriodQuery),
        payPeriod: params.payPeriodId ? this.store.find('pay-period', payPeriodId) : null,
        punches: params.employeeId && params.payPeriodId ? this.store.query('punch', timeSheetQuery) : null,
        paycodes: this.store.query('company-paycode', {
          $or: [{
            unit: 'D'
          }, {
            unit: 'H'
          }]
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.employees,
        payPeriods: model.payPeriods,
        punches: model.punches,
        payPeriod: model.payPeriod,
        employee: model.selectedEmployee,
        paycodes: model.paycodes
      });
    }
  });

  _exports.default = _default;
});