define("slate-payroll-client/templates/components/table-row-punch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XRebtLv6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\"],[12,\"colspan\",[21,\"columns\"]],[12,\"class\",[28,[\"day-block-container \",[27,\"if\",[[23,[\"notClockedOut\"]],\"danger-state\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"_blockWidths\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"day-block left\"],[12,\"style\",[28,[\"width: \",[23,[\"_blockWidths\",\"left\"]],\"%\"]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"day-block active\"],[12,\"style\",[28,[\"width: \",[23,[\"_blockWidths\",\"active\"]],\"%\"]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"day-block right\"],[12,\"style\",[28,[\"width: \",[23,[\"_blockWidths\",\"right\"]],\"%\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/table-row-punch.hbs"
    }
  });

  _exports.default = _default;
});