define("slate-payroll-client/components/x-sve-punch-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sve-punch-indicator'],
    classNameBindings: ['isClockedIn:in:out'],
    isClockedIn: Ember.computed('lastPunch.timeOut', function () {
      var punch = this.get('lastPunch');
      return punch && !punch.get('timeOut');
    })
  });

  _exports.default = _default;
});