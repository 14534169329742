define("slate-payroll-client/components/sg/sg-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sg__section'],
    classNameBindings: ['_hidden:hidden'],
    _hidden: Ember.computed('focus', 'name', function () {
      return this.get('focus') && this.get('focus') !== this.get('name');
    })
  });

  _exports.default = _default;
});