define("slate-payroll-client/templates/reminders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nZq6iTEr",
    "block": "{\"symbols\":[\"reminder\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-text-o\"],[9],[10],[0,\" Reminders\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n    \"],[1,[27,\"bound-select\",null,[[\"tagName\",\"selectClass\",\"content\",\"selection\",\"compareSelectionWithValue\",\"optionLabelPath\",\"optionValuePath\",\"action\"],[\"span\",\"inconspicuous-select\",[23,[\"sortOptions\"]],[23,[\"sortBy\"]],true,\"label\",\"value\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"sortBy\"]]],null]],null]]]],false],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"inline-control\"],[3,\"action\",[[22,0,[]],\"toggleProperty\",\"sortAsc\"]],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-caret-\",[27,\"if\",[[23,[\"sortAsc\"]],\"up\",\"down\"],null]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"reminder-list-item\",null,[[\"reminder\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"h3\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\" No Reminders Available.\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n    \"],[1,[27,\"x-pagination\",null,[[\"page\",\"pages\",\"maxButtons\"],[[23,[\"page\"]],[23,[\"pages\"]],8]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/reminders.hbs"
    }
  });

  _exports.default = _default;
});