define("slate-payroll-client/components/list/time-off-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    approvedFutureHours: Ember.computed('requests.[]', 'balance', function () {
      var balance = this.balance,
          pp = this.payPeriod || {},
          timePeriodStart = moment(balance.asOf).isBefore(pp.start) ? balance.asOf : pp.start;
      return (this.requests || []).reduce(function (total, request) {
        if (!request.reviewedOn || !request.approved || balance.companyPaycode !== request.belongsTo('companyPaycode').id() || !moment(request.days[0].date).isAfter(timePeriodStart)) {
          return total;
        }

        return total + (request.totalHours || 0);
      }, 0);
    }),
    availableBalance: Ember.computed('approvedFutureHours', 'balance', function () {
      return (this.balance.balance || 0) - (this.get('approvedFutureHours') || 0);
    })
  });

  _exports.default = _default;
});