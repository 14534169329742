define("slate-payroll-client/components/time-off/condensed-request-item", ["exports", "slate-payroll-client/mixins/render-tooltips", "moment"], function (_exports, _renderTooltips, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_renderTooltips.default, {
    tagName: 'li',
    classNames: ['tor-condensed__component'],
    requestedPaycode: Ember.computed('request.{balanceDescription,companyPaycode.description}', function () {
      var request = this.get('request'),
          explicitDesc = request.get('balanceDescription');
      return explicitDesc ? explicitDesc : request.get('companyPaycode.description') ? request.get('companyPaycode.description') : '';
    }),
    daysTooltipContent: Ember.computed('request.days.@each.{date,end}', function () {
      return 'Days in this request:\n' + (this.get('request.days') ? this.get('request.days').reduce(function (accumulator, day) {
        return accumulator.concat((0, _moment.default)(day.date).format('dddd MM/DD/YYYY') + (day.end ? " ".concat((0, _moment.default)(day.date).format('h:mma'), " - ").concat((0, _moment.default)(day.end).format('h:mma')) : ''));
      }, []).join('\n') : 'None');
    }),
    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      approve: function approve() {
        this.get('approve').apply(void 0, arguments);
      },
      reject: function reject() {
        this.get('reject').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});