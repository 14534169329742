define("slate-payroll-client/components/table-row-punch", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['punch-row-block'],
    _dayRepWidth: Ember.computed('payPeriod.days', function () {
      return 100 / this.get('payPeriod.days');
    }),

    /*
    _dayRepWidth: function () {
      return 100 / this.get('payPeriod.days');
    }.property('payPeriod.days'),
    */
    _blockWidths: Ember.computed('payPeriod.{start,end}', 'punch.{timeIn,timeOut}', function () {
      var dayBlockWidth = this.get('_dayRepWidth'),
          start = (0, _moment.default)(this.get('payPeriod.start')),
          end = (0, _moment.default)(this.get('payPeriod.end')),
          timeIn = (0, _moment.default)(this.get('punch.timeIn')),
          timeOut = (0, _moment.default)(this.get('punch.timeOut'));

      if (!timeOut.isValid()) {
        return undefined;
      }

      return {
        left: timeIn.diff(start, 'days') * dayBlockWidth,
        active: (timeOut.diff(timeIn, 'days') + 1) * dayBlockWidth,
        right: end.diff(timeOut, 'days') - 1 * dayBlockWidth
      };
    }),

    /*
    _blockWidths: function () {
      const dayBlockWidth = this.get('_dayRepWidth'),
            start = moment(this.get('payPeriod.start')),
            end = moment(this.get('payPeriod.end')),
            timeIn = moment(this.get('punch.timeIn')),
            timeOut = moment(this.get('punch.timeOut'));
       if ( !timeOut.isValid() ) {
        return undefined;
      }
       return {
        left:   timeIn.diff(start, 'days') * dayBlockWidth,
        active: (timeOut.diff(timeIn, 'days') + 1) * dayBlockWidth,
        right:  end.diff(timeOut, 'days') - 1 * dayBlockWidth
      };
    }.property('payPeriod.start', 'payPeriod.end', 'punch.timeIn', 'punch.timeOut'),
    */
    notClockedOut: Ember.computed('punch.{timeIn,timeOut}', function () {
      return this.get('punch.timeIn') && !this.get('punch.timeOut');
    })
  });

  _exports.default = _default;
});