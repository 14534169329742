define("slate-payroll-client/routes/punches/view/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        paycodes: this.store.query('company-paycode', {
          'permissions.employees': true,
          sort: {
            description: 1
          }
        }),
        punch: this.store.find('punch', params.punchid)
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.punch,
        paycodes: model.paycodes,
        dateInSelection: model.punch.get('timeIn'),
        dateOutSelection: model.punch.get('timeOut'),
        timeInSelection: model.punch.get('timeIn'),
        timeOutSelection: model.punch.get('timeOut')
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controller;

        if (!controller.get('showModal')) {
          controller.set('didAutoset', undefined);
          return;
        }

        transition.abort();
        controller.set('showModal', false);
        Ember.$('#' + controller.get('modalId')).one('hidden.bs.modal', Ember.run.bind(this, function () {
          transition.retry();
          this.controllerFor('punches.view').send('refresh');
        }));
        /*
        $('#' + controller.get('modalId')).one('hidden.bs.modal', () => {
          transition.retry()
          .then(() => {
            this.controllerFor('punches.view').send('refresh');
          });
        });
        */
      },
      didTransition: function didTransition() {
        // We must turn the modal on every time we enter the route
        this.controller.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});