define("slate-payroll-client/mixins/render-tooltips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RenderTooltips = Ember.Mixin.create({
    _scheduleTooltipRender: Ember.on('init', 'didInsertElement', function () {
      if (this.get('selfImplementTooltips') === true) {
        return;
      } // Schedule a renderTooltips for afterRender


      Ember.run.scheduleOnce('afterRender', this, this._renderTooltips);
    }),
    _renderTooltips: function _renderTooltips() {
      Ember.run.scheduleOnce('afterRender', function () {
        // Get jQuery object from view, find tooltip elements, destroy
        // any existing elements and then render the tooltips
        var $tooltips = Ember.$('.tooltip-trigger');

        if (!$tooltips || !$tooltips.tooltip) {
          return;
        }

        $tooltips.tooltip();
      });
    }
  });
  var _default = RenderTooltips;
  _exports.default = _default;
});