define("slate-payroll-client/templates/components/paystub-document-ytd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tMCOdIdT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\"],[11,\"class\",\"section-small-heading text-center\"],[9],[0,\"Year To Date\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"data-list text-center\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"x-currency\",[[23,[\"paystub\",\"yearToDateInformationGrossWages\"]]],null],false],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"Gross Wages\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"x-currency\",[[23,[\"paystub\",\"yearToDateInformationFederal\"]]],null],false],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"Federal Withholding\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"x-currency\",[[23,[\"paystub\",\"yearToDateInformationState\"]]],null],false],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"State Withholding\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"x-currency\",[[23,[\"paystub\",\"yearToDateInformationSocialSecurity\"]]],null],false],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"Social Security Withholding\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"x-currency\",[[23,[\"paystub\",\"yearToDateInformationMedicare\"]]],null],false],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"Medicare Withholding\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[1,[27,\"x-currency\",[[23,[\"paystub\",\"yearToDateInformationOtherDeductions\"]]],null],false],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"Other Deductions\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/paystub-document-ytd.hbs"
    }
  });

  _exports.default = _default;
});