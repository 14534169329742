define("slate-payroll-client/instance-initializers/mixpanel", ["exports", "ember-cli-mixpanel-service/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    if (_configuration.default.enabled) {
      var router;

      if (typeof instance.lookup === 'function') {
        router = instance.lookup('router:main');
      } else {
        router = instance.container.lookup('router:main');
      }

      if (_configuration.default.autoPageviewTracking == undefined || _configuration.default.autoPageviewTracking) {
        router.on('didTransition', function () {
          var attributeOverrides = _configuration.default.attributeOverrides;
          var mixpanelService;

          if (typeof instance.lookup === 'function') {
            mixpanelService = instance.lookup('service:mixpanel');
          } else {
            mixpanelService = instance.container.lookup('service:mixpanel');
          }

          var pageViewAttribute = _configuration.default.pageViewAttribute;
          mixpanelService.trackPageView(this.get(pageViewAttribute), attributeOverrides);
        });
      }
    }
  }

  var _default = {
    name: 'mixpanel',
    initialize: initialize
  };
  _exports.default = _default;
});