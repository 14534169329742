define("slate-payroll-client/components/paystub-grid-item", ["exports", "slate-payroll-client/mixins/render-tooltips"], function (_exports, _renderTooltips) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_renderTooltips.default, {
    paidOn: Ember.computed.or('paystub.{payPeriod.paidOn,payrollDate}'),
    actions: {
      toggleReadStatus: function toggleReadStatus() {
        var model = this.get('paystub');
        model.set('read', !model.get('read'));
        model.save();
      },
      download: function download() {
        var downloadAction = this.get('download');

        if (downloadAction && typeof downloadAction === 'function') {
          downloadAction(this.get('paystub.id'));
        }
      },
      print: function print() {
        var printAction = this.get('print');

        if (printAction && typeof printAction === 'function') {
          printAction(this.get('paystub.id'));
        }
      }
    }
  });

  _exports.default = _default;
});