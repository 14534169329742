define("slate-payroll-client/routes/squares-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('auth.authenticated')) {
        return this.transitionTo('punch.simple');
      }
    },
    model: function model(params) {
      if (params.companyid) {
        localforage.setItem('slateClientStickyCompanyId', params.companyid);
      }

      return this.store.queryRecord('company', {
        urlKey: params.companyid
      });
    }
  });

  _exports.default = _default;
});