define("slate-payroll-client/mixins/controller-abstractions/delete", ["exports", "slate-payroll-client/mixins/ux-ajax-status-hooks"], function (_exports, _uxAjaxStatusHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_uxAjaxStatusHooks.default, {
    actions: {
      delete: function _delete(model) {
        var _this = this;

        var _model = model || this.get('model');

        if (!_model) {
          return;
        }

        this.ajaxStart();

        _model.destroyRecord().then(function () {
          _this.ajaxSuccess('Successfully deleted.');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  _exports.default = _default;
});