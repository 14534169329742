define("slate-payroll-client/models/session", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    token: attr('string'),
    expires: attr('string'),
    esAuthenticated: attr('boolean'),
    user: attr('string')
  });

  _exports.default = _default;
});