define("slate-payroll-client/components/input/multi-select/x-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['multi-select-option', 'list-group-item'],
    classNameBindings: ['selected:checked'],
    click: function click(e) {
      e.preventDefault();
      this.get('toggle')(this.get('value'));
    },
    selected: Ember.computed('selection.[]', 'item', function () {
      var selection = this.get('selection') || Ember.A(),
          comparator = this.get('compareWithValue') ? this.get('value') : this.get('item');
      return selection.includes(comparator);
    }),
    value: Ember.computed('optionValuePath', 'item', function () {
      var item = this.get('item'),
          valPath = this.get('optionValuePath');
      return valPath ? Ember.get(item, valPath) : item;
    })
  });

  _exports.default = _default;
});