define("slate-payroll-client/mixins/array-qp-tweaks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array') {
        return value.split('-');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array') {
        return typeof value === 'string' ? value : value.join('-');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    coerceToArray: function coerceToArray(val) {
      var retVal = val && val.toArray ? val.toArray() : val;

      if (retVal && !Array.isArray(retVal) && typeof retVal === 'string') {
        try {
          retVal = JSON.parse(retVal.replace(/\\/g, ''));
        } catch (e) {
          retVal = retVal.match(/(\d\w*)/g);
        }
      }

      return retVal;
    }
  });

  _exports.default = _default;
});