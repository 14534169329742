define("slate-payroll-client/routes/punch", ["exports", "slate-payroll-client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    auth: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.get('auth.user'),
        broadcasts: this.store.query('broadcast', {
          sort: {
            created: -1
          },
          limit: 10
        }),
        activePayPeriod: this.store.query('pay-period', {
          start: {
            $lte: new Date()
          },
          end: {
            $gte: new Date()
          },
          acceptPunches: true
        }),
        activePunch: this.store.query('punch', {
          timeIn: {
            $lt: new Date()
          },
          $or: [{
            timeOut: {
              $exists: false
            }
          }, {
            timeOut: {
              $type: 10
            }
          }]
        }).then(function (result) {
          return result.get('firstObject');
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.user,
        activePayPeriod: model.activePayPeriod.get('firstObject'),
        activePunch: model.activePunch,
        broadcasts: model.broadcasts
      });
    }
  });

  _exports.default = _default;
});