define("slate-payroll-client/templates/paystubs/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T+WLpWX6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"hidden-print clearfix\"],[9],[0,\"\\n    \"],[4,\"link-to\",[\"paystubs.index\"],null,{\"statements\":[[7,\"i\"],[11,\"class\",\"fa fa-fw fa-angle-left\"],[9],[10],[0,\" Back to Paystubs\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"pull-right text-success\"],[3,\"action\",[[22,0,[]],\"print\"]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-print\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"pull-right text-info\"],[3,\"action\",[[22,0,[]],\"download\"]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-download\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"paystub-document\",null,[[\"paystub\",\"payPeriod\",\"company\"],[[23,[\"paystub\"]],[23,[\"payPeriod\"]],[23,[\"company\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/paystubs/view.hbs"
    }
  });

  _exports.default = _default;
});