define("slate-payroll-client/templates/components/bs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WWKzCiNI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"modal-dialog\",[27,\"if\",[[23,[\"modal-sm\"]],\" modal-sm\"],null],[27,\"if\",[[23,[\"modal-lg\"]],\" modal-lg\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"dismissable\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[9],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"Close\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[21,\"title\"],true],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/bs-modal.hbs"
    }
  });

  _exports.default = _default;
});