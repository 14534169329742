define("slate-payroll-client/helpers/x-inflect-text", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inflectText = inflectText;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);

  function inflectText(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        text = _ref2[0],
        length = _ref2[1];

    return length && length === 1 ? inflector.singularize(text) : inflector.pluralize(text);
  }

  var _default = Ember.Helper.helper(inflectText);
  /*
    Usage
    ---
    You have {{employees.length}} {{x-inflect-text 'Employee' employees.length}}!
      -> Given employees.length is 0 or >1
      "You have 0 Employees!"
      -> Given employees.length is 1
      "You have 1 Employee!"
  
    Note: Preserves case
   */


  _exports.default = _default;
});