define("slate-payroll-client/controllers/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    unauthorizedReason: Ember.computed('fromError', function () {
      var error = this.get('fromError');

      if (!error) {
        return 'Undefined Error.';
      }

      return error.responseText ? 'The server says: ' + error.responseText : 'The server says: ' + error.statusText;
    }),
    companyId: Ember.computed(function () {
      return localforage.getItem('slateClientStickyCompanyId');
    })
  });

  _exports.default = _default;
});