define("slate-payroll-client/templates/components/time-off/view-calendar/request-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tMa01khO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-\",[27,\"if\",[[23,[\"request\",\"approved\"]],\"check\",\"ellipsis-h\"],null]]]],[9],[10],[0,\"\\n\"],[1,[23,[\"request\",\"employee\",\"briefName\"]],false],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"timeDisplay\"]]],null,{\"statements\":[[0,\"    \"],[7,\"strong\"],[9],[1,[21,\"timeDisplay\"],false],[10],[0,\"\\n    (\"],[1,[21,\"hours\"],false],[0,\"hrs)\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"strong\"],[9],[1,[21,\"hours\"],false],[0,\"hrs\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/time-off/view-calendar/request-item.hbs"
    }
  });

  _exports.default = _default;
});