define("slate-payroll-client/initializers/handle-deprecation-warnings", ["exports", "slate-payroll-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (_environment.default.BUG_THE_HELL_OUTTA_ME) {
        next(message, options);
      }
    });
    Ember.Debug.registerWarnHandler(function (message, options, next) {
      if (_environment.default.BUG_THE_HELL_OUTTA_ME) {
        next();
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});