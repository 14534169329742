define("slate-payroll-client/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    firstTimeInRoute: true,
    beforeModel: function beforeModel() {
      if (this.get('auth.authenticated')) {
        return this.transitionTo('punch.simple');
      }
    },
    model: function model(params) {
      var urlKey = params.companyid || this.paramsFor('application').u;

      if (urlKey) {
        localforage.setItem('slateClientStickyCompanyId', urlKey);
      }

      return this.store.queryRecord('company', {
        urlKey: urlKey
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (model && model.get('enableSquares')) {
        var transitionToSquares = function transitionToSquares() {
          return _this.transitionTo('squares-login', model.get('urlKey'));
        };

        if (model.get('requireSquares')) {
          return transitionToSquares();
        }

        if (model.get('landOnSquaresLogin') && this.get('firstTimeInRoute')) {
          this.set('firstTimeInRoute', false);
          return transitionToSquares();
        }
      }
    }
  });

  _exports.default = _default;
});