define("slate-payroll-client/templates/components/x-version-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "50zgoVuH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"text-sm version\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"toggleProperty\",\"showVersion\"]],[9],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[28,[\"fa fa-fw fa-eye\",[27,\"if\",[[23,[\"showVersion\"]],\"-slash\"],null]]]],[9],[10],[0,\" \"],[1,[27,\"if\",[[23,[\"showVersion\"]],\"Hide\",\"Show\"],null],false],[0,\" version info\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showVersion\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\"],[9],[10],[0,\"App Version: \"],[1,[23,[\"buildInfo\",\"version\"]],false],[0,\" | Build: \"],[1,[23,[\"buildInfo\",\"commit\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/x-version-info.hbs"
    }
  });

  _exports.default = _default;
});