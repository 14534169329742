define("slate-payroll-client/controllers/paystubs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelUnread: Ember.computed.filterBy('model', 'read', false),
    modelPast: Ember.computed.filterBy('model', 'read', true),
    multiModels: Ember.computed('modelUnread', 'modelPast', function () {
      var _this = this;

      var modelNames = ['Unread', 'Past'];
      return modelNames.map(function (name) {
        var modelPath = 'model' + name;
        return {
          modelPath: modelPath,
          name: name,
          model: _this.get(modelPath)
        };
      });
    }),
    actions: {
      printPaystub: function printPaystub(paystubId) {
        this.set('printOnInit', true);
        this.transitionToRoute('paystubs.view', paystubId);
      },
      downloadPaystub: function downloadPaystub(paystubId) {
        this.set('downloadOnInit', true);
        this.transitionToRoute('paystubs.view', paystubId);
      }
    }
  });

  _exports.default = _default;
});