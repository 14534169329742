define("slate-payroll-client/routes/2fa/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service(),
    model: function model(params, transition) {
      var _this = this;

      return this.get('ajax').request('/client-api/employee/2fa/emp-methods').catch(function (err) {
        if (err.status === 400 && ((err.payload || [])[0] || {}).detail === 'Session already verified.') {
          _this.get('auth').set('isTwoFactor', true);

          return _this.transitionTo(transition.queryParams.intent || 'esa.index');
        }

        throw err;
      });
    }
  });

  _exports.default = _default;
});