define("slate-payroll-client/routes/punches/view", ["exports", "slate-payroll-client/mixins/refreshable"], function (_exports, _refreshable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_refreshable.default, {
    model: function model(params) {
      var payPeriodId = params.pay_period_id;
      var sortParams = ['sortCommentToEmployee', 'sortPunchComment', 'sortTimeIn', 'sortTimeOut', 'sortLunch', 'sortBreak', 'sortHours'],
          sort = {};
      sortParams.forEach(function (paramName) {
        var enableParam = params[paramName],
            paramAsc = params[paramName + 'Asc'],
            newParamName = paramName.replace('sort', '');

        if (enableParam) {
          sort[newParamName.substring(0, 1).toLowerCase() + newParamName.substring(1)] = paramAsc === true ? -1 : 1;
        }
      });

      if (Object.keys(sort).length < 1) {
        sort.timeIn = -1;
      }

      return new Ember.RSVP.hash({
        payPeriod: this.store.find('pay-period', payPeriodId),
        paycodes: this.store.query('company-paycode', {
          $or: [{
            unit: 'D'
          }, {
            unit: 'H'
          }]
        }),
        punches: this.store.query('punch', {
          payPeriod: payPeriodId,
          sort: sort
        })
      });
    }
  });

  _exports.default = _default;
});