define("slate-payroll-client/templates/components/x-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SIv+XlpQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"bs-modal\",null,[[\"id\",\"showModal\",\"modal-sm\",\"title\",\"static\"],[[23,[\"modalId\"]],[23,[\"showModal\"]],[23,[\"modalSm\"]],[23,[\"_confirmState\",\"title\"]],true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"x-confirm__modal-message\"],[9],[0,\"\\n      \"],[1,[23,[\"_confirmState\",\"message\"]],false],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isConfirm\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-success\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"close\",true]],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-check\"],[9],[10],[0,\" Yes\"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-danger\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"close\",false]],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-times\"],[9],[10],[0,\" No\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"isAlert\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-success\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-check\"],[9],[10],[0,\" Ok\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/x-confirm.hbs"
    }
  });

  _exports.default = _default;
});