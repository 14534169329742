define("slate-payroll-client/routes/punches/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query('pay-period', {
        paidOn: {
          $lt: (0, _moment.default)().add(3, 'month').toDate(),
          $gt: (0, _moment.default)().subtract(1, 'year').toDate()
        },
        sort: {
          paidOn: -1
        }
      });
    }
  });

  _exports.default = _default;
});