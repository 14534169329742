define("slate-payroll-client/controllers/supervisor/approvals/review/employee", ["exports", "slate-payroll-client/mixins/ux-ajax-status-hooks", "slate-payroll-client/mixins/confirm"], function (_exports, _uxAjaxStatusHooks, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hours(total, punch) {
    return total + (punch.get('hours') || 0);
  }

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, _confirm.default, {
    approvalIndex: Ember.computed('model.{approval.approved,employee}', function () {
      return Ember.A(this.get('model.approval.approved') || []).mapBy('employee').indexOf(this.get('model.employee.id')) + 1;
    }),
    approval: Ember.computed('approvalIndex', 'model.approval.approved.[]', function () {
      return Ember.A(this.get('model.approval.approved')).objectAt(this.get('approvalIndex') - 1);
    }),
    alreadyApproved: Ember.computed.bool('approval.at'),
    onLastApproval: Ember.computed('approvalIndex', 'model.approval.approved', function () {
      var approvals = this.get('model.approval.approved'),
          firstUnapproved = approvals.findIndex(function (approval) {
        return approval.at === null;
      });
      return this.get('approvalIndex') === approvals.length && (firstUnapproved !== -1 ? firstUnapproved === this.get('model.approval.approved.length') - 1 : true);
    }),
    workWeekGroupings: Ember.computed('model.payPeriod.{start,end}', 'model', function () {
      var payPeriod = this.get('model.payPeriod'),
          punches = this.get('model.punches').sortBy('timeIn'),
          end = moment(payPeriod.get('end')),
          workWeekStart = moment(this.get('model.company.workWeekStart')),
          groups = Ember.A();
      var start = moment(payPeriod.get('start')),
          startGroupEnd = moment(start).day(workWeekStart).add(6, 'days').endOf('day');
      groups.pushObject({
        start: start.startOf('day').toDate(),
        end: startGroupEnd.toDate()
      });
      start = moment(startGroupEnd).add(1, 'day');

      for (var i = 0; i < end.diff(start, 'days') / 6; i++) {
        var groupStart = moment(start).add(i, 'week').day(workWeekStart).startOf('day'),
            groupEnd = moment(groupStart).add(6, 'days').endOf('day');
        groups.pushObject({
          start: groupStart.toDate(),
          end: groupEnd.isAfter(moment(end).endOf('day')) ? moment(end).endOf('day').toDate() : groupEnd.toDate()
        });
      }

      groups.map(function (weekGroup) {
        weekGroup.punches = punches.filter(function (punch) {
          var s, e;

          if (punch.get('timeIn') && punch.get('timeOut')) {
            s = moment(punch.get('timeIn'));
            e = moment(punch.get('timeOut'));
          } else {
            s = e = moment(punch.get('created'));
          }

          return s.isAfter(weekGroup.start) && e.isBefore(weekGroup.end);
        });
        weekGroup.totalHours = weekGroup.punches.reduce(hours, 0);
        return weekGroup;
      });
      return groups;
    }),
    onFirstApproval: Ember.computed.equal('approvalIndex', 1),
    transitionToApprovalModel: function transitionToApprovalModel(dir) {
      var i = this.get('approvalIndex'),
          approved = this.get('model.approval.approved') || [];
      var firstUnapproved = approved.findIndex(function (approval) {
        return approval.at === null;
      });

      if (i === approved.length && firstUnapproved !== approved.length - 1) {
        i = firstUnapproved;
      } else {
        i = dir === 'next' ? i : dir === 'prev' ? i - 2 : i - 1;
      }

      var newModel = (approved[i] || {}).employee;

      if (!newModel) {
        return;
      }

      if (newModel !== this.get('model.employee.id')) {
        this.transitionToRoute('supervisor.approvals.review.employee', newModel);
      }
    },
    actions: {
      approveAndNext: function approveAndNext(skippingApprove) {
        var _this = this;

        this.ajaxStart();
        var approvalModel = this.get('model.approval'),
            index = this.get('approvalIndex') - 1;
        var beforeTransition;

        if (!this.get('alreadyApproved') && !skippingApprove) {
          approvalModel.set("approved.".concat(index, ".at"), new Date());
          beforeTransition = approvalModel.save();
        }

        Ember.RSVP.resolve(beforeTransition).then(function () {
          _this.ajaxSuccess();

          _this.transitionToApprovalModel('next');
        }).catch(this.ajaxError.bind(this));
      },
      finishReview: function finishReview() {
        var _this2 = this;

        this.confirm('Are you sure you want to finalize your review for this pay period?', 'Finalize Your Review').then(function (response) {
          if (!response) {
            return;
          }

          _this2.ajaxStart();

          var approvalModel = _this2.get('model.approval'),
              index = _this2.get('approvalIndex') - 1;

          if (!_this2.get('alreadyApproved')) {
            approvalModel.set("approved.".concat(index, ".at"), new Date());
          }

          approvalModel.setProperties({
            completedOn: new Date(),
            managerRejected: null
          });
          return approvalModel.save().then(function () {
            _this2.ajaxSuccess();

            _this2.transitionToRoute('supervisor.approvals');
          });
        }).catch(this.ajaxError.bind(this));
      },
      dismissPunchReview: function dismissPunchReview(punch) {
        var _this3 = this;

        punch.setProperties({
          needsReview: false,
          reviewDate: new Date(),
          reviewedBy: this.get('auth.user.fullName')
        });
        this.saveModel(punch).then(function () {
          return _this3.send('refresh');
        });
      }
    }
  });

  _exports.default = _default;
});