define("slate-payroll-client/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    urlKey: attr('string'),
    tin: attr('string'),
    email: attr('string'),
    logo: attr('string'),
    addressLine1: attr('string'),
    addressLine2: attr('string'),
    addressCity: attr('string'),
    addressState: attr('string'),
    addressZipcode: attr('string'),
    loginMessage: attr('string'),
    payPeriod: attr('string'),
    maxHourEntry: attr('number'),
    workWeekStart: attr('number'),
    timeMinuteInterval: attr('number'),
    requireSignatures: attr('boolean'),
    useGeolocation: attr('boolean'),
    requireNavLocation: attr('boolean'),
    disableTor: attr('boolean'),
    codeBasedPunch: attr('boolean'),
    enableSquares: attr('boolean', {
      defaultValue: false
    }),
    requireSquares: attr('boolean', {
      defaultValue: false
    }),
    requires2fa: attr('boolean', {
      defaultValue: false
    }),
    landOnSquaresLogin: attr('boolean', {
      defaultValue: false
    }),
    enablePunchComments: attr('boolean', {
      defaultValue: false
    }),
    enablePunchCommentsView: attr('boolean', {
      defaultValue: false
    }),
    enableDirectHourEntry: attr('boolean', {
      defaultValue: false
    }),
    basicAutoLogout: attr('boolean', {
      defaultValue: false
    }),
    allowCustomTimeOff: attr('boolean', {
      defaultValue: false
    }),
    timeOfDayTimeOff: attr('boolean', {
      defaultValue: false
    }),
    allowBreakBtn: attr('boolean', {
      defaultValue: false
    }),
    enablePAS: attr('boolean', {
      defaultValue: false
    }),
    tz: attr('string'),
    incompletePunches: attr('boolean'),
    supervisorChanges: attr('boolean'),
    supervisorReportsOnly: attr('boolean'),
    // Relational
    employees: _emberData.default.hasMany('employee', {
      async: true
    }),
    departments: _emberData.default.hasMany('department', {
      async: true
    }),
    locations: _emberData.default.hasMany('location', {
      async: true
    }),
    companyPaycodes: _emberData.default.hasMany('company-paycode', {
      async: true
    }),
    payPeriods: _emberData.default.hasMany('pay-period', {
      async: true
    }),
    // Legacy Fields
    legacyCompanyId: attr('number'),
    legacyUsernamePrefix: attr('string'),
    legacyMaxEFileMonths: attr('number'),
    inactiveOn: attr('date'),
    'time_stamp': attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});