define("slate-payroll-client/templates/supervisor/approvals/review/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Vk44KzO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"working\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\"],[11,\"class\",\"text-center text-muted\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-spin fa-spinner\"],[9],[10],[0,\" One moment...\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"model\",\"directReports\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h2\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"btn btn-lg btn-block btn-success\"],[3,\"action\",[[22,0,[]],\"initializeReview\"]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"continuingReview\"]]],null,{\"statements\":[[0,\"          Continue Review\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-eye\"],[9],[10],[0,\" Begin Review With \"],[1,[23,[\"model\",\"directReports\",\"length\"]],false],[0,\" \"],[1,[27,\"inflect-text\",[\"Employees\",[23,[\"model\",\"directReports\",\"length\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h4\"],[11,\"class\",\"text-warning text-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\" No Direct Reports Available. No Review Required.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/approvals/review/index.hbs"
    }
  });

  _exports.default = _default;
});