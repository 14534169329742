define("slate-payroll-client/templates/tax-forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOCZF9TY",
    "block": "{\"symbols\":[\"files\",\"year\",\"file\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-text-o\"],[9],[10],[0,\" Tax Forms\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"model\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"grid-heading\"],[9],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row model-grid-view\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"tax-form-grid-item\",null,[[\"class\",\"file\"],[\"col-md-3\",[22,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"    \"],[7,\"h4\"],[11,\"class\",\"text-center text-muted\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\" No Tax Forms Available.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/tax-forms.hbs"
    }
  });

  _exports.default = _default;
});