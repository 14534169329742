define("slate-payroll-client/utils/serialize-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeObject;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function upperCaseFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function serializeObject(object, prefix) {
    var args = Array.prototype.slice.call(arguments, 2);

    if (!object || _typeof(object) !== 'object') {
      return object;
    }

    var setInside = function setInside(o, a) {
      for (var key in o) {
        if (!o.hasOwnProperty(key)) {
          continue;
        }

        if (_typeof(o[key]) === 'object') {
          return setInside(o[key], a ? a + upperCaseFirst(key) : key);
        }

        var modifiedKey = a ? a + upperCaseFirst(key) : key;
        object[modifiedKey] = o[key];
      }

      return object;
    };

    args.forEach(function (objectKey) {
      if (!object[objectKey] || _typeof(object[objectKey]) !== 'object') {
        return;
      }

      setInside(object[objectKey], prefix ? objectKey : null);
      delete object[objectKey];
    });
    return object;
  }
});