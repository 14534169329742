define("slate-payroll-client/controllers/supervisor/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.hourChart = {
        chartOptions: {
          color: {
            pattern: ['#374140', '#FF6138', '#79BD8F']
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                format: '%m-%d-%Y'
              }
            },
            y: {
              label: {
                text: 'Hours',
                position: 'outer-middle'
              }
            }
          }
        },
        dataOptions: {
          x: 'x',
          types: {
            Total: 'spline',
            Regular: 'bar',
            Overtime: 'bar'
          },
          groups: [['Overtime', 'Regular']]
        }
      };
    },
    dataset: Ember.computed('chartData.@each.hours', function () {
      var model = this.get('chartData');
      var data = [['x'], ['Regular'], ['Overtime'], ['Total']],
          start = (0, _moment.default)().subtract(14, 'days');

      var punchesForDate = function punchesForDate(date) {
        return model.filter(function (p) {
          return (0, _moment.default)(p.get('timeIn')).isSame(date, 'day');
        });
      };

      var totalPunches = function totalPunches(totals, punch) {
        var i = punch.get('isOvertime') ? 1 : 0;
        totals[i] += punch.get('hours');
        return totals;
      };

      var totalData = function totalData() {
        var t = function t(a, i) {
          return a + i;
        };

        return data[1].slice(1).reduce(t, 0) + data[2].slice(1).reduce(t, 0);
      };

      for (var i = 0; i < 14; i++) {
        var referenceDate = (0, _moment.default)(start).add(i, 'day'),
            filteredPunches = punchesForDate(referenceDate),
            totals = filteredPunches.reduce(totalPunches, [0, 0]);
        data[0].push(referenceDate.toDate());
        data[1].push(totals[0]);
        data[2].push(totals[1]);
        data[3].push(totalData());
      }

      return data;
    })
  });

  _exports.default = _default;
});