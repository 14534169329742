define("slate-payroll-client/controllers/punch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    isClockedIn: Ember.computed.bool('activePunch'),
    _activePunchNeedsUpdate: true
  });

  _exports.default = _default;
});