define("slate-payroll-client/routes/supervisor/employees/employee/add-punch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('punch', {
        employee: this.modelFor('supervisor.employees.employee').model,
        timesheet: true,
        status: 'Created by Supervisor [' + this.get('auth.user.initials') + ']',
        clientVersion: "slate-payroll-client@".concat(this.get('buildInfo.version'))
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controller; // If the modal is already hidden and we are trying a transition, we let the transition happen

        if (!controller.get('showModal')) {
          // If the model is new (unsaved) we are going to assume the user wants
          // to abandon progress on the model.
          if (controller.get('model.isNew')) {
            controller.get('model').destroyRecord();
          }

          return;
        } // If the modal is not hidden, we have to abort for now


        transition.abort(); // We trigger the modal hiding itself

        this.controller.set('showModal', false); // We listen for the hidden modal event

        Ember.$('#' + controller.get('modalId')).one('hidden.bs.modal', function () {
          // We try the transition again after the modal hides itself
          transition.retry();
        }); // Rollback changes if we are aborting an edit

        if (this.controller.get('model.hasDirtyAttributes')) {
          this.controller.get('model').rollbackAttributes();
          this.controller.set('didAutoset', false);
        }
      },
      didTransition: function didTransition() {
        // We must turn the modal on every time we enter the route
        this.controller.set('showModal', true);
      }
    }
  });

  _exports.default = _default;
});