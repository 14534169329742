define("slate-payroll-client/components/x-sv-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sve-li-picture'],
    attributeBindings: ['style'],
    mergeStyles: null,
    style: Ember.computed('picture', function () {
      var picture = this.get('picture'),
          mergeStyles = this.get('mergeStyles');
      var returnStyle = 'background-image: url(\'';
      returnStyle += picture || '/assets/images/no-user-thumbnail.jpg';
      returnStyle += '\');';

      if (mergeStyles) {
        returnStyle += ' ' + mergeStyles;
      }

      return new Ember.String.htmlSafe(returnStyle);
    })
  });

  _exports.default = _default;
});