define("slate-payroll-client/templates/sso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "72XtwGIK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container loader loader--fs\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-spinner fa-spin\"],[9],[10],[0,\"\\n    One moment while Slate completes your sign in...\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/sso.hbs"
    }
  });

  _exports.default = _default;
});