define("slate-payroll-client/templates/supervisor/time-off/historical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQ1bs9jL",
    "block": "{\"symbols\":[\"request\"],\"statements\":[[7,\"p\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"supervisor.time-off.index\"],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-angle-left\"],[9],[10],[0,\" Back to Time Off Management\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"time-off/condensed-request-item\",null,[[\"request\",\"showState\"],[[22,1,[]],true]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\" No Historical Requests.\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 form-group\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"employee-filter-select\"],[11,\"class\",\"sr-only\"],[9],[0,\"Filter By Employee\"],[10],[0,\"\\n    \"],[1,[27,\"bound-select\",null,[[\"selectId\",\"selectClass\",\"prompt\",\"selection\",\"content\",\"optionLabelPath\",\"optionValuePath\",\"compareSelectionWithValue\",\"action\"],[\"employee-filter-select\",\"form-control\",\"Filter by employee\",[23,[\"e\"]],[23,[\"employees\"]],\"fullName\",\"id\",true,[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"e\"]]],null]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6 text-right\"],[9],[1,[27,\"x-pagination\",null,[[\"page\",\"pages\",\"maxButtons\"],[[23,[\"page\"]],[23,[\"pages\"]],8]]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/time-off/historical.hbs"
    }
  });

  _exports.default = _default;
});