define("slate-payroll-client/components/time-off/select-calendar/select-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['cal-day', 'cal-day-select'],
    classNameBindings: ['day.outOfMonth:cal-gray', 'day.selected:selected', 'day.half:cal-half-day', 'isToday:is-today', 'customHours:cal__day--custom'],
    toggleClick: function toggleClick() {
      if (Ember.get(this, 'day.outOfMonth')) {
        return;
      }

      if (Ember.get(this, 'day.selected')) {
        if (Ember.get(this, 'day.half')) {
          Ember.set(this, 'day.selected', false);
          Ember.set(this, 'day.half', false);
          Ember.get(this, 'removedDay')(Ember.get(this, 'day'));
        } else {
          Ember.set(this, 'day.half', true);
          this.get('changedDay')(this.get('day'));
        }
      } else {
        Ember.set(this, 'day.selected', true);
        Ember.get(this, 'addedDay')(Ember.get(this, 'day'));
      }

      Ember.set(this, 'day.hours', Ember.get(this, 'day.half') ? 4 : 8);

      if (this.get('company.timeOfDayTimeOff')) {
        Ember.set(this, 'day.date', (0, _moment.default)(this.get('day.date')).hour(8).toDate());
        Ember.set(this, 'day.end', (0, _moment.default)(this.get('day.date')).add(this.get('day.hours'), 'hours'));
      }
    },
    isToday: Ember.computed('day.date', function () {
      return this.get('day.date') && (0, _moment.default)().isSame(this.get('day.date'), 'day');
    }),
    customHours: Ember.computed('day.hours', function () {
      var h = this.get('day.hours');
      return h && h !== 4 && h !== 8;
    }),
    actions: {
      toggleSelect: function toggleSelect() {
        var day = Ember.get(this, 'day');
        Ember.set(day, 'selected', !Ember.get(day, 'selected'));
      },
      toggleCustomEditing: function toggleCustomEditing() {
        this.toggleProperty('editingHours');

        if (this.get('editingHours')) {
          this.set('hours', this.get('day.hours'));
        }
      },
      setHours: function setHours() {
        var hours = parseFloat(this.get('hours'));
        this.set('day.half', hours === 4);
        this.set('day.hours', hours);

        if (this.get('company.timeOfDayTimeOff')) {
          Ember.set(this, 'day.end', (0, _moment.default)(this.get('day.date')).add(this.get('day.hours'), 'hours'));
        }

        this.send('toggleCustomEditing');
        this.get('changedDay')(this.get('day'));
      }
    }
  });

  _exports.default = _default;
});