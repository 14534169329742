define("slate-payroll-client/components/input/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['multi-select', 'list-group'],
    _selection: Ember.A(),
    didReceiveAttrs: function didReceiveAttrs() {
      this.selectionChanged();
    },
    selectionChanged: Ember.observer('selection.[]', function () {
      var selection = this.get('selection');

      if (selection) {
        var a = Ember.A();
        a.addObjects(selection);
        this.set('_selection', a);
      }
    }),
    toggleItem: function toggleItem(value) {
      var selection = this.get('_selection');
      selection[selection.includes(value) ? 'removeObject' : 'addObject'](value);
      this.get('onChange')(selection);
    }
  });

  _exports.default = _default;
});