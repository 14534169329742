define("slate-payroll-client/components/list/collapsing-nav-pills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollapsingNavPillsComponent = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['nav', 'nav-pills', 'nav-stacked', 'nav-pills--collapsing'],
    classNameBindings: ['collapsed:nav-pills--collapsed'],
    collapsed: false
  });
  CollapsingNavPillsComponent.reopenClass({
    positionalParams: ['links']
  });
  var _default = CollapsingNavPillsComponent;
  _exports.default = _default;
});