define("slate-payroll-client/templates/esa/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ql4Mi+i4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"transparent-card clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n    \"],[7,\"h3\"],[9],[0,\"Please enter your password to access that page.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"form\"],[11,\"class\",\"card-body\"],[3,\"action\",[[22,0,[]],\"login\"],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n\"],[4,\"auto-focus\",null,[[\"class\"],[\"form-group\"]],{\"statements\":[[0,\"      \"],[7,\"label\"],[11,\"for\",\"esat\"],[9],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"class\",\"value\",\"placeholder\"],[\"password\",\"esat\",\"form-control input-lg\",[23,[\"esat\"]],\"Password\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"text-danger\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-exclamation-triangle\"],[9],[10],[0,\"\\n          Error logging in: \"],[1,[21,\"errorMessage\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-success btn-lg btn-block\"],[12,\"disabled\",[27,\"if\",[[27,\"and\",[[27,\"not\",[[23,[\"working\"]]],null],[23,[\"esat\"]]],null],false,true],null]],[11,\"type\",\"submit\"],[9],[0,\"Login\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"form-group text-center text-sm\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"esa.reset\"],[[\"class\"],[\"text-danger\"]],{\"statements\":[[0,\"Reset your password\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/esa/index.hbs"
    }
  });

  _exports.default = _default;
});