define("slate-payroll-client/templates/components/paystub-document-company-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Lz6E7Vt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"company\",\"logo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"paystub__company-logo company-logo\"],[12,\"style\",[28,[\"background-image: url('/client-api\",[23,[\"company\",\"logo\"]],\"');\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[23,[\"company\",\"name\"]],false],[0,\"\\n\\n  \"],[7,\"small\"],[11,\"class\",\"pull-right text-right\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"company\",\"addressLine1\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"company\",\"addressLine1\"]],false],[0,\" \"],[1,[27,\"if\",[[23,[\"company\",\"addressLine2\"]],[23,[\"company\",\"addressLine2\"]]],null],false],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"company\",\"addressCity\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"company\",\"addressCity\"]],false],[0,\", \"],[1,[23,[\"company\",\"addressState\"]],false],[0,\" \"],[1,[23,[\"company\",\"addressZipcode\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/paystub-document-company-information.hbs"
    }
  });

  _exports.default = _default;
});