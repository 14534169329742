define("slate-payroll-client/utils/is-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isNumber;

  function isNumber(x) {
    return typeof x === 'number' && !isNaN(x);
  }
});