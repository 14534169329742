define("slate-payroll-client/controllers/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    logging: Ember.inject.service(),
    modelUnread: Ember.computed.filterBy('model', 'isRead', false),
    modelRead: Ember.computed.filterBy('model', 'isRead', true),
    multiModels: Ember.computed('modelUnread', 'modelRead', function () {
      var _this = this;

      var modelNames = ['Unread', 'Read'];
      return modelNames.map(function (name) {
        var modelPath = 'model' + name;
        return {
          modelPath: modelPath,
          name: name,
          model: _this.get(modelPath)
        };
      });
    }),
    actions: {
      refreshModel: function refreshModel() {
        this.logging.debug('Documents Controller :: Refresh');
        this.send('refreshModel');
      }
    }
  });

  _exports.default = _default;
});