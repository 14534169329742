define("slate-payroll-client/utils/computed-equals-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedEqualsKey;

  function computedEqualsKey(rhsDependentKey, lhsDependentKey) {
    return Ember.computed(rhsDependentKey, lhsDependentKey, function () {
      return Ember.get(this, rhsDependentKey) === Ember.get(this, lhsDependentKey);
    });
  }
});