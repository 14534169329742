define("slate-payroll-client/templates/components/list-item/punch-view-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P2bYcr8+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"class\",\"type\",\"value\",\"placeholder\"],[\"form-control input-sm\",\"text\",[22,0,[\"view\",\"name\"]],\"View Name\"]]],false],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"text-danger input-group-addon\"],[3,\"action\",[[22,0,[]],[23,[\"delete\"]],[23,[\"view\"]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-trash-o text-danger\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/list-item/punch-view-item.hbs"
    }
  });

  _exports.default = _default;
});