define("slate-payroll-client/mixins/resource-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PaginationMixin = Ember.Mixin.create({
    __maxItems: Ember.computed.alias('model.meta.totalRecords'),
    pages: Ember.computed('__maxItems', 'limit', 'target.limit', function () {
      var limit = this.get('limit');
      return Math.ceil(this.get('__maxItems') / limit);
    }),
    __minMax: Ember.observer('page', 'pages', function () {
      var page = this.get('page'),
          pages = this.get('pages');

      if (page && page < 0) {
        this.set('page', 1);
      } else if (page && page > pages) {
        this.set('page', pages || 1);
      }
    })
  });
  PaginationMixin[Ember.NAME_KEY] = 'Slate.ResourcePaginationMixin';
  var _default = PaginationMixin;
  _exports.default = _default;
});