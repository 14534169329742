define("slate-payroll-client/templates/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTKOko9n",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[28,[\"ember-radio-button \",[27,\"if\",[[23,[\"checked\"]],\"checked\"],null],\" \",[21,\"joinedClassNames\"]]]],[12,\"for\",[21,\"radioId\"]],[9],[0,\"\\n    \"],[1,[27,\"radio-button-input\",null,[[\"class\",\"id\",\"disabled\",\"name\",\"required\",\"groupValue\",\"value\",\"changed\"],[[23,[\"radioClass\"]],[23,[\"radioId\"]],[23,[\"disabled\"]],[23,[\"name\"]],[23,[\"required\"]],[23,[\"groupValue\"]],[23,[\"value\"]],\"changed\"]]],false],[0,\"\\n\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"radio-button-input\",null,[[\"class\",\"id\",\"disabled\",\"name\",\"required\",\"groupValue\",\"value\",\"changed\"],[[23,[\"radioClass\"]],[23,[\"radioId\"]],[23,[\"disabled\"]],[23,[\"name\"]],[23,[\"required\"]],[23,[\"groupValue\"]],[23,[\"value\"]],\"changed\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/radio-button.hbs"
    }
  });

  _exports.default = _default;
});