define("slate-payroll-client/models/earning", ["exports", "slate-payroll-client/models/transaction"], function (_exports, _transaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transaction.default.extend({
    isTaxable: Ember.computed('taxLocal', 'taxState', 'taxFederal', function () {
      return this.get('taxLocal') || this.get('taxState') || this.get('taxFederal');
    })
  });

  _exports.default = _default;
});