define("slate-payroll-client/templates/components/input/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gX1IxW03",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"content\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"input/multi-select/x-option\",null,[[\"item\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"compareWithValue\",\"toggle\"],[[22,1,[]],[23,[\"optionValuePath\"]],[23,[\"optionLabelPath\"]],[23,[\"_selection\"]],[23,[\"compareSelectionWithValue\"]],[27,\"action\",[[22,0,[]],[23,[\"toggleItem\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/input/multi-select.hbs"
    }
  });

  _exports.default = _default;
});