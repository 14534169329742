define("slate-payroll-client/models/pay-period", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    notes: attr('string'),
    start: attr('date'),
    end: attr('date'),
    paidOn: attr('date'),
    acceptPunches: attr('boolean'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    created: attr('date'),
    days: Ember.computed('start', 'end', function () {
      var range = this.getProperties('start', 'end');
      return (0, _moment.default)(range.end).diff((0, _moment.default)(range.start), 'days');
    }),
    title: Ember.computed('start', 'end', function () {
      return (0, _moment.default)(this.get('start')).format('M/D/YY') + ' - ' + (0, _moment.default)(this.get('end')).format('M/D/YY');
    })
  });

  _exports.default = _default;
});