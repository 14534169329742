define("slate-payroll-client/routes/supervisor/time-off/balances", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var query = {
        $or: [{
          terminatedOn: {
            $not: {
              $type: 9
            }
          }
        }, {
          terminatedOn: {
            $exists: false
          }
        }, {
          terminatedOn: {
            $gte: (0, _moment.default)().subtract(1, 'month').toDate()
          }
        }],
        sort: {
          'name.last': -1
        }
      };

      if (this.paramsFor('supervisor').allSupervised) {
        query.includeRecursiveSupervised = true;
      }

      return this.store.query('employee', query).then(function (employees) {
        var headers = Ember.A();
        employees.forEach(function (employee) {
          employee.get('balances').forEach(function (balance) {
            headers.addObject(balance.description);
          });
        });
        return {
          headers: headers,
          rows: employees.map(function (employee) {
            return {
              employee: employee,
              columns: headers.map(function (header) {
                return employee.get('balances').findBy('description', header) || 0;
              })
            };
          }),
          totals: headers.map(function (header) {
            return employees.reduce(function (sum, employee) {
              var balanceItem = employee.get('balances').findBy('description', header);
              return sum + (balanceItem ? balanceItem.balance : 0);
            }, 0);
          })
        };
      });
    },
    actions: {
      sum: function sum(_sum, num) {
        return _sum + (num || 0);
      }
    }
  });

  _exports.default = _default;
});