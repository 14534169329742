define("slate-payroll-client/templates/supervisor/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0KL23DvY",
    "block": "{\"symbols\":[\"employee\"],\"statements\":[[1,[27,\"supervisor/week-at-a-glance\",null,[[\"user\",\"store\"],[[23,[\"auth\",\"user\"]],[23,[\"store\"]]]]],false],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"Employees you supervise\"],[10],[0,\"\\n    \"],[7,\"hr\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group text-center\"],[9],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"x-sv-employee-list-item\",null,[[\"employee\",\"store\",\"selected\",\"onClick\"],[[22,1,[]],[23,[\"store\"]],[23,[\"activeEmployee\"]],[27,\"action\",[[22,0,[]],\"selectEmployee\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/employees.hbs"
    }
  });

  _exports.default = _default;
});