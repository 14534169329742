define("slate-payroll-client/controllers/supervisor/punches/add-many", ["exports", "slate-payroll-client/mixins/ux-ajax-status-hooks", "slate-payroll-client/config/app-data", "slate-payroll-client/config/models", "moment"], function (_exports, _uxAjaxStatusHooks, _appData, _models, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    punchesController: Ember.inject.controller('supervisor/punches'),
    showModal: true,
    modalId: 'add-punches-modal',
    modalTitle: '<h2>Add Punches</h2>',
    init: function init() {
      this._super.apply(this, arguments);

      this.selectedEmployees = [];
      this.selectionOptions = [{
        name: 'Exempt',
        filter: {
          key: 'exempt',
          value: true
        }
      }, {
        name: 'Hourly',
        filter: {
          key: 'exempt',
          value: false
        }
      }, {
        name: 'Part Time',
        filter: {
          key: 'partTime',
          value: true
        }
      }, {
        name: 'Full Time',
        filter: {
          key: 'partTime',
          value: false
        }
      }, {
        name: 'Department',
        modelName: 'department'
      }, {
        name: 'Supervisor',
        modelName: 'employee',
        key: 'supervisor'
      }];
    },
    selectByValid: Ember.computed('selectBy', 'selectByRecord', function () {
      var selectBy = this.get('selectBy');
      return selectBy && (selectBy.filter || this.get('selectByRecord'));
    }),
    lazyModelSettings: Ember.computed('selectBy.modelName', function () {
      var modelName = this.get('selectBy.modelName');

      if (modelName) {
        var setting = _models.modelSettings[modelName];
        return setting || {
          label: 'id'
        };
      }
    }),
    selectByChanged: Ember.observer('selectBy', function () {
      this.set('selectByRecord', undefined);
    }),
    allowSubmit: Ember.computed('selectedEmployees.[]', 'working', 'paycode', 'created', function () {
      return !this.get('working') && this.get('selectedEmployees.length') > 0 && this.get('paycode') && this.get('created');
    }),
    resetForm: function resetForm() {
      this.setProperties({
        units: undefined,
        paycode: undefined,
        selectedEmployees: [],
        commentToEmployee: undefined,
        created: undefined
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.resetForm();
      this.transitionToRoute('supervisor.punches');
    },
    findPayPeriod: function findPayPeriod(date) {
      return this.store.query('pay-period', {
        start: {
          $lte: date
        },
        end: {
          $gte: date
        }
      }).then(function (res) {
        return res.get('firstObject');
      });
    },
    actions: {
      selectAll: function selectAll() {
        this.get('selectedEmployees').addObjects(this.get('employees'));
      },
      deselectAll: function deselectAll() {
        this.get('selectedEmployees').removeObjects(this.get('selectedEmployees'));
      },
      selectCustom: function selectCustom() {
        var selectBy = this.get('selectBy'),
            selectByRecord = this.get('selectByRecord'),
            selected = this.get('selectedEmployees'),
            employees = this.get('employees');

        if (!selectBy) {
          return;
        }

        if (selectBy.filter) {
          selected.addObjects(employees.filterBy(selectBy.filter.key, selectBy.filter.value));
        } else if (selectBy.modelName && selectByRecord) {
          var id = selectByRecord.get('id');
          selected.addObjects(employees.filter(function (employee) {
            var rel = employee.belongsTo(selectBy.key || selectBy.modelName),
                relValue = rel && rel.value ? rel.value() : rel;
            return relValue && relValue.get('id') === id;
          }));
        }

        this.setProperties({
          selectBy: undefined,
          selectByRecord: undefined
        });
      },
      close: function close() {
        this.transitionToParentRoute();
      },
      addPunches: function addPunches() {
        var _this = this;

        if (!this.get('allowSubmit')) {
          return;
        }

        this.ajaxStart();
        var units = this.get('units'),
            employees = this.get('selectedEmployees'),
            paycode = this.get('paycode');

        var mapping = _appData.paycodeUnitMap[paycode.get('unitActual')];

        if (_typeof(mapping) === 'object') {
          mapping = mapping[paycode.get('type')];
        }

        this.findPayPeriod(this.get('created')).then(function (payPeriod) {
          if (!payPeriod) {
            return _this.ajaxError('Unable to find appropriate pay period', true);
          }

          return Ember.RSVP.map(employees, function (employee) {
            var data = {};
            data[mapping] = units;
            var time = (0, _moment.default)(_this.get('created')).hour(12).minute(0).second(0).toDate();
            return _this.store.createRecord('punch', Ember.$.extend(data, {
              payPeriod: payPeriod,
              employee: employee,
              companyPaycode: paycode,
              status: 'Added by Supervisor',
              created: new Date(),
              commentToEmployee: _this.get('commentToEmployee'),
              timesheet: true,
              directHourEntry: true,
              timeIn: time,
              timeOut: time,
              clientVersion: "slate-payroll-client@".concat(_this.get('buildInfo.version'))
            })).save();
          }).then(function () {
            _this.ajaxSuccess('Successfully added.');

            _this.resetForm();
          });
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  _exports.default = _default;
});