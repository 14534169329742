define("slate-payroll-client/routes/supervisor/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        chartData: this.getChartData(),
        employees: this.getEmployees()
      });
    },
    getChartData: function getChartData() {
      var query = {
        _supervised: true,
        timeIn: {
          $gte: (0, _moment.default)().subtract(14, 'days').toDate()
        },
        timeOut: {
          $lte: new Date()
        },
        hours: {
          $exists: true
        }
      };

      if (this.paramsFor('supervisor').allSupervised) {
        query.includeRecursiveSupervised = true;
      }

      return this.store.query('punch', query);
    },
    getEmployees: function getEmployees() {
      var query = {
        $or: [{
          terminatedOn: {
            $not: {
              $type: 9
            }
          }
        }, {
          terminatedOn: {
            $exists: false
          }
        }, {
          terminatedOn: {
            $gte: (0, _moment.default)().subtract(1, 'month').toDate()
          }
        }],
        sort: {
          'name.last': -1
        }
      };

      if (this.paramsFor('supervisor').allSupervised) {
        query.includeRecursiveSupervised = true;
      }

      return this.store.query('employee', query);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        chartData: model.chartData,
        employees: model.employees
      });
    }
  });

  _exports.default = _default;
});