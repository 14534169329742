define("slate-payroll-client/components/input/time-date", ["exports", "ember-flatpickr/components/ember-flatpickr", "slate-payroll-client/utils/date-parser", "ember-diff-attrs"], function (_exports, _emberFlatpickr, _dateParser, _emberDiffAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var defaults = {
    allowInput: true,
    altInput: true,
    enableTime: true,
    enableDate: true,
    altFormat: 'm/d/Y h:iK',
    defInputClasses: 'form-control time-picker-input',
    clickOpens: true,
    shorthandCurrentMonth: true,
    weekNumbers: false,
    disableMobile: false,
    minuteIncrement: Ember.computed('auth.user.company.timeMinuteInterval', function () {
      return this.get('auth.user.company.timeMinuteInterval') || 15;
    }),
    placeholder: Ember.computed('enableTime', 'enableDate', function () {
      var selectOpts = [this.get('enableDate') && 'Date', this.get('enableTime') && 'Time'].filter(Boolean).join('/');
      return "Type Or Select A ".concat(selectOpts);
    }),
    altInputClass: Ember.computed('classes', 'defInputClasses', function () {
      var classes = [this.get('defInputClasses'), this.get('classes')].filter(Boolean);
      return classes.join(' ');
    }),
    parseDate: function parseDate(date) {
      return (0, _dateParser.default)(date, {
        type: [this.get('enableDate') && 'date', this.get('enableTime') && 'time'].join('')
      });
    }
  };

  var _default = _emberFlatpickr.default.extend({
    auth: Ember.inject.service(),

    /* Slate Defaults */
    init: function init() {
      var _this = this;

      var def = Object.keys(defaults).reduce(function (adjustedDefaults, defaultKey) {
        if (_this.attrs[defaultKey] !== undefined) {
          return adjustedDefaults;
        }

        return Object.assign(_defineProperty({}, defaultKey, defaults[defaultKey]), adjustedDefaults);
      }, {});

      if (def.parseDate) {
        def.parseDate = def.parseDate.bind(this);
      }

      Ember.setProperties(this.attrs, def);
      Ember.setProperties(this, def);

      this._super.apply(this, arguments);
    },

    /* ./ Slate Defaults */
    didReceiveAttrs: (0, _emberDiffAttrs.default)('date', 'disabled', 'locale', 'maxDate', 'minDate', 'enable', 'disable', function (changedAttrs) {
      var _this2 = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);

      this._attributeHasChanged(changedAttrs, 'date', function (newDate) {
        if (typeof newDate !== 'undefined') {
          _this2.element._flatpickr.setDate(newDate);
        }
      });

      this._attributeHasChanged(changedAttrs, 'disabled', function (newDisabled) {
        if (typeof newDisabled !== 'undefined') {
          _this2._setDisabled(newDisabled);
        }
      });

      this._attributeHasChanged(changedAttrs, 'locale', function () {
        _this2.element._flatpickr.destroy();

        _this2.setupComponent();
      });

      this._attributeHasChanged(changedAttrs, 'enable', function (enable) {
        _this2.element._flatpickr.set('enable', enable);
      });

      this._attributeHasChanged(changedAttrs, 'disable', function (disable) {
        _this2.element._flatpickr.set('disable', disable);
      });

      this._attributeHasChanged(changedAttrs, 'maxDate', function (newMaxDate) {
        _this2.element._flatpickr.set('maxDate', newMaxDate);
      });

      this._attributeHasChanged(changedAttrs, 'minDate', function (newMinDate) {
        _this2.element._flatpickr.set('minDate', newMinDate);
      });
    }),
    onClose: function onClose(dobj, selection, picker) {
      picker.setDate(picker.altInput.value, true);

      if (this.onPickerClose) {
        this.onPickerClose();
      }
    }
  });

  _exports.default = _default;
});