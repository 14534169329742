define("slate-payroll-client/routes/supervisor/approvals/review/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service(),
    model: function model() {
      var userId = this.get('auth.user.id'),
          parentModel = this.modelFor('supervisor.approvals.review'),
          _ref = this.paramsFor('supervisor.approvals.review') || {},
          standIn = _ref.standIn;

      return Ember.RSVP.hash({
        parentModel: parentModel,
        // get employees that are direct reports ~AND non-exempt(removed as of #486)
        directReports: this.store.query('employee', {
          supervisor: standIn || userId,
          $or: [{
            terminatedOn: {
              $not: {
                $type: 9
              }
            }
          }, {
            terminatedOn: {
              $gte: Ember.get(parentModel, 'payPeriod.start')
            }
          }],
          sort: {
            'name.last': 1
          }
        })
      }).then(function (_ref2) {
        var parentModel = _ref2.parentModel,
            directReports = _ref2.directReports;

        if (!parentModel || !parentModel.approval) {
          return {
            parentModel: parentModel,
            directReports: directReports
          };
        }

        var removeSubapprovals = [],
            approval = parentModel.approval,
            approvals = Ember.get(approval, 'approved') || Ember.A(); // double check directReports are still correct...

        approvals.forEach(function (subapproval) {
          if (!directReports.findBy('id', subapproval.employee)) {
            removeSubapprovals.push(subapproval);
          }
        });
        var addSubapprovals = directReports.reduce(function (add, employee) {
          if (!approvals.findBy('employee', employee.get('id'))) {
            add.push({
              at: null,
              employee: employee.get('id')
            });
          }

          return add;
        }, []);
        var didChange = false;

        if (removeSubapprovals && removeSubapprovals.length > 0) {
          didChange = true;
          approvals.removeObjects(removeSubapprovals);
        }

        if (addSubapprovals && addSubapprovals.length > 0) {
          didChange = true;
          approvals.addObjects(addSubapprovals);
        }

        return Ember.RSVP.resolve(didChange ? approval.save() : null).then(function () {
          return {
            parentModel: parentModel,
            directReports: directReports
          };
        });
      });
    }
  });

  _exports.default = _default;
});