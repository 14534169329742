define("slate-payroll-client/controllers/beyonce", ["exports", "slate-payroll-client/mixins/confirm", "slate-payroll-client/config/beyonce"], function (_exports, _confirm, _beyonce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ipsum = 'Bacon ipsum dolor amet pig id ut ea velit chicken incididunt brisket. Mollit deserunt tri-tip ut commodo. Commodo culpa brisket pork belly. Mollit voluptate ribeye, in in dolor est aliqua sunt t-bone quis salami pancetta ham hock.';

  var _default = Ember.Controller.extend(_confirm.default, {
    ipsum: ipsum,
    queryParams: ['focus'],
    focus: null,
    timeOffRequestFixtures: Ember.computed.filterBy('fixtures', 'constructor.modelName', 'time-off-request'),
    select2: 'success',
    logging: Ember.inject.service(),
    fakeStub: {
      payrollDate: moment().add(2, 'days').toDate(),
      payPeriod: {
        paidOn: moment().subtract(1, 'day').toDate()
      },
      descriptor: "Fake Paystub"
    },
    fixtures: Ember.computed(function () {
      var _this = this;

      var models = Ember.A(),
          createModel = function createModel(m) {
        return function (data) {
          return _this.store.createRecord(m, data);
        };
      };

      for (var m in _beyonce.modelData) {
        if (!_beyonce.modelData.hasOwnProperty(m)) {
          continue;
        }

        var ms = _beyonce.modelData[m];
        models.addObjects(ms.map(createModel(m)));
      }

      _beyonce.references.forEach(function (reference) {
        var needsReference = models.filter(function (model) {
          return model.constructor.modelName === reference.for;
        }),
            availableReferences = models.filter(function (model) {
          return model.constructor.modelName === reference.toModel;
        });
        needsReference.forEach(function (model) {
          model.set(reference.forKey, availableReferences.objectAt(reference.atIndex));
        });
      });

      return models;
    }),
    typeSizes: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
    colors: ['default', 'primary', 'info', 'success', 'warning', 'danger', 'bright-blue', 'gray-darker', 'gray-dark', 'gray', 'gray-light', 'gray-lighter'],
    btnColors: ['default', 'primary', 'info', 'success', 'warning', 'danger'],
    btnSizes: ['xs', 'sm', null, 'lg'],
    actions: {
      resetTimeOffRequest: function resetTimeOffRequest() {
        var _this2 = this;

        this.set('hideTimeOffRequest', true);
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.set('hideTimeOffRequest', false);
        });
      },
      placeholderAction: function placeholderAction() {
        this.logging.debug('Some action was called with: ', arguments);
      },
      confirmTest: function confirmTest(type, block) {
        var _this3 = this;

        this.set('blockConfirm', block);
        Ember.run.scheduleOnce('afterRender', function () {
          _this3[type](type === 'alert' ? 'Alert! Hello!' : 'Confirm!').then(function (response) {
            _this3.logging.debug('Confirm/Alert response', response);
          });
        });
      }
    }
  });

  _exports.default = _default;
});