define("slate-payroll-client/components/pas/pay-period-group", ["exports", "moment", "slate-payroll-client/mixins/ux-ajax-status-hooks", "slate-payroll-client/mixins/render-tooltips", "slate-payroll-client/mixins/confirm"], function (_exports, _moment, _uxAjaxStatusHooks, _renderTooltips, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PayPeriodGroupComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, _renderTooltips.default, _confirm.default, {
    store: Ember.inject.service(),
    auth: Ember.inject.service(),
    classNames: ['list-group-item'],
    classNameBindings: ['pastPayPeriod:text-muted'],
    inApprovalGrace: Ember.computed('model.payPeriod.{end,paidOn}', function () {
      var end = this.get('model.payPeriod.end'),
          paidOn = this.get('model.payPeriod.paidOn');
      return (0, _moment.default)().isBetween(end, (0, _moment.default)(paidOn).endOf('day'));
    }),
    pastPayPeriod: Ember.computed('model.payPeriod.paidOn', function () {
      return (0, _moment.default)().isAfter((0, _moment.default)(this.get('model.payPeriod.paidOn')).endOf('day'));
    }),
    daysLeft: Ember.computed('model.payPeriod.paidOn', function () {
      var paidOn = (0, _moment.default)(this.get('model.payPeriod.paidOn')).endOf('day'),
          days = Math.abs((0, _moment.default)().diff(paidOn, 'days'));
      return days > 0 ? " (".concat(days, " day").concat(days > 1 ? 's' : '', " left)") : '';
    }),
    actions: {
      removeApproval: function removeApproval() {
        var _this = this;

        var approval = this.get('model.approval');

        if (this.get('working') || !this.get('inApprovalGrace') || this.get('pastPayPeriod') || !approval.get('completedOn') || this.notOwn) {
          return;
        }

        this.confirm('Are you sure you want to remove your approval? You will have to re-approve this pay period.', 'Are you sure?').then(function (response) {
          if (!response) {
            return;
          }

          _this.ajaxStart();

          approval.set('completedOn', null);
          approval.save().then(function () {
            return _this.ajaxSuccess();
          }).catch(_this.ajaxError.bind(_this));
        });
      },
      quickApprove: function quickApprove() {
        var _this2 = this;

        var approval = this.get('model.approval'),
            notOwn = this.notOwn;

        if (this.get('working') || !this.get('inApprovalGrace') || this.get('pastPayPeriod') || approval && approval.get('completedOn')) {
          return;
        }

        this.confirm("Are you sure you want to approve this pay period without reviewing punches?".concat(notOwn ? ' You also will be submitting a stand-in approval for this employee.' : ''), 'Are you sure?').then(function (response) {
          if (!response) {
            return;
          }

          _this2.ajaxStart();

          if (!approval) {
            approval = _this2.store.createRecord('approval', {
              payPeriod: _this2.get('model.payPeriod')
            });
          }

          approval.setProperties({
            quickApproval: true,
            completedOn: new Date(),
            managerRejected: null
          });

          if (_this2.notOwn) {
            approval.setProperties({
              employee: _this2.forEmployee,
              standInEmployee: _this2.auth.user
            });
          }

          approval.save().then(function () {
            _this2.ajaxSuccess();

            if (_this2.onQuickApproval) {
              _this2.onQuickApproval();
            }
          }).catch(_this2.ajaxError.bind(_this2));
        });
      }
    }
  });
  PayPeriodGroupComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = PayPeriodGroupComponent;
  _exports.default = _default;
});