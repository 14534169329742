define("slate-payroll-client/routes/paystubs", ["exports", "slate-payroll-client/mixins/authenticated-route", "slate-payroll-client/mixins/esa-authenticated-route"], function (_exports, _authenticatedRoute, _esaAuthenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, _esaAuthenticatedRoute.default);

  _exports.default = _default;
});