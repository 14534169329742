define("slate-payroll-client/templates/supervisor/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e37wY+WX",
    "block": "{\"symbols\":[\"employee\"],\"statements\":[[1,[27,\"x-chart\",null,[[\"dataset\",\"chartOptions\",\"dataOptions\",\"height\"],[[23,[\"dataset\"]],[23,[\"hourChart\",\"chartOptions\"]],[23,[\"hourChart\",\"dataOptions\"]],\"600\"]]],false],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"list-group\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"employees\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"x-sv-employee-list-item\",null,[[\"tagName\",\"employee\",\"store\",\"selected\"],[\"div\",[22,1,[]],[23,[\"store\"]],[23,[\"activeEmployee\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/index.hbs"
    }
  });

  _exports.default = _default;
});