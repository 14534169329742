define("slate-payroll-client/serializers/company", ["exports", "slate-payroll-client/serializers/application", "slate-payroll-client/utils/serialize-object"], function (_exports, _application, _serializeObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    isNewSerializerAPI: true,
    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName === 'company') {
        (0, _serializeObject.default)(hash, true, 'address');
        (0, _serializeObject.default)(hash, false, 'notificationSettings', 'roleSettings', 'notificationEmails');
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});