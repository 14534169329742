define("slate-payroll-client/serializers/broadcast", ["exports", "slate-payroll-client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({});

  _exports.default = _default;
});