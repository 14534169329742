define("slate-payroll-client/templates/components/navigation/x-punch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iHu5Tx0Y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\"],[11,\"class\",\"punch-nav\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"auth\",\"user\",\"allowAdvanced\"]]],null,{\"statements\":[[4,\"active-link\",null,[[\"role\"],[\"presentation\"]],{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"punch.timesheet\"],[[\"class\"],[\"timesheet-link\"]],{\"statements\":[[0,\"Timesheet\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"active-link\",null,[[\"role\"],[\"presentation\"]],{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"punch.simple\"],[[\"class\"],[\"in-out-link\"]],{\"statements\":[[0,\"In/Out\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"unless\",[[27,\"get\",[[22,0,[]],\"auth.user.company.disableTor\"],null]],null,{\"statements\":[[4,\"active-link\",null,[[\"role\"],[\"presentation\"]],{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"punch.time-off\"],[[\"class\"],[\"time-off-link\"]],{\"statements\":[[0,\"Time Off\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/navigation/x-punch.hbs"
    }
  });

  _exports.default = _default;
});