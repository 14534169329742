define("slate-payroll-client/routes/esa/setup", ["exports", "slate-payroll-client/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    auth: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get('auth.session.esAuthenticated')) {
        this.transitionTo('punch.simple');
        return;
      }

      if (this.get('auth.user.esaEnabled')) {
        this.transitionTo('esa.index');
        return;
      }

      if (!this.get('auth.isTwoFactor')) {
        this.transitionTo('2fa');
      }
    },
    currentUserChanged: Ember.observer('auth.user.esaEnabled', function () {
      if (this.get('auth.user.esaEnabled') === true) {
        return this.transitionTo('esa.index');
      }
    })
  });

  _exports.default = _default;
});