define("slate-payroll-client/components/time-off/view-calendar/cal-day", ["exports", "slate-payroll-client/mixins/render-tooltips"], function (_exports, _renderTooltips) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_renderTooltips.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.tagName = 'li';
      this.classNames = ['cal__day'];
      this.classNameBindings = ['day.outOfMonth:cal__gray', 'isToday:is-today'];
      this.sortingRequests = ['approved'];
      this.requestsSorted = Ember.computed.sort('day.requests', 'sortingRequests');
      this.pendingRequests = Ember.computed.filter('day.requests', function (request) {
        return !request.get('approved');
      });
    },
    isToday: Ember.computed('day.date', function () {
      return this.get('day.date') && moment().isSame(this.get('day.date'), 'day');
    }),
    actions: {
      openInteractive: function openInteractive() {
        this.get('interact')('day', this.get('day'));
        Ember.$('.tooltip').hide();
      }
    }
  });

  _exports.default = _default;
});