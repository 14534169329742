define("slate-payroll-client/components/time-off/time-of-day-select-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hourStepValue: Ember.computed('company.timeMinuteInterval', function () {
      return (this.get('company.timeMinuteInterval') || 15) / 60;
    }),
    changeTime: function changeTime(time) {
      var torDay = new Date(this.get('day.date'));
      torDay.setHours(time.getHours());
      torDay.setMinutes(time.getMinutes());
      this.onChange(torDay.toISOString(), this.get('day.hours'));
    },
    changeHours: function changeHours(e) {
      var hrs = e.target.value;
      this.onChange(this.get('day.date'), parseFloat(hrs));
    }
  });

  _exports.default = _default;
});