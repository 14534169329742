define("slate-payroll-client/components/ot-breakout", ["exports", "slate-payroll-client/mixins/render-tooltips"], function (_exports, _renderTooltips) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_renderTooltips.default, {
    tagName: 'li',
    classNames: ['list-group-item', 'ot-container'],
    overtime: Ember.computed.alias('overtimeWeekDetail.rule.overtimeToCreate'),
    hours: Ember.computed.alias('overtimeWeekDetail.hoursToAdd'),
    regularHours: Ember.computed('hours', 'ot', function () {
      var ot = this.get('overtime');
      var hours = this.get('hours');
      return ot ? hours - ot : undefined;
    }),
    regularWidth: Ember.computed('hours', 'ot', function () {
      var ot = this.get('overtime'),
          hours = this.get('hours'),
          withOvertime = 'width: ' + (hours - ot) / hours * 100 + '%;',
          reducedOvertime = 'width: 80%;';
      return Ember.String.htmlSafe(ot && ot < 7 ? reducedOvertime : ot >= 7 ? withOvertime : 'width: 100%;');
    }),
    otWidth: Ember.computed('hours', 'ot', function () {
      var ot = this.get('overtime'),
          hours = this.get('hours'),
          otWidthCalc = 'width: ' + ot / hours * 100 + '%;';
      return ot && ot < 7 ? 'width: 20%;' : ot >= 7 ? otWidthCalc : undefined;
    }),
    overtimeChanged: Ember.observer('overtime', function () {
      this._renderTooltips();
    })
  });

  _exports.default = _default;
});