define("slate-payroll-client/controllers/supervisor/approvals/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['approvalEmployee'],
    approvalEmployee: null,
    reviewingNotOwn: Ember.computed.bool('approvalEmployee'),
    reviewingEmployee: Ember.computed('employeesWithDirectReports', 'approvalEmployee', function () {
      return this.employeesWithDirectReports.findBy('id', this.approvalEmployee);
    })
  });

  _exports.default = _default;
});