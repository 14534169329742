define("slate-payroll-client/templates/components/punch-queue-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7VajD1Rc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"text-danger text-bright\"],[11,\"title\",\"Remove punch\"],[3,\"action\",[[22,0,[]],\"removePunch\"]],[9],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-trash-o\"],[9],[10],[10],[0,\"\\n\\n\"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-sign-in\"],[9],[10],[0,\" \"],[1,[27,\"format-moment\",[[23,[\"punch\",\"timeIn\"]],\"h:mma\"],null],false],[0,\"\\n\"],[7,\"i\"],[11,\"class\",\"fa fa-fw fa-sign-out\"],[9],[10],[0,\" \"],[1,[27,\"format-moment\",[[23,[\"punch\",\"timeOut\"]],\"h:mma\"],null],false],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"pull-right text-muted\"],[9],[0,\"\\n  \"],[1,[27,\"format-moment\",[[23,[\"punch\",\"timeIn\"]],\"M/D\"],null],false],[0,\"-\\n  \"],[1,[27,\"format-moment\",[[23,[\"punch\",\"timeOut\"]],\"M/D\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"hours\"],[9],[1,[23,[\"punch\",\"hours\"]],false],[10],[0,\" Hours\\n\"],[7,\"span\"],[11,\"class\",\"pull-right text-muted\"],[9],[0,\"\\n  \"],[4,\"if\",[[23,[\"punch\",\"lunch\"]]],null,{\"statements\":[[1,[23,[\"punch\",\"lunch\"]],false],[0,\"hr Lunch\"]],\"parameters\":[]},{\"statements\":[[0,\"No Lunch\"]],\"parameters\":[]}],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/punch-queue-item.hbs"
    }
  });

  _exports.default = _default;
});