define("slate-payroll-client/models/punch", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    timeIn: attr('date'),
    timeOut: attr('date'),
    hours: attr('number'),
    days: attr('number'),
    addEarnings: attr('number'),
    deduction: attr('number'),
    lunch: attr('number'),
    // Hours
    breaks: attr('number', {
      defaultValue: 0
    }),
    // Hours
    onBreak: attr('date'),
    commentToEmployee: attr('string'),
    directHourEntry: attr('boolean'),
    needsReview: attr('boolean'),
    reviewReason: attr('string'),
    reviewDate: attr('date'),
    reviewedBy: attr('string'),
    transaction: attr('boolean'),
    // Meaning it is not tied to a pay period
    checkServerTime: attr('boolean'),
    // need this so api can check to see if client time = server time on simple entry,
    clientVersion: attr('string'),
    status: attr('string'),
    notes: attr('string'),
    signature: attr('string'),
    punchComment: attr('string'),
    ip: attr('string'),
    timesheet: attr('boolean'),
    overMaxHours: attr('boolean'),
    isOvertime: attr('boolean'),
    triggeredOvertime: attr('boolean'),
    uaIn: attr('string'),
    uaOut: attr('string'),
    timeInGeoLat: attr('number'),
    timeInGeoLng: attr('number'),
    timeOutGeoLat: attr('number'),
    timeOutGeoLng: attr('number'),
    seenByUsers: attr('array'),
    // Relational
    employee: _emberData.default.belongsTo('employee'),
    company: _emberData.default.belongsTo('company', {
      async: true,
      inverse: null
    }),
    companyPaycode: _emberData.default.belongsTo('company-paycode', {
      async: true,
      inverse: null
    }),
    payPeriod: _emberData.default.belongsTo('pay-period', {
      async: true,
      inverse: null
    }),
    splitTo: _emberData.default.belongsTo('punch', {
      async: true,
      inverse: null
    }),
    overtimePunch: _emberData.default.belongsTo('punch', {
      async: true,
      inverse: 'triggerPunch'
    }),
    triggerPunch: _emberData.default.belongsTo('punch', {
      async: true,
      inverse: 'overtimePunch'
    }),
    seenBySupervisors: _emberData.default.hasMany('employee', {
      async: true,
      inverse: null
    }),
    // Legacy Fields
    legacyEntryId: attr('number'),
    legacyPayPeriodId: attr('number'),
    legacyCostCenterId: attr('number'),
    updatedOn: attr('date'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    incomplete: Ember.computed('timeIn', 'timeOut', function () {
      return this.get('timeIn') && !this.get('timeOut');
    })
  });

  _exports.default = _default;
});