define("slate-payroll-client/models/broadcast", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company', {
      inverse: null,
      async: true
    }),
    readBy: (0, _relationships.hasMany)('employee'),
    departments: (0, _relationships.hasMany)('department'),
    locations: (0, _relationships.hasMany)('location'),
    expires: (0, _attr.default)('date'),
    created: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    isExpired: Ember.computed('expires', function () {
      return this.expires && this.expires < new Date();
    })
  });

  _exports.default = _default;
});