define("slate-payroll-client/controllers/squares-login", ["exports", "slate-payroll-client/mixins/ux-ajax-status-hooks"], function (_exports, _uxAjaxStatusHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    auth: Ember.inject.service(),
    actions: {
      squareSuccess: function squareSuccess(data) {
        var _this = this;

        if (this.get('working')) {
          return;
        }

        this.ajaxStart();

        if (!data) {
          this.ajaxError('Unable to login with that square.', true);
          return;
        }

        var squareData = data.usedSquare;
        delete data.usedSquare;
        this.get('auth').createSession(data).then(function () {
          _this.ajaxSuccess();

          _this.set('pendingAutoPunch', squareData.autoPunch);

          _this.transitionToRoute('punch.simple');
        }).catch(this.ajaxError.bind(this));
      },
      squareError: function squareError(err) {
        this.ajaxError(err);
      }
    }
  });

  _exports.default = _default;
});