define("slate-payroll-client/models/deduction", ["exports", "slate-payroll-client/models/transaction"], function (_exports, _transaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transaction.default.extend();

  _exports.default = _default;
});