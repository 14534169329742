define("slate-payroll-client/templates/components/time-off/view-calendar/interaction-day-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cKTx29b6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"label label-success\"],[9],[1,[27,\"format-moment\",[[23,[\"day\",\"date\"]]],null],false],[0,\" (\"],[1,[27,\"format-moment\",[[23,[\"day\",\"date\"]],\"dddd in MMMM\"],null],false],[0,\")\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"showTimes\"]],[23,[\"day\",\"end\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"format-moment\",[[23,[\"day\",\"date\"]],\"h:mma\"],null],false],[0,\"-\"],[1,[27,\"format-moment\",[[23,[\"day\",\"end\"]],\"h:mma\"],null],false],[0,\"\\n    -\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[23,[\"day\",\"hours\"]],false],[0,\"hrs\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/components/time-off/view-calendar/interaction-day-item.hbs"
    }
  });

  _exports.default = _default;
});