define("slate-payroll-client/templates/supervisor/approvals/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TBdO6ctZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hgroup\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    Review for \"],[1,[27,\"format-moment\",[[23,[\"model\",\"payPeriod\",\"start\"]],\"M/D\"],null],false],[0,\" - \"],[1,[27,\"format-moment\",[[23,[\"model\",\"payPeriod\",\"end\"]],\"M/D\"],null],false],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"small\"],[9],[0,\"Paid on \"],[1,[27,\"format-moment\",[[23,[\"model\",\"payPeriod\",\"paidOn\"]],\"M/D/YY\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "slate-payroll-client/templates/supervisor/approvals/review.hbs"
    }
  });

  _exports.default = _default;
});