define("slate-payroll-client/controllers/esa/setup", ["exports", "slate-payroll-client/mixins/popovers", "slate-payroll-client/mixins/ux-ajax-status-hooks"], function (_exports, _popovers, _uxAjaxStatusHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_popovers.default, _uxAjaxStatusHooks.default, {
    auth: Ember.inject.service(),
    ajax: Ember.inject.service(),
    disabledForm: Ember.computed('esat', 'esatConfirm', 'working', function () {
      var esat = this.get('esat');
      return this.get('working') || !esat || esat !== this.get('esatConfirm');
    }),
    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      createEsat: function createEsat() {
        var _this = this;

        var esat = this.get('esat');

        if (this.get('disabledForm')) {
          return;
        }

        this.ajaxStart();
        this.get('ajax').post('/client-api/employee/esat', {
          data: {
            esat: esat
          }
        }).then(function () {
          _this.ajaxSuccess('Successfully created ESAT.');

          _this.get('auth.user').set('esaEnabled', true);

          _this.transitionToRoute('esa.index');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  _exports.default = _default;
});